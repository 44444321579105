import ServicesBunner from '../ServicesBunner/ServicesBunner';
import CardImageStyle from '../cardImageStyle/CardImageStyle';
import './cardThree.css';

const CardThree = ({servicesUs, color, positionX, positionY, header, icon, image }) => {
  const {content,title}=servicesUs;
 
  return (
    <section className="cardThree items-center">
      <div className="cardThree_content">
        <div className="cardThree_content_header">
          <h3>{title}</h3>
        </div>
        { 
          content?.map((item) => <div className="cardThree_content_parragraph">
          <ArrowIcon />
          <p>{item.description}</p>
        </div> )
        }
      </div>
      {/* <CardImageStyle
        image={image}
        color={color}
        positionY="bottom"
        positionX="right"
      /> */}
      <div className='cardThree_content'>
      <ServicesBunner image={image}/>
      </div>
    </section>
  );
};

const ArrowIcon = () => {
  return (
    <>
      <div className="arrowIcon">
        <div className="arrowIicon1"></div>
        <div className="arrowIicon2"></div>
      </div>
    </>
  );
};

export default CardThree;
