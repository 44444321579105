import SingleCardServices from './SingleCardServices';
import './ourServicesCard.css';
import family_nursing from '../../../assets/service-img/Family nursing service1.jpg';
import caregiver_support_home from '../../../assets/service-img/Caregiver Support at Home.jpg';
import caregiver_support from '../../../assets/service-img/Caregiver Support at Abroad.jpg';
import baby_home_care from '../../../assets/service-img/Baby Home Care Services.jpg';
import personal_home_care from '../../../assets/service-img/Personal Home Care.jpg';
import on_demand_nursing_care from '../../../assets/service-img/On-Demand nursing care.jpg';

const OurServicesCard = () => {
     const srvicesInfo = [
      {
        "id": 1,
        "serviceName": "Family Nursing Services",
        "description": "Elevate Family Care with Our Expert Nursing Services. Quality Health Support Tailored for Your Loved Ones. Discover the Difference Today!",
        "img": family_nursing
      },
      {
        "id": 2,
        "serviceName": "Caregiver Support at Home",
        "description": "Empowering lives through compassionate Caregiver Services. Discover Quality Home Care Today!",
        "img": caregiver_support_home
      },
      {
        "id": 3,
        "serviceName": "Caregiver Support at Abroad",
        "description": "Become a parasol caregiver and find Host Families from abroad – Contact them today for free",
        "img": caregiver_support
      },
      {
        "id": 4,
        "serviceName": "Baby Home Care Services",
        "description": "Providing peace of mind and expert care with our baby home care Services. Trust us to nurture and support your little one's development in a safe and loving environment.",
        "img": baby_home_care
      },
      {
        "id": 5,
        "serviceName": "Personal Home Care",
        "description": "Enhancing Lives with Personal Home Care Services: Tailored Support for Your Comfort and Well-Being. Discover the Benefits Today",
        "img": personal_home_care
      },
      {
        "id": 6,
        "serviceName": "On-Demand Nursing Care",
        "description": "Experience the Convenience of On-Demand Nursing Care Services. Quality Healthcare Where and When You Need It. Discover Personalized Care Today.",
        "img": on_demand_nursing_care
      }
    ];
    
  return (
    <section className="OSC_wrap mx-auto lg:w-[80%]">
      <h3>Our Nurse Services</h3>
      <div className="OSC_container grid grid-cols-1 md:grid-cols-2 gap-4">
        {
          srvicesInfo?.map((service=> <SingleCardServices key={service.id} service={service} /> ))
        }
    
      </div>
    </section>
  );
};


export default OurServicesCard;
