import './allDoctors.css';
import '../Users/users.css';

import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { FaUserPlus, FaTrashAlt } from 'react-icons/fa';
import { GrView } from 'react-icons/gr';
import { useState } from 'react';
import { useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import { SignleDoctor } from '../../../pages/DoctorDetails/DoctorDetails';

const AllDoctors = ({ pageTitle }) => {
  const [allDoctor, setAllDoctor] = useState(false);
  const [doctorView, setDoctorView] = useState(false);
  const [addFormVisible, setAddFormVisible] = useState(false);
  const [deleteDoct, setDeleteDoct] = useState(false);

  const [message, setMessage] = useState('');

  const url = process.env.REACT_APP_BACKEND_PRIFILE_URL;

  const token = useSelector((info) => {
    return info.user.info.token;
  });

  const getAllDoctor = async () => {
    try {
      const { data } = await axios.get(`${url}/doctorlis/alldoctor`);
      setAllDoctor(data.doctor);
    } catch (error) {
      return;
    }
  };
  const getOneDoctor = async (id) => {
    try {
      const { data } = await axios.get(`${url}/doctorlis/onedoctor/${id}`);
      setDoctorView(data.onedoctor);
    } catch (error) {
      return;
    }
  };

  const deleteDoctor = async (id) => {
    try {
      const response = await axios.delete(`${url}/user/deletedoctor/${id}`, {
        headers: {
          authorization: 'Bearer ' + token,
        },
      });
      setMessage(response.data.message);
      setTimeout(() => {
        getAllDoctor();
        setDoctorView(false);
        setDeleteDoct(false);
      }, 1500);
    } catch (error) {
      return;
    }
  };
  //===================

  const initialValues = {
    fullName: '',
    qualifications: '',
    designation: '',
    experience: '',
    specialization: '',
    membership: '',
    availability: '',
    consultaion1: '',
    consultaion2: '',
    consultaion3: '',
    consultaion4: '',
    consultaion5: '',
    profilePic: '',
    gender: '',
    fackbook: '',
    twitter: '',
    linkedIn: '',
  };

  const validationSchema = yup.object({
    fullName: yup
      .string()
      .required('Full Name are required')
      .min(4, 'Full Name Minimun 4 Charactor'),
    qualifications: yup
      .string()
      .required(true, 'Qualifications are required')
      .min(4, 'Qualifications Minimun 4 Charactor'),
    experience: yup.string().required('Experience are required'),
    specialization: yup
      .string()
      .required('Specialization are required')
      .min(4, 'Specialization Minimun 4 Charactor'),
    availability: yup
      .string()
      .required('Availability are required Enter With Time'),
    consultaion1: yup.string().required('Consultaion1 FEES are required'),
  });

  const {
    errors,
    values,
    touched,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: async ({
      fullName,
      qualifications,
      designation,
      experience,
      specialization,
      membership,
      availability,
      consultaion1,
      consultaion2,
      consultaion3,
      consultaion4,
      consultaion5,
      profilePic,
      gender,
      fackbook,
      twitter,
      linkedIn,
    }) => {
      // imageCode
      let image = new FormData();
      image.append('profilePic', profilePic);
      const { data } = await axios.post(`${url}/image-upload`, image);

      const respons = await axios.post(
        `${url}/user/alldoctorlist`,
        {
          fullName,
          qualifications,
          designation,
          experience,
          specialization,
          membership,
          availability,
          consultaion1,
          consultaion2,
          consultaion3,
          consultaion4,
          consultaion5,
          profilePic: data.uploadUrl,
          gender,
          fackbook,
          twitter,
          linkedIn,
        },
        {
          headers: {
            authorization: 'Bearer ' + token,
          },
        }
      );

      setMessage(respons.data.message);

      setTimeout(() => {
        getAllDoctor();
        resetForm();
        setAddFormVisible(false);
      }, 1500);
    },
    validationSchema: validationSchema,
  });

  useEffect(() => {
    getAllDoctor();
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    return () => {
      unlisten();
    };
  }, []);

  return (
    <>
      <h3>{pageTitle}</h3>
      <div className="user_container_add">
        <button onClick={() => setAddFormVisible(true)}>
          <FaUserPlus className="add_user_icon" /> Addn New Doctor
        </button>
      </div>
      <div className="user_header">
        <h4>Name</h4>
        <h4>Qualifications</h4>
        <h4>specialization</h4>
        <h4>action</h4>
      </div>
      <div className="user_content_wrap">
        {allDoctor ? (
          allDoctor.map((doctor) => {
            return (
              <div className="user_content" key={doctor._id}>
                <p>
                  <img
                    src={
                      doctor.profilePic ||
                      'https://cdn.pixabay.com/photo/2022/10/02/01/58/technology-7492577_960_720.jpg'
                    }
                    alt="UserPic"
                  />
                  <span> {doctor.fullName.substring(0, 20) || 'Null'}</span>
                </p>
                <p style={{ textTransform: 'capitalize' }}>
                  {doctor.qualifications.substring(0, 20)}...
                </p>
                <p>{doctor.specialization.substring(0, 20)}...</p>
                <p>
                  <button
                    onClick={() => {
                      getOneDoctor(doctor._id);
                      setDeleteDoct(doctor._id);
                    }}
                  >
                    <FaTrashAlt className="user_icon_delete" />
                    delete
                  </button>
                  <button onClick={() => getOneDoctor(doctor._id)}>
                    <GrView />
                    view
                  </button>
                </p>
              </div>
            );
          })
        ) : (
          <p>Loading...</p>
        )}
      </div>

      {doctorView && (
        <div className="doctor_profile_view_wrap">
          <MdClose
            className="doctor_close_icon"
            onClick={() => {
              setDoctorView(false);
              setDeleteDoct(false);
            }}
          />

          {deleteDoct && doctorView && (
            <button
              className="confirm_doctor_delete"
              onClick={() => deleteDoctor(deleteDoct)}
            >
              Comfirm Delete
            </button>
          )}
          <SignleDoctor
            fullName={doctorView?.fullName || false}
            profilePic={doctorView?.profilePic || false}
            qualifications={doctorView?.qualifications || false}
            designation={doctorView?.designation || false}
            experience={doctorView?.experience || false}
            specialization={doctorView?.specialization || false}
            membership={doctorView?.membership || false}
            availability={doctorView?.availability || false}
            consultaion1={doctorView?.consultaion1 || false}
            consultaion2={doctorView?.consultaion2 || false}
            consultaion3={doctorView?.consultaion3 || false}
            consultaion4={doctorView?.consultaion4 || false}
            consultaion5={doctorView?.consultaion5 || false}
            youtubeLink={doctorView?.youtubeLink || false}
          />
        </div>
      )}

      {addFormVisible && (
        <div className="alldoctor_form_wrap">
          <h3>Add New Doctor</h3>
          <MdClose
            className="doctor_close_icon"
            onClick={() => setAddFormVisible(false)}
          />

          <form onSubmit={handleSubmit}>
            <span>
              <label htmlFor="fullName">
                Enter Full Name <span>*</span>
              </label>
              <input
                value={values.fullName}
                onChange={handleChange}
                type="text"
                name="fullName"
                id="fullName"
                className={errors.fullName && touched.fullName && 'error_box'}
              />
              {errors.fullName && touched.fullName && <p>{errors.fullName}</p>}
            </span>
            <span>
              <label htmlFor="qualifications">
                Enter Qualifications <span>*</span>
              </label>
              <input
                value={values.qualifications}
                onChange={handleChange}
                type="text"
                name="qualifications"
                id="qualifications"
                className={
                  errors.qualifications && touched.qualifications && 'error_box'
                }
              />
              {errors.qualifications && touched.qualifications && (
                <p>{errors.qualifications}</p>
              )}
            </span>
            <span>
              <label htmlFor="designation">Enter Designation</label>
              <input
                value={values.designation}
                onChange={handleChange}
                type="text"
                name="designation"
                id="designation"
              />
            </span>
            <span>
              <label htmlFor="experience">
                Enter Experience <span>*</span>
              </label>
              <input
                value={values.experience}
                onChange={handleChange}
                type="text"
                name="experience"
                id="experience"
                className={
                  errors.experience && touched.experience && 'error_box'
                }
              />
              {errors.experience && touched.experience && (
                <p>{errors.experience}</p>
              )}
            </span>
            <span>
              <label htmlFor="specialization">
                Enter Specialization with Details <span>*</span>
              </label>
              <input
                value={values.specialization}
                onChange={handleChange}
                type="text"
                name="specialization"
                id="specialization"
                className={
                  errors.specialization && touched.specialization && 'error_box'
                }
              />
              {errors.specialization && touched.specialization && (
                <p>{errors.specialization}</p>
              )}
            </span>
            <span>
              <label htmlFor="membership">Enter Membership here</label>
              <input
                value={values.membership}
                onChange={handleChange}
                type="text"
                name="membership"
                id="membership"
              />
            </span>
            <span>
              <label htmlFor="availability">
                Enetr Availability with Time <span>*</span>
              </label>
              <input
                value={values.availability}
                onChange={handleChange}
                type="text"
                name="availability"
                id="availability"
                className={
                  errors.availability && touched.availability && 'error_box'
                }
              />
              {errors.availability && touched.availability && (
                <p>{errors.availability}</p>
              )}
            </span>
            <span>
              <label htmlFor="consultaion1">
                Enter consultaion FEES No 1 <span>*</span>
              </label>
              <input
                value={values.consultaion1}
                onChange={handleChange}
                type="text"
                name="consultaion1"
                id="consultaion1"
                className={
                  errors.consultaion1 && touched.consultaion1 && 'error_box'
                }
              />
              {errors.consultaion1 && touched.consultaion1 && (
                <p>{errors.consultaion1}</p>
              )}
            </span>
            <span>
              <label htmlFor="consultaion2">Enter consultaion FEES No 2</label>
              <input
                value={values.consultaion2}
                onChange={handleChange}
                type="text"
                name="consultaion2"
                id="consultaion2"
              />
            </span>
            <span>
              <label htmlFor="consultaion3">Enter consultaion FEES No 3</label>
              <input
                value={values.consultaion3}
                onChange={handleChange}
                type="text"
                name="consultaion3"
                id="consultaion3"
              />
            </span>
            <span>
              <label htmlFor="consultaion4">Enter consultaion FEES No 4</label>
              <input
                value={values.consultaion4}
                onChange={handleChange}
                type="text"
                name="consultaion4"
                id="consultaion4"
              />
            </span>
            <span>
              <label htmlFor="consultaion5">Enter consultaion FEES No 5</label>
              <input
                value={values.consultaion5}
                onChange={handleChange}
                type="text"
                name="consultaion5"
                id="consultaion5"
              />
            </span>
            <span>
              <label htmlFor="profilePic">Select Doctor Profile Picture</label>
              <input
                onChange={(e) => {
                  setFieldValue('profilePic', e.target.files[0]);
                }}
                type="file"
                accept="image/*"
                name="profilePic"
                id="profilePic"
              />
            </span>
            <span>
              <label htmlFor="fackbook">Enter Fackbook Profile Link</label>
              <input
                value={values.fackbook}
                onChange={handleChange}
                type="text"
                name="fackbook"
                id="fackbook"
              />
            </span>
            <span>
              <label htmlFor="twitter">Enter Twitter Profile Link</label>
              <input
                value={values.twitter}
                onChange={handleChange}
                type="text"
                name="twitter"
                id="twitter"
              />
            </span>
            <span>
              <label htmlFor="linkedIn">Enter LinkedIn Profile Link</label>
              <input
                value={values.linkedIn}
                onChange={handleChange}
                type="text"
                name="linkedIn"
                id="linkedIn"
              />
            </span>

            <button type="submit">Add doctor</button>
            {message && <p>{message}</p>}
          </form>
        </div>
      )}
    </>
  );
};

export default AllDoctors;
