import React from 'react'
import './SingleCardServices.css'
const SingleCardServices = ({service}) => {
  const {serviceName,description, img} =service;
  return (
    <div className='OSC-card flex flex-col lg:flex-row gap-4 items-start'>
        <div className="service-image flex-1">
            <img src={img} alt="nursing services" />
        </div>
        <div className="service-text lg:flex-1 p-2">
            <h2 className='text-3xl md:text-5xl'>{serviceName}</h2>
            <p className='py-2'>{description}</p>
            <button>Book Now</button>
        </div>
    </div>
  )
}

export default SingleCardServices