import './cardImageStyle.css';

const CardImageStyle = ({ image, color, positionX, positionY }) => {
  return (
    <div className="cardTwo_img">
      <img src={`/assets/${image}`} alt="photo" />
      <div
        className="cardTwo_img_layer"
        style={{
          background: `${
            color ? 'var(--primary-color)' : 'var(--primary-color-2)'
          }`,
          [positionX]: '-3%',
          [positionY]: '-4%',
        }}
      ></div>
    </div>
  );
};

export default CardImageStyle;
