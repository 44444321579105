import React, { useEffect, useState } from 'react'
import './TourismServicesMain.css'
import TourismServices from './TourismServices'
import axios from 'axios'
const TourismServicesMain = () => {

    const [services, setServices] = useState([])
    console.log(services)
    useEffect(() => {
        // Use Axios to fetch data from the JSON file
        axios.get('/json/servicesList.json')
            .then(response => {
                const data = response.data;
                setServices(data);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, [])

    return (
        <div className='mt-8 w-[80%] mx-auto grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6'>
            {
                services.map((service)=> <TourismServices name={service.title}
                text = {service.text}
                img="https://gomedii.com/mt/assets/images/medi_1.png">
            </TourismServices> )
            }
            
        </div>
    )
}

export default TourismServicesMain