import React from 'react'
import './TourismOffer.css'
import SectionHeading from './SectionHeading'
import OfferItem from './OfferItem'
// Image icon Import
import bariatricSurgerySvg from './../../assets/tourism_Icon/Bariatric Surgery.png';
import cardiologySvg from './../../assets/tourism_Icon/cordonoly.png';
import cosmetologySvg from './../../assets/tourism_Icon/Cosmetology.png';
import endocrinologySvg from './../../assets/tourism_Icon/Endocrinology.png'
import gynecologySvg from './../../assets/tourism_Icon/Gynecology.png';
import orthopedicsSvg from './../../assets/tourism_Icon/Orthopedics.png';
import ivfFertilitySvg from './../../assets/tourism_Icon/IVF & Fertility.png';
import nephrologySvg from './../../assets/tourism_Icon/Nephrology.png';
import neurologySvg from './../../assets/tourism_Icon/Neurology.png';
import oncologySvg from './../../assets/tourism_Icon/Oncology.png';

const TourismOffer = () => {

  const medicalSpecialties = [
    { id: 1, name: 'Bariatric Surgery', img: bariatricSurgerySvg },
    { id: 2, name: 'Cardiology', img: cardiologySvg },
    { id: 3, name: 'Cosmetology', img: cosmetologySvg },
    { id: 4, name: 'Endocrinology', img: endocrinologySvg },
    { id: 5, name: 'Gynecology', img: gynecologySvg },
    { id: 6, name: 'Orthopedics', img: orthopedicsSvg },
    { id: 7, name: 'IVF & Fertility', img: ivfFertilitySvg },
    { id: 8, name: 'Nephrology', img: nephrologySvg },
    { id: 9, name: 'Neurology', img: neurologySvg },
    { id: 10, name: 'Oncology', img: oncologySvg }
  ];

  return (
    <div className='container-main py-10'>
      <div className="offer-main">
        <SectionHeading title="Specialities" span="We Offer" button="Exprole More" text="Affordable treatment options with all possible ranges of medical procedures to choose from with the finest quality of healthcare in the country."></SectionHeading>
      </div>
      <div className="offer-items grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-3">
        {
          medicalSpecialties?.map((specialties) => <OfferItem key={specialties.id} specialties={specialties}></OfferItem>)
        }

      </div>
    </div>
  )
}

export default TourismOffer