import Cookies from 'js-cookie';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function NurseHeader() {
  let navigator = useNavigate();
  const isLogout = () => {
    Cookies.remove('parasol_token');
    Cookies.remove('parasol_refrash_token');
    navigator('/user-login');
  };
  return (
    <div className="navbar bg-base-100 bg-transparent">
      <div className="flex-1">
        <a
          href="/"
          className="font-semibold text-[20px] normal-case text-xl text-white"
        >
          {' '}
          ParasolHealth{' '}
        </a>
      </div>
      <div className="flex-none">
        <div className="dropdown dropdown-end">
          <label tabIndex={0} className="btn btn-ghost btn-circle text-white">
            <div className="indicator mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                />
              </svg>

              <span className="badge badge-sm indicator-item ">0</span>
            </div>
          </label>
          <div
            tabIndex={0}
            className="menu menu-sm dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 z-30"
          >
            <li>
              <a className="justify-between">
                <span className="text-[17px] font-medium">Notification</span>
                <span className="badge ">New</span>
              </a>
            </li>
            <li>
              <a>List </a>
            </li>
          </div>
        </div>
        <div className="dropdown dropdown-end">
          <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
            <div className="w-10 rounded-full">
              <img src="https://img.freepik.com/free-photo/close-up-upset-female-nurse_329181-19723.jpg?w=1380&t=st=1686134843~exp=1686135443~hmac=380ab62b473c0562abdea70009d53782715e0fdbdb8ccbd52974aa3d8073b96c" />
            </div>
          </label>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 z-30"
          >
            <li>
              <a>Profile</a>
            </li>
            <li>
              <a>Settings</a>
            </li>
            <li>
              <a onClick={isLogout}>Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
