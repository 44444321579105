import './Testimonials.css';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';

let dummyArray = new Array(10).fill('dummy');

const Testimonials = () => {
  return (
    <section className="testimonials_container">
      <h4>Testimonials</h4>
      <h2>What Clients Says About Us</h2>
      <div className="testimonials_carousel">
        <Swiper
          slidesPerView="auto"
          spaceBetween={10}
          loop={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 20000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            1024: {
              slidesPerView: 1,
              spaceBetween: 50,
            },
            1025: {
              slidesPerView: 2,
              spaceBetween: 50,
            },
          }}
          // navigation={true}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            bulletClass: 'testimonial-swiper',
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            {' '}
            <div className="testimonials_card">
              <div className="testimonials_card_img">
                <img src="/assets/testimonials.jpg" alt="testimonails" />
              </div>
              <p>Miley Rahman</p>
              <h5>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry,Lorem Ipsum is simply dummy text of the printing and
                typesetting industry.
              </h5>
              <img src="/assets/testimonails_icon.png" alt='testimonails' />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <div className="testimonials_card">
              <div className="testimonials_card_img">
                <img src="/assets/testimonials.jpg" alt="testimonails" />
              </div>
              <p>Miley Rahman</p>
              <h5>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry,Lorem Ipsum is simply dummy text of the printing and
                typesetting industry.
              </h5>
              <img src="/assets/testimonails_icon.png" alt='"' />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <div className="testimonials_card">
              <div className="testimonials_card_img">
                <img src="/assets/testimonials.jpg" alt="testimonails" />
              </div>
              <p>Miley Rahman</p>
              <h5>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry,Lorem Ipsum is simply dummy text of the printing and
                typesetting industry.
              </h5>
              <img src="/assets/testimonails_icon.png" alt='"' />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <div className="testimonials_card">
              <div className="testimonials_card_img">
                <img src="/assets/testimonials.jpg" alt="testimonails" />
              </div>
              <p>Miley Rahman</p>
              <h5>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry,Lorem Ipsum is simply dummy text of the printing and
                typesetting industry.
              </h5>
              <img src="/assets/testimonails_icon.png" alt='"' />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <div className="testimonials_card">
              <div className="testimonials_card_img">
                <img src="/assets/testimonials.jpg" alt="testimonails" />
              </div>
              <p>Miley Rahman</p>
              <h5>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry,Lorem Ipsum is simply dummy text of the printing and
                typesetting industry.
              </h5>
              <img src="/assets/testimonails_icon.png" alt='"' />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <div className="testimonials_card">
              <div className="testimonials_card_img">
                <img src="/assets/testimonials.jpg" alt="testimonails" />
              </div>
              <p>Miley Rahman</p>
              <h5>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry,Lorem Ipsum is simply dummy text of the printing and
                typesetting industry.
              </h5>
              <img src="/assets/testimonails_icon.png" alt='"' />
            </div>
          </SwiperSlide>
        </Swiper> 
        <div className="swiper-button-next"></div>
        <div className="swiper-button-prev"></div>
        
      </div>
    </section>
  );
};

export default Testimonials;
