import { ContactInfoCard } from '../ContactForm/ContactForm';
import { useFormik } from 'formik';
import * as yup from 'yup';
import '../ContactForm/ContactForm.css';
import axios from 'axios';
import { useState } from 'react';

const TouchContact = () => {
  const [msg, setMsg] = useState(false);
  const url = process.env.REACT_APP_BACKEND_PRIFILE_URL;
  const touchFormValukidation = yup.object({
    patientsName: yup
      .string()
      .required('Patients Name are required')
      .min(3, 'Patients Name minimum 3 charactor')
      .max(60, 'Patients Name  too Long..!'),
    contactNo: yup
      .string()
      .required('Contact No are required')
      .min(11, 'Invalid Contact Number')
      .max(14, 'Invalid Contact Number'),
    email: yup
      .string()
      .required('Email are required')
      .email('Please Enter Valid email Address'),
    describe: yup.string().required('Describe are required').min(6).max(1499),
  });

  const { errors, values, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        patientsName: '',
        contactNo: '',
        email: '',
        department: '',
        describe: '',
        address: '',
      },
      onSubmit: async (value) => {
        const { data } = await axios.post(`${url}/doctorbooking`, { ...value });

        setMsg(data.message);
        resetForm();

        setTimeout(() => {
          setMsg(false);
        }, 3000);
      },
      validationSchema: touchFormValukidation,
    });

  return (
    <section className="Contact">
      <div className="Contact_form">
        <form onSubmit={handleSubmit}>
          <label htmlFor="patientsName">
            Patients Name <span className="star">*</span>
          </label>
          <input
            onChange={handleChange}
            value={values.patientsName}
            type="text"
            name="patientsName"
            id="patientsName"
            className={
              errors.patientsName && touched.patientsName && 'error_border'
            }
          />
          {errors.patientsName && touched.patientsName && (
            <p className="star">{errors.patientsName}</p>
          )}
          <label htmlFor="contactNo">
            Contact <span className="star">*</span>
          </label>
          <input
            onChange={handleChange}
            value={values.contactNo}
            type="text"
            name="contactNo"
            id="contactNo"
            className={errors.contactNo && touched.contactNo && 'error_border'}
          />
          {errors.contactNo && touched.contactNo && (
            <p className="star">{errors.contactNo}</p>
          )}

          <label htmlFor="email">
            E-mail <span className="star">*</span>
          </label>
          <input
            onChange={handleChange}
            value={values.email}
            type="email"
            name="email"
            id="email"
            className={errors.email && touched.email && 'error_border'}
          />
          {errors.email && touched.email && (
            <p className="star">{errors.email}</p>
          )}

          <label htmlFor="department">Department</label>
          <input
            onChange={handleChange}
            value={values.department}
            type="text"
            name="department"
            id="department"
          />
          <label htmlFor="describe">
            Describe <span className="star">*</span>
          </label>
          <textarea
            onChange={handleChange}
            value={values.describe}
            type="text"
            name="describe"
            id="describe"
            className={errors.describe && touched.describe && 'error_border'}
          />
          {errors.describe && touched.describe && (
            <p className="star">{errors.describe}</p>
          )}

          <label htmlFor="address">Address</label>
          <input
            onChange={handleChange}
            value={values.address}
            type="text"
            name="address"
            id="address"
          />
          {msg && <p className="messages_success">{msg}</p>}
          <button type="submit">Submit</button>
        </form>
      </div>
    </section>
  );
};

export default TouchContact;
