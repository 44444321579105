import React from 'react'
import { Outlet } from 'react-router-dom'
import { Menubar } from './AdminSideBar'
import { MdDashboard, MdMedicalServices, MdLocalHospital, MdExitToApp, MdClear } from "react-icons/md";
const AdminDashboard = () => {
    return (
        <div className='flex h-[100vh] gap-4'>
            <div className='w-[300px] p-6 md:block bg-[#2ABFC4]' >
                <div className=" border-2 flex justify-between items-center">
                    <div>Logo</div>
                </div>
                <div className='text-center my-4'>
                    <div className='w-24 h-24 mx-auto'>
                    <img src="/assets/nurseservices.jpg" alt="" className='rounded-2xl mx-auto' />
                    </div>
                    <h2>Admin: Name</h2>
                </div>
                <div>
                    <Menubar listOfMenu={listOfMenu} listIcon={listIcon} listogActive={listogActive} />
                </div>
            </div>
            
            <div className='border-2'>
                <Outlet />
            </div>
        </div>
    )
}

export default AdminDashboard


let listOfMenu = [
    'Dashboard',
    'Nurse Services',
    'Medical Tourism',
    'Log Out'
];

let listogActive = [
    'dashboard',
    'nurse-services',
    'medical-tourism',
    'logout'
];

const listIcon = [
    <MdDashboard />,
    <MdLocalHospital />,
    <MdMedicalServices />,
    <MdExitToApp />
];

