import React from 'react'
import './TourismServices.css'
const TourismServices = ({name, text, img}) => {
  return (
    <div className="services-item">
      <div className="services-img">
        <img src={img} alt="" />
      </div>
      <div className="services-text">
        <h4>{name}</h4>
        <p>
          {text}
        </p>
      </div>
    </div>
  )
}

export default TourismServices