import './Hero.css';
import { Link } from 'react-router-dom';

import { AiFillFacebook, AiFillInstagram,AiFillLinkedin,AiFillTwitterSquare,AiFillYoutube } from "react-icons/ai";
import { GrAndroid, GrApple } from 'react-icons/gr';
import Navbar from '../Navbar/Navbar';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';



const Hero = () => {
  return (
    <section className="hero_container">
      <Navbar menuBlack />
      <div className="hero_main">
        <div className="hero_banner">
          <div className="hero_banner-left">
            <h1>
            Welcome to the <br /><span>
            Parasol health Limited
            </span>
            </h1>
            <p className='pb-2'>Your well-being is our top priority.</p>
            <p>
            Parasol Health is a high-tech health care & insure tech services company. Providing service including Nursing & Caregiver, Medical Tourism, Pharmacy, Tele-Medicine, Appointment with Specialist Doctors, Diagnostics, Hospitalization, Psycho-Counselling, Health Card.
            </p>

            <div className="hero_donload_app_wrap">
              <div className="hero_donload_app_user">
                <div className="style_dot">
                  <span></span>
                  <span></span>
                </div>
                <h4>Download Our USER App</h4>
                <div className="hero_donload_app_user_icon">
                  <Link to="#" className="bubble-animate-btn">
                    <img src="/assets/Google-Play-Icon 1.png" alt="app" />
                  </Link>
                  <Link to="#" className="bubble-animate-btn">
                    <img
                      src="/assets/apple-app-store-icon-png-11 1.png"
                      alt="app"
                    />
                  </Link>
                </div>
              </div>

              <div className="hero_donload_app_vendor">
                <div className="style_dot">
                  <span></span>
                  <span></span>
                </div>
                <h4>Download Our VENDOR App</h4>
                <div className="hero_donload_app_vendor_icon">
                  <Link to="#" className="bubble-animate-btn">
                    <img src="/assets/Google-Play-Icon 1.png" alt="app" />
                  </Link>
                  <Link to="#" className="bubble-animate-btn">
                    <img
                      src="/assets/apple-app-store-icon-png-11 1.png"
                      alt="app"
                    />
                  </Link>
                </div>
              </div>
            </div>

            <div className="app_icons">
              <div className="app_icons_inner">
                <span>Download USER App</span>
                <div className="app_icons_inner-icon">
                  <Link to="#">
                    <GrAndroid />
                  </Link>
                  <Link to="#">
                    <GrApple />
                  </Link>
                </div>
              </div>
              <div className="app_icons_inner">
                <span>Download VENDOR App</span>
                <div className="app_icons_inner-icon">
                  <Link to="#">
                    <GrAndroid />
                  </Link>
                  <Link to="#">
                    <GrApple />
                  </Link>
                </div>
              </div>
            </div>

            {/* <span className="hero_animatin-1"></span>
            <span className="hero_animatin-2">+</span>
            <span className="hero_animatin-3">+</span>
            <span className="hero_animatin-4"></span> */}
          </div>

          <div className="hero_banner-right border-indigo-500">


            <Swiper spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              // navigation={true}
              modules={[Autoplay, Pagination, Navigation]} className="mySwiper bunner-slider hero_banner_right-poster border-2 rounded-lg">
              <SwiperSlide>
                <img src="/assets/hero_banner/service.jpg" alt="Hero Poster" className="rounded-lg h-full" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="/assets/hero_banner/nurse.png" alt="Hero Poster" className="rounded-lg h-full" />
              </SwiperSlide>
            </Swiper>

            {/* <img src="/assets/hero_banner/service.jpg" alt="Hero Poster" className="rounded-lg" />
              <img src="/assets/hero_banner/nurse.png" alt="Hero Poster" className="rounded-lg" /> */}

            <div className="hero_banner_right-social">
              <span className="hero_banner_right-line"></span>
              <a target="_blank" href="https://facebook.com">
                <AiFillFacebook className="text-3xl social_icon1 text-[#1674EA]" />
              </a>
              <a target="_blank" href="https://instagram.com">
                <AiFillInstagram className="text-3xl social_icon3 text-[#D9435E]" />
              </a>
              <a target="_blank" href="https://linkedin.com">
                <AiFillLinkedin className="text-3xl social_icon4 text-[#0072b1]" />
              </a>
              <a target="_blank" href="https://twitter.com">
                <AiFillTwitterSquare className="text-3xl social_icon2 text-[#1DA1F2]" />
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCe5OzHatSUm_YCi2O2PguiQ"
              >
                <AiFillYoutube className="text-3xl social_icon5 text-[#c4302b]" />
              </a>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default Hero;
