import React from 'react';
import './AppPosterCard.css';

const AppPosterCard = () => {
  return (
    <section className="app_card_container w-[80%] mx-auto">
      <div className="app_card_right">
        <h2>Download Our Mobile Application</h2>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Veritatis,
          ullam.
          <br /> consectetur adipisicing elit. Veritatis, ullam.
        </p>
      </div>
      <div className="app_card_left">
        <a href="#" target="_blank" rel="noopener noreferrer">
          <img src="/assets/Google-Play-Icon 1.png" alt="" />
        </a>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <img src="/assets/apple-app-store-icon-png-11 1.png" alt="" />
        </a>
      </div>
    </section>
  );
};

export default AppPosterCard;
