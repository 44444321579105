import React from 'react'
import './SectionHeading.css'
const SectionHeading = (props) => {
  const { title, span, button, text } = props;
  return (
    <>
      <div className="offer-heading">
        <h2>{title} <span>{span}</span></h2>
        <p><button>{button}</button></p>
      </div>
      <p>{text}</p>
    </>
  )
}

export default SectionHeading