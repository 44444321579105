import './DoctorBooking.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MdDeleteSweep } from 'react-icons/md';

export const DoctorBookingContainer = ({ pageTitle }) => {
  const [doctorList, setDoctorList] = useState('');
  const adminData = useSelector((info) => {
    return info.user.info;
  });

  const url = process.env.REACT_APP_BACKEND_PRIFILE_URL;

  const getDoctorList = async () => {
    try {
      const { data } = await axios.get(`${url}/doctorbooking/alllist`, {
        headers: { authorization: 'Bearer ' + adminData.token },
      });
      return setDoctorList(data);
    } catch (error) {
      return;
    }
  };

  const deletedUser = async (id) => {
    try {
      await axios.delete(`${url}/doctorbooking/${id}`, {
        headers: { authorization: 'Bearer ' + adminData.token },
      });
      getDoctorList();
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    getDoctorList();
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    return () => {
      unlisten();
    };
  }, []);

  return (
    <>
      <h3>{pageTitle}</h3>
      <div className="doctor_Booking_wrap">
        <div className="doctor_booking_header">
          <h4>patients Name</h4>
          <h4>Contact Number</h4>
          <h4>Deperment</h4>
          <h4>Action</h4>
        </div>
        <div className="doctor_booking_content_wrap">
          {doctorList.doctor ? (
            doctorList.doctor.map((doctor) => {
              return (
                <div className="doctor_booking_content" key={doctor._id}>
                  <p>{doctor.patientsName}</p>
                  <p>
                    <a href={`tel:${doctor.contactNo}`}>{doctor.contactNo}</a>
                  </p>
                  <p>{doctor.department}</p>
                  <p>
                    <button onClick={() => deletedUser(doctor._id)}>
                      <MdDeleteSweep />
                      Detete
                    </button>
                  </p>
                </div>
              );
            })
          ) : (
            <p>Loading....</p>
          )}
        </div>
      </div>
    </>
  );
};
