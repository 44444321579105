import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialState = {
  info: Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null,
};

const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginReducer: (state, action) => {
      state.info = action.payload;
    },
  },
});

export const { loginReducer } = userReducer.actions;

export default userReducer.reducer;
