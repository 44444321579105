import './Counter.css';

const Counter = () => {
  return (
    <div className="counter-container">
      <div className="counter-content">
        <CounterCard title="Patients" count="500" icon="medical1 1.png" />
        <CounterCard title="Department" count="12" icon="department1 1.png" />
        <CounterCard
          title="Expert Doctors"
          count="100"
          icon="microscope1 1.png"
        />
        <CounterCard title="Total Labs" count="60" icon="stethoscope1 1.png" />
        <CounterCard title="Total Labs" count="60" icon="microscope1 1.png" />
        <CounterCard title="Patients" count="500" icon="medical1 1.png" />
        <CounterCard title="Department" count="12" icon="department1 1.png" />
        <CounterCard
          title="Expert Doctors"
          count="100"
          icon="microscope1 1.png"
        />
        <CounterCard title="Total Labs" count="60" icon="stethoscope1 1.png" />
        <CounterCard title="Total Labs" count="60" icon="microscope1 1.png" />
      </div>
    </div>
  );
};

const CounterCard = ({ title, count, icon }) => {
  return (
    <div className="counter_card">
      <img src={`/assets/${icon}`} alt="Counter Image" />
      <h3>{count}+</h3>
      <h4>{title}</h4>
    </div>
  );
};

export default Counter;
