import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { array } from 'yup/lib/locale';
import './RegisterUser.css';

const RegisterUser = ({ pageTitle }) => {
  const [users, setUsers] = useState('');

  let image =
    'https://img.freepik.com/free-vector/illustration-user-avatar-icon_53876-5907.jpg?w=826&t=st=1667463254~exp=1667463854~hmac=78de6b706259adf5d624e43ea312ece001859e1b98a1b86299916a10fe524f8e';

  const url = process.env.REACT_APP_BACKEND_PRIFILE_URL;

  let token = useSelector((info) => {
    return info.user.info.token;
  });

  const getRegisterUser = async () => {
    const { data } = await axios.get(`${url}/fontend-user-get`, {
      headers: {
        authorization: 'Bearer ' + token,
      },
    });
    setUsers(data.getUser);
  };

  useEffect(() => {
    getRegisterUser();
  }, []);
  return (
    <>
      <h3>{pageTitle}</h3>
      <section className="users_register">
        <div className="users_register-header">
          <h3>Name</h3>
          <h3>Email</h3>
          <h3>Contact No</h3>
        </div>
        <div className="users_register_content-wrap">
          {users ? (
            users.map((user) => {
              return (
                <div className="users_register-content" key={user._id}>
                  <p>
                    <img src={user?.picture || image} alt="User Avatar" />
                    {user?.fullName}
                  </p>

                  <p>
                    <a href={`mailto:${user?.email}`}>exmpale@mail.com</a>
                  </p>
                  <p>
                    <a href={`call:+88${user?.mobileNo}`}>{user?.mobileNo}</a>
                  </p>
                </div>
              );
            })
          ) : (
            <p>Loading....</p>
          )}
        </div>
      </section>
    </>
  );
};

export default RegisterUser;
