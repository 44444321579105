import React, { useState } from 'react';
import NurseHeader from '../../components/nurse-dashboard/nurse_header';
import SiteDrawer from '../../components/nurse-dashboard/SiteDrawer';
import UserInfo from '../../components/nurse-dashboard/User_Info';
import { RiServiceFill } from 'react-icons/ri';
import { Outlet, Link } from 'react-router-dom';
import {
  MdAccountBalanceWallet,
  MdBusinessCenter,
  MdHistory,
  MdOutlinePending,
} from 'react-icons/md';
import { FaStar } from 'react-icons/fa';
import { AiFillStar } from 'react-icons/ai';
import { GrAddCircle } from 'react-icons/gr';
import DatePicker from 'react-multi-date-picker';
import { BsFillCalendar2CheckFill } from 'react-icons/bs';


let primaryBtn =
  'bg-[#2ABFC4] font-semibold text-[18px] capitalize mx-auto hover:bg-[#00b8be] my-4 btn text-white w-full max-w-sm';

export default function NurseUser() {
  const [isMenuActive, setIsMenuActive] = useState('allservice');
  return (
    <section>
      <div className="nurseDashboard_grid_top">
        <NurseHeader />
      </div>
      <div className="nurseDashboard_wrap">
        <div className="nurseDashboard_grid_left hidden md:block absolute top-1 left-0 md:static">
          <SiteDrawer
            isMenuActive={isMenuActive}
            setIsMenuActive={setIsMenuActive}
            listOfMenu={listOfMenu}
            listogActive={listogActive}
            listOfIcon={listOfIcon}
          />
        </div>
        {/* <div className="nurseDashboard_grid_right">
          <UserInfo />
        </div> */}
        <div className="nurseDashboard_grid_body">
          <Outlet />
        </div>
      </div>
    </section>
  );
}

let listOfMenu = [
  'Dashboard',
  'Appointment',
  'Pending Appointment',
  'History',
  'Transatoin',
];

let listogActive = [
  'dashboard',
  'appointment',
  'pending-appointment',
  'history',
  'transatoin',
];
let listOfIcon = [
  <RiServiceFill />,
  <BsFillCalendar2CheckFill />,
  <MdOutlinePending />,
  <MdHistory />,
  <MdAccountBalanceWallet />,
];

// ============CategoryService========================

// ============UserHistory========================

export const UserHistory = () => {
  const [isActive, setIsActive] = useState('all');
  return (
    <div>
      <h3 className="text-[1.4rem] mb-4 font-bold">History</h3>
      <ul className="flex  gap-2 text-[17px] font-medium capitalize">
        <li
          className={`flex-1 cursor-pointer text-center p-2 rounded-sm shadow-sm ${
            isActive === 'all' && 'bg-[#2ABFC4] text-white'
          }`}
          onClick={() => setIsActive('all')}
        >
          All
        </li>
        <li
          className={`flex-1 cursor-pointer text-center p-2 rounded-sm shadow-sm ${
            isActive === 'complate' && 'bg-[#2ABFC4] text-white'
          }`}
          onClick={() => setIsActive('complate')}
        >
          Complete
        </li>
        <li
          className={`flex-1 cursor-pointer text-center p-2 rounded-sm shadow-sm ${
            isActive === 'pending' && 'bg-[#2ABFC4] text-white'
          }`}
          onClick={() => setIsActive('pending')}
        >
          Pending
        </li>
      </ul>

      <div className="mt-2 flex flex-col flex-wrap justify-center gap-4">
        {new Array(5).fill(0).map(() => (
          <div className="h-40 py-3 px-4 border rounded-xl">
            <div className="flex items-center text-[17px] font-semibold justify-between my-2">
              <h4>01 Mar 2022, 09:30 PM</h4>
              <h4>৳ 100</h4>
            </div>
            <div className="flex gap-2">
              <img
                src="/assets/nurseselfe.svg"
                className="h-14 w-14 rounded-full"
                alt=""
              />
              <p>You are appoint to Dr. Laksman Tomorrow at 10:00 AM</p>
            </div>
            <hr className="my-2" />
            <button className="text-[#128041] font-semibold ">
              See Schedule
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
// ============UserTransactions========================

export const UserTransactions = () => {
  return (
    <div>
      <h3 className="text-[1.4rem] mb-4 font-bold">Transactions</h3>
      <div className="">
        <div className="text-[14px] flex items-center justify-between max-w-xl">
          <p>Description</p>
          <p>Amount</p>
        </div>
        <div className="">
          {new Array(5).fill(0).map((item, i) => (
            <div
              className={`${
                i % 2 == 0 && 'bg-[#F1F1F1]'
              } flex justify-between items-start p-2`}
            >
              <div className="flex flex-col gap-1">
                <p>Md. Akbar Ali</p>
                <p>Bkash payment</p>
                <p>20th March, 2022. 09:00 AM</p>
              </div>
              <p className="text-[#F1634C]">1000 BDT</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// ============UserNurseViewProfile========================

// ============PatientSelect========================
export const PatientSelect = () => {
  const [addPatient, setAddPatient] = useState(false);
  return (
    <div>
      <h3 className="text-[1.4rem] mb-4 font-bold">Patient Select</h3>

      <div className="border max-h-64 rounded-2xl shadow-sm max-w-xl w-full flex gap-4 py-4">
        <div className="ml-4 mt-4">
          <img
            src="/assets/Rectangle 155.png"
            className="h-[120px] w-[120px] rounded-full border border-cyan-200"
            alt=""
          />
        </div>
        <div className="">
          <h3 className="text-[1.2rem] font-semibold ">
            Mohammad Akhtaruzzaman
          </h3>
          <p>Home nursing specialist</p>
          <div className="mb-2">
            <p className="font-medium">BNMC No: 231561321</p>
            <p className="font-medium">Experience : 4 year</p>
          </div>
          <div className="flex items-center gap-4 font-semibold">
            <p className="flex items-center gap-2">
              <MdBusinessCenter
                color="#2ABFC4"
                className="bg-[#2abfc426] w-10 h-10 p-2 rounded-full"
              />{' '}
              03
            </p>
            <p className="flex items-center gap-2">
              <FaStar
                color="#FF9529"
                className="bg-[#ff952926] w-10 h-10 p-2 rounded-full"
              />{' '}
              4.5
            </p>
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-col gap-6">
        <Link to='/user/booking' className="py-3 px-2 gap-3 items-center rounded-2xl max-w-xl border flex cursor-pointer hover:border-cyan-400 hover:shadow-md transition-all active:scale-95">
          
          <img
            src="/assets/Ellipse 23.png"
            alt=""
            className="w-16 h-16 rounded-full"
            />
          <div className="flex-1">
            <p className="font-medium">Zenifer Lopez</p>
            <p className="text-[14px] text-gray-400">You</p>
          </div>
          
        </Link>
        <div className="py-3 px-4 rounded-2xl max-w-xl border cursor-pointer hover:border-cyan-400 hover:shadow-md transition-all active:scale-95">
          <p className="font-medium">Zenifer Lopez</p>
          <p className="text-[14px] text-gray-400">Cousin</p>
        </div>
        <button
          className="py-6 justify-center px-4 flex items-center rounded-2xl max-w-xl border cursor-pointer hover:border-cyan-400 hover:shadow-md transition-all active:scale-95"
          onClick={() => setAddPatient(!addPatient)}
        >
          <GrAddCircle className="mr-2" size={25} />
          <p className="font-semibold">Add new patient</p>
        </button>
      </div>

      {addPatient && (
        <>
          <div className="mt-8 flex flex-wrap gap-4">
            <BookingInputer labelName="Full Name*" />
            <BookingInputer labelName="Date of birth*" type="date" />
            {/*  */}
            <BookingSelectInputer
              labelName="Gender*"
              name="gender"
              children={
                <>
                  <option value="" selected>
                    Male
                  </option>
                  <option value="">Female</option>
                  <option value="">Other</option>
                </>
              }
            />
            <BookingSelectInputer
              labelName="Blood Group*"
              name="bloodgroup"
              children={
                <>
                  <option value="" selected>
                    AB+
                  </option>
                  <option value="">A+</option>
                  <option value="">A-</option>
                </>
              }
            />
            <BookingInputer labelName="Weight*" />
            <BookingInputer labelName="Height*" />
            <BookingInputer labelName="Contact number" />
            <BookingSelectInputer
              labelName="Relation with you"
              name="relationwithyou"
              children={
                <>
                  <option value="" selected>
                    what ?
                  </option>
                  <option value="">what ?</option>
                </>
              }
            />
          </div>
          <button className={primaryBtn}>Add to patient list</button>
        </>
      )}
    </div>
  );
};
// ============NurseBookingForm========================

export const NurseBookingForm = () => {
  const [isMenuActive, setIsMenuActive] = useState(true);
  // const [needCaregiver, setNeedCaregiver] = useState('No');

  return (
    <div className="">
      <h3 className="text-[22px] font-semibold underline">Booking Info</h3>

      <div className=" mt-4">
        <h3 className="">
          <span className="text-[18px]">Name :</span>{' '}
          <span className="capitalize text-[18px] font-medium">name</span>
        </h3>
        <ul className="flex border-b-2 mt-2">
          <li
            onClick={() => setIsMenuActive(true)}
            className={`flex-1 cursor-pointer  text-center text-[17px] font-semibold py-2 ${
              isMenuActive && 'bg-[#2abfc47e] '
            }`}
          >
            Service
          </li>
          <li
            onClick={() => setIsMenuActive(false)}
            className={`flex-1 py-2 cursor-pointer  text-center text-[17px] font-semibold ${
              !isMenuActive && 'bg-[#2abfc47e]'
            }`}
          >
            Duratoin
          </li>
        </ul>

        {isMenuActive ? (
          <>
            {/* Part ==== 1 */}
            <div className="">
              {new Array(5).fill('0').map(() => (
                <div className="form-control px-4 py-2 hover:border-b-cyan-400 hover:border-b-2 transition-all shadow-md mt-1">
                  <label className="cursor-pointer label flex justify-between items-center">
                    <span className="label-text text-[17px] font-medium">
                      Remember me
                    </span>

                    <div className="flex items-center gap-2">
                      <span className="font-semibold">500</span>
                      <input
                        type="checkbox"
                        className="checkbox checkbox-info"
                      />
                    </div>
                  </label>
                </div>
              ))}
              <div className="my-4 text-center">
                <button className={primaryBtn}>next</button>
              </div>
            </div>

            {/* Part ==== 2 */}

            <BookingFormLayout />
            {/* Part ==== 3 */}
            <PatientInfoShower />
          </>
        ) : (
          <>
            <BookingFormLayout />
            <PatientInfoShower />
          </>
        )}
      </div>
    </div>
  );
};
// ============BookingFormLayout========================

const BookingFormLayout = () => {
  const [needCaregiver, setNeedCaregiver] = useState('No');
  const [isMultiDate, setIsMultiDate] = useState('day');
  const [value, setValue] = useState([]);
  console.log('value==>', value);

  return (
    <>
      <div className="border rounded-xl shadow-md py-4 px-6">
        <ul className="flex flex-wrap justify-center gap-2 p-2 ">
          {value.length !== 0 &&
            value.map((date) => (
              <li className="border text-[17px] w-fit max-w-full font-medium px-3 py-2 rounded-md border-cyan-200 shadow-sm bg-slate-100 ">
                {`${date?.month.number}-${date?.day}-${date?.year}`}
              </li>
            ))}
        </ul>
        <form
          action=""
          className="w-full gap-x-4 flex flex-wrap justify-center"
        >
          <BookingSelectInputer
            labelName="Package"
            name="isEmergency"
            onChange={setIsMultiDate}
            children={
              <>
                <option value="day" selected>
                  1 hour
                </option>
                <option value="day">1 Day</option>
                <option value="a">1 week</option>
                <option value="a">2 week</option>
                <option value="a">3 week</option>
                <option value="a">1 month</option>
              </>
            }
          />
          {/* Here if day hoi ta hola remove single date field and add multidate button********** */}
          {isMultiDate === 'day' && (
            <BookingInputer labelName="Date" type="date" />
          )}
          {/* Here if day hoi ta hola remove single date field and add multidate button********** */}
          <BookingInputer labelName="Time" type="time" placeholder="H: M:" />
          <BookingSelectInputer
            labelName="Shift"
            name="shift"
            children={
              <>
                <option value="" selected>
                  Day
                </option>
                <option value="">Night</option>
                <option value="">Fully</option>
              </>
            }
          />
          <BookingSelectInputer
            labelName="Patient"
            name="isEmergency"
            children={
              <>
                <option value="" selected>
                  Normal
                </option>
                <option value="">Critical</option>
              </>
            }
          />
          <BookingInputer
            labelName="Possible Work"
            placeholder="Working Details"
          />
          <BookingInputer
            labelName="Reason"
            placeholder="Briefly Discrete your Problem"
          />
          <BookingSelectInputer
            labelName="Select Address"
            name="area"
            children={
              <>
                {new Array(50).fill(0).map((item, i) => (
                  <option value="" selected={i == 0}>
                    Address {i}
                  </option>
                ))}
              </>
            }
          />
          <BookingInputer labelName="Address" placeholder="Proper Address" />
          {/* if Caregiver is yes than show blow twofield */}
          <BookingSelectInputer
            labelName="Select Caregivr"
            name="caregiver"
            onChange={setNeedCaregiver}
            children={
              <>
                <option value="Yes">Yes</option>
                <option selected value="No">
                  No
                </option>
              </>
            }
          />
          {needCaregiver == 'Yes' && (
            <>
              <BookingInputer
                labelName="Number of Caregivr"
                placeholder="Type number of caregiver"
              />
              <BookingInputer
                labelName="Caregivr"
                placeholder="Day of Caregivr"
              />
            </>
          )}

          {isMultiDate !== 'day' && (
            <div className="relative">
              <div className="w-0 h-0 overflow-hidden">
                <DatePicker
                  value={value}
                  id="test"
                  onChange={setValue}
                  multiple={true}
                />
              </div>

              <label
                htmlFor="test"
                className="rounded-md shadow-md block px-6 py-2 mt-3 bg-cyan-400 font-medium"
              >
                Select Date
              </label>
            </div>
          )}
        </form>
        <div className="mt-4 text-center  ">
          <button className={primaryBtn}>Proceed</button>
        </div>
      </div>
    </>
  );
};
// ============PatientInfoShower========================
const PatientInfoShower = () => {
  const KeyValuePair = ({ label1, value1, label2, value2 }) => (
    <div className="flex mb-2 capitalize max-w-full text-[17px]">
      <div className="flex-1">
        <p>{label1}</p>
        <p className="font-semibold">{value1}</p>
      </div>
      <div className="flex-1">
        <p>{label2}</p>
        <p className="font-semibold">{value2}</p>
      </div>
    </div>
  );
  return (
    <>
      <div className="mt-8">
        <div className="border w-full h-full py-6 px-8">
          <div className="w-full h-full flex flex-wrap gap-4">
            <div className="min-w-[400px] max-w-full flex-[0.5]">
              <h3 className="text-[20px] font-bold text-[#2ABFC4] underline">
                Patient information
              </h3>
              <div className="mt-2">
                <KeyValuePair
                  label1="Patient Name"
                  value1="Nurmohammad"
                  label2="Mobile Number"
                  value2="+880 1758-236589"
                />
                <KeyValuePair
                  label1="Patient Age"
                  value1="25 years"
                  label2="Patient Weight"
                  value2="60 (KG)"
                />
                <KeyValuePair
                  label1="Reason"
                  value1="Brain Problem"
                  label2="Address"
                  value2="House: 15, Road: 02, Block: D, Mirpur-1."
                />
              </div>
            </div>
            <div className="min-w-[400px] max-w-full flex-[0.5]">
              <h3 className="text-[20px] font-bold text-[#2ABFC4] underline">
                Consultant information
              </h3>
              <div className="mt-2">
                <KeyValuePair
                  label1="Doctor Name"
                  value1="Dr.Alexander Zefer "
                  label2="Start Time"
                  value2="09:30 AM"
                />
                <KeyValuePair
                  label1="Appoint Date"
                  value1="20th March, 2022"
                  label2="End Time"
                  value2="11:30 AM"
                />
              </div>
            </div>

            <div className="min-w-[400px] max-w-full flex-[0.5]">
              <h3 className="text-[20px] font-bold text-[#2ABFC4] underline">
                Payment Information
              </h3>
              <div className="mt-2">
                <KeyValuePair
                  label1="From"
                  value1="Rakibul Islam"
                  label2="To"
                  value2="Dr. Alexander Xefer"
                />
                <KeyValuePair
                  label1="Account name"
                  value1="Rakibul Islam"
                  label2="Account number"
                  value2="01304-350205"
                />
                <KeyValuePair
                  label1="Purpose"
                  value1="Stay Home Duty"
                  label2="Amount"
                  value2="10,00.00 BDT"
                />
              </div>
            </div>
            <div className="min-w-[400px] max-w-full flex-[0.5]">
              <h3 className="text-[20px] font-bold text-[#2ABFC4] underline">
                Caregiver
              </h3>
              <div className="mt-2">
                <KeyValuePair
                  label1="Caregiver"
                  value1="No"
                  label2="Number of Caregiver"
                  value2="N/A"
                />
              </div>
            </div>
          </div>
          <div className="form-control max-w-fit mt-2">
            <label className="cursor-pointer label">
              <input type="checkbox" className="checkbox checkbox-info mr-4" />
              <span className="label-text">
                I Agree To Parasol’s{' '}
                <a href="#" className="text-[#2ABFC4] font-semibold underline">
                  Terms & Conditions.
                </a>
              </span>
            </label>
          </div>
          <div className="text-center">
            <button className={primaryBtn}>Payment</button>
          </div>
        </div>
      </div>
    </>
  );
};

// ============SearchNurse========================
export const SearchNurse = () => {
  return (
    <>
      <div className="">test</div>
    </>
  );
};
// ============BookingInputer========================
const BookingInputer = ({ labelName, type, placeholder, onChange }) => (
  <div className="flex flex-col gap-1 mt-3 min-w-[22rem]">
    {labelName && <label htmlFor="">{labelName}</label>}
    <input
      type={type ? type : 'text'}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      className="input input-bordered input-info w-full max-w-full"
    />
  </div>
);
// ============BookingSelectInputer========================
const BookingSelectInputer = ({ labelName, name, children, onChange }) => (
  <div className="flex flex-col mt-3 min-w-[22rem]">
    <label htmlFor="" className="text-[17px] capitalize mb-1">
      {labelName}
    </label>
    <select
      onChange={(e) => onChange(e.target.value)}
      name={name}
      className="select select-info w-full max-w-full "
    >
      {children}
    </select>
  </div>
);

// ============Appointment========================
export const Appointment=()=>{
  return(
    <div>
      <h1 className='my-4 text-2xl font-semibold'>Appointment</h1>
      <div className="appointment-card rounded-lg grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className='p-4 border-2 flex gap-4 items-center'>
          <div className="image w-24">
            <img className='rounded-full' src="/assets/nurseselfe.svg" alt="" />
          </div>
          <div className="text">
            <h2 className='font-semibold'>Asadullah</h2>
            <p>Appointment id : 123</p>
            <p>Date : 20 aug 2023</p>
            <p>Time : 08:00 PM</p>
            <p>Amount: 500</p>
            <p>Request status: Active</p>
            <p>Payment status: Paid</p>
            <button className='py-2 px-3 border-2 border-blue-600'>View Details</button>
          </div>
        </div>
        <div className='p-4 border-2 flex gap-4 items-center'>
          <div className="image w-24">
            <img className='rounded-full' src="/assets/nurseselfe.svg" alt="" />
          </div>
          <div className="text">
            <h2 className='font-semibold'>Asadullah</h2>
            <p>Appointment id : 123</p>
            <p>Date : 20 aug 2023</p>
            <p>Time : 08:00 PM</p>
            <p>Amount: 500</p>
            <p>Request status: Active</p>
            <p>Payment status: Paid</p>
            <button className='py-2 px-3 border-2 border-blue-600'>View Details</button>
          </div>
        </div>
        <div className='p-4 border-2 flex gap-4 items-center'>
          <div className="image w-24">
            <img className='rounded-full' src="/assets/nurseselfe.svg" alt="" />
          </div>
          <div className="text">
            <h2 className='font-semibold'>Asadullah</h2>
            <p>Appointment id : 123</p>
            <p>Date : 20 aug 2023</p>
            <p>Time : 08:00 PM</p>
            <p>Amount: 500</p>
            <p>Request status: Active</p>
            <p>Payment status: Paid</p>
            <button className='py-2 px-3 border-2 border-blue-600'>View Details</button>
          </div>
        </div>
        <div className='p-4 border-2 flex gap-4 items-center'>
          <div className="image w-24">
            <img className='rounded-full' src="/assets/nurseselfe.svg" alt="" />
          </div>
          <div className="text">
            <h2 className='font-semibold'>Asadullah</h2>
            <p>Appointment id : 123</p>
            <p>Date : 20 aug 2023</p>
            <p>Time : 08:00 PM</p>
            <p>Amount: 500</p>
            <p>Request status: Active</p>
            <p>Payment status: Paid</p>
            <button className='py-2 px-3 border-2 border-blue-600'>View Details</button>
          </div>
        </div>
        <div className='p-4 border-2 flex gap-4 items-center'>
          <div className="image w-24">
            <img className='rounded-full' src="/assets/nurseselfe.svg" alt="" />
          </div>
          <div className="text">
            <h2 className='font-semibold'>Asadullah</h2>
            <p>Appointment id : 123</p>
            <p>Date : 20 aug 2023</p>
            <p>Time : 08:00 PM</p>
            <p>Amount: 500</p>
            <p>Request status: Active</p>
            <p>Payment status: Paid</p>
            <button className='py-2 px-3 border-2 border-blue-600'>View Details</button>
          </div>
        </div>
      </div>
    </div>
  )
};
// ============Pending Appointment========================
export const PendingAppointment=()=>{
  return(
    <div>
      <h1 className='my-4 text-2xl font-semibold'>Pending Appointment</h1>
      <div className="appointment-card rounded-lg grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className='p-4 border-2 flex gap-4 items-center'>
          <div className="image w-24">
            <img className='rounded-full' src="/assets/nurseselfe.svg" alt="" />
          </div>
          <div className="text">
            <h2 className='font-semibold'>Asadullah</h2>
            <p>Appointment id : 123</p>
            <p>Date : 20 aug 2023</p>
            <p>Time : 08:00 PM</p>
            <p>Amount: 500</p>
            <p>Request status: Pending</p>
            <p>Payment status: Unpaid</p>
            <button className='py-2 px-3 border-2 border-blue-600'>View Details</button>
          </div>
        </div>
        <div className='p-4 border-2 flex gap-4 items-center'>
          <div className="image w-24">
            <img className='rounded-full' src="/assets/nurseselfe.svg" alt="" />
          </div>
          <div className="text">
            <h2 className='font-semibold'>Asadullah</h2>
            <p>Appointment id : 123</p>
            <p>Date : 20 aug 2023</p>
            <p>Time : 08:00 PM</p>
            <p>Amount: 500</p>
            <p>Request status: Pending</p>
            <p>Payment status: Unpaid</p>
            <button className='py-2 px-3 border-2 border-blue-600'>View Details</button>
          </div>
        </div>
        <div className='p-4 border-2 flex gap-4 items-center'>
          <div className="image w-24">
            <img className='rounded-full' src="/assets/nurseselfe.svg" alt="" />
          </div>
          <div className="text">
            <h2 className='font-semibold'>Asadullah</h2>
            <p>Appointment id : 123</p>
            <p>Date : 20 aug 2023</p>
            <p>Time : 08:00 PM</p>
            <p>Amount: 500</p>
            <p>Request status: Pending</p>
            <p>Payment status: Unpaid</p>
            <button className='py-2 px-3 border-2 border-blue-600'>View Details</button>
          </div>
        </div>
        <div className='p-4 border-2 flex gap-4 items-center'>
          <div className="image w-24">
            <img className='rounded-full' src="/assets/nurseselfe.svg" alt="" />
          </div>
          <div className="text">
            <h2 className='font-semibold'>Asadullah</h2>
            <p>Appointment id : 123</p>
            <p>Date : 20 aug 2023</p>
            <p>Time : 08:00 PM</p>
            <p>Amount: 500</p>
            <p>Request status: Pending</p>
            <p>Payment status: Unpaid</p>
            <button className='py-2 px-3 border-2 border-blue-600'>View Details</button>
          </div>
        </div>
        <div className='p-4 border-2 flex gap-4 items-center'>
          <div className="image w-24">
            <img className='rounded-full' src="/assets/nurseselfe.svg" alt="" />
          </div>
          <div className="text">
            <h2 className='font-semibold'>Asadullah</h2>
            <p>Appointment id : 123</p>
            <p>Date : 20 aug 2023</p>
            <p>Time : 08:00 PM</p>
            <p>Amount: 500</p>
            <p>Request status: Pending</p>
            <p>Payment status: Unpaid</p>
            <button className='py-2 px-3 border-2 border-blue-600'>View Details</button>
          </div>
        </div>
      </div>
    </div>
  )
};
