import {
  Counter,
  FeaturedVideo,
  Footer,
  Frequently,
  Hero,
  Navbar,
  PostCard,
  Testimonials,
} from "../../components";
import Bunner from "../../components/hero/Bunner";
import { CommonDoctor } from "../../components/ourDoctors/OurDoctors";
import { postData } from "../../tmp-data";
import "./Home.css";
const Home = () => {
  return (
    <>
      <div className="home_container">
        <Hero />
        <section className="our_services pt-10">
          <h4>Our Services</h4>
          <h2 className="font-semibold">We Provide The Best Services</h2>
          <div className="our_services_posts">
            {postData.map((item, i) => (
              <PostCard
                key={i}
                img={item.img}
                title={item.title}
                content={item.content}
                link={item.link}
              />
            ))}
          </div>
        </section>
        <FeaturedVideo />
        <Frequently />
        <Counter />
        {/* <CommonDoctor /> */}
        <Testimonials />
      </div>
      <Footer />
    </>
  );
};

export default Home;
