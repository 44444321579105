import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';

import {
  MdOutlineAddCircleOutline,
  MdRemoveCircleOutline,
} from 'react-icons/md';

import './Frequently.css';
const Frequently = () => {
  const [showAccordion, setShowAccordion] = useState('one');
  const [sendMsg, setSendMsg] = useState('');

  const validateSchema = yup.object({
    patientName: yup.string().required().min(3),
    email: yup.string().required().email(),
    question: yup.string().required().min(6),
  });

  let initialValues = {
    patientName: '',
    email: '',
    question: '',
  };

  const {
    errors,
    values,
    resetForm,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: async ({ patientName, email, question }) => {
      const url = process.env.REACT_APP_BACKEND_PRIFILE_URL;
      try {
        await axios.post(`${url}/questen`, {
          patientName,
          email,
          question,
        });
        setSendMsg('Your qustion send successfully');
        resetForm();
      } catch (error) {
        return 'error';
      }
    },
    validationSchema: validateSchema,
  });

  return (
    <section className="frequently_container">
      <h2>Frequently Asked Questions</h2>
      <div className="frequently_content">
        <div className="frequently_content-left">
          <div
            className="frequently_content_left-accordion"
            style={{ color: `${showAccordion == 'one' ? '#fff' : '#313030'}` }}
          >
            <div
              className="accordion_title"
              style={{
                backgroundColor: `${
                  showAccordion == 'one' ? '#2ABFC4' : '#F6F6F6'
                }`,
              }}
              onClick={() =>
                showAccordion === 'one'
                  ? setShowAccordion(null)
                  : setShowAccordion('one')
              }
            >
              {showAccordion == 'one' ? (
                <MdRemoveCircleOutline className="accordion_icon" />
              ) : (
                <MdOutlineAddCircleOutline className="accordion_icon" />
              )}
              <h5>What assistance are provided under nursing care?</h5>
            </div>

            {showAccordion == 'one' && (
              <span className="accordion_content">
               Parasol Health Ltd. nursing care is here to provide with post care treatment assistances and medical needs of the patient.
              </span>
            )}
          </div>
          <div
            className="frequently_content_left-accordion"
            style={{ color: `${showAccordion == 'two' ? '#fff' : '#313030'}` }}
          >
            <div
              className="accordion_title"
              style={{
                backgroundColor: `${
                  showAccordion == 'two' ? '#2ABFC4' : '#F6F6F6'
                }`,
              }}
              onClick={() =>
                showAccordion === 'two'
                  ? setShowAccordion(null)
                  : setShowAccordion('two')
              }
            >
              {showAccordion == 'two' ? (
                <MdRemoveCircleOutline className="accordion_icon" />
              ) : (
                <MdOutlineAddCircleOutline className="accordion_icon" />
              )}
              <h5>What will be the frequency of visits of the attendant?</h5>
            </div>

            {showAccordion == 'two' && (
              <span className="accordion_content">
                The attendant’s visit will highly be dependent on the demand of the patients and their needs.
                The attendant’s visit will highly be dependent on the demand of the patients and their needs.
              </span>
            )}
          </div>
          <div
            className="frequently_content_left-accordion"
            style={{
              color: `${showAccordion == 'three' ? '#fff' : '#313030'}`,
            }}
          >
            <div
              className="accordion_title"
              style={{
                backgroundColor: `${
                  showAccordion == 'three' ? '#2ABFC4' : '#F6F6F6'
                }`,
              }}
              onClick={() =>
                showAccordion === 'three'
                  ? setShowAccordion(null)
                  : setShowAccordion('three')
              }
            >
              {showAccordion == 'three' ? (
                <MdRemoveCircleOutline className="accordion_icon" />
              ) : (
                <MdOutlineAddCircleOutline className="accordion_icon" />
              )}
              <h5>What are qualifications of the attendants?</h5>
            </div>

            {showAccordion == 'three' && (
              <span className="accordion_content">
                All the medical attendants are trained and certified with experienced qualifications along with ICU expertise.
              </span>
            )}
          </div>
          <div
            className="frequently_content_left-accordion"
            style={{ color: `${showAccordion == 'four' ? '#fff' : '#313030'}` }}
          >
            <div
              className="accordion_title"
              style={{
                backgroundColor: `${
                  showAccordion == 'four' ? '#2ABFC4' : '#F6F6F6'
                }`,
              }}
              onClick={() =>
                showAccordion === 'four'
                  ? setShowAccordion(null)
                  : setShowAccordion('four')
              }
            >
              {showAccordion == 'four' ? (
                <MdRemoveCircleOutline className="accordion_icon" />
              ) : (
                <MdOutlineAddCircleOutline className="accordion_icon" />
              )}
              <h5>What kind of services does Parasol health provide?</h5>
            </div>

            {showAccordion == 'four' && (
              <span className="accordion_content">
                Parasol health provide Nursing & Caregiver, Medical Tourism, Pharmacy, Tele-Medicine, Appointment with Specialist Doctors, Diagnostics, Hospitalization, Psycho-Counselling, Health Card
              </span>
            )}
          </div>
          <div
            className="frequently_content_left-accordion"
            style={{ color: `${showAccordion == 'five' ? '#fff' : '#313030'}` }}
          >
            <div
              className="accordion_title"
              style={{
                backgroundColor: `${
                  showAccordion == 'five' ? '#2ABFC4' : '#F6F6F6'
                }`,
              }}
              onClick={() =>
                showAccordion === 'five'
                  ? setShowAccordion(null)
                  : setShowAccordion('five')
              }
            >
              {showAccordion == 'five' ? (
                <MdRemoveCircleOutline className="accordion_icon" />
              ) : (
                <MdOutlineAddCircleOutline className="accordion_icon" />
              )}
              <h5>Does Parasol Health provide treatments?</h5>
            </div>

            {showAccordion == 'five' && (
              <span className="accordion_content">
                Yes, we facilitate your treatment requirements with the most reputed hospitals and experienced doctors across the country and globe. You choose the treatment and we ensure that you get the most affordable yet the best quality services at the most advanced healthcare centers.
              </span>
            )}
          </div>
          <div
            className="frequently_content_left-accordion"
            style={{ color: `${showAccordion == 'six' ? '#fff' : '#313030'}` }}
          >
            <div
              className="accordion_title"
              style={{
                backgroundColor: `${
                  showAccordion == 'six' ? '#2ABFC4' : '#F6F6F6'
                }`,
              }}
              onClick={() =>
                showAccordion === 'six'
                  ? setShowAccordion(null)
                  : setShowAccordion('six')
              }
            >
              {showAccordion == 'six' ? (
                <MdRemoveCircleOutline className="accordion_icon" />
              ) : (
                <MdOutlineAddCircleOutline className="accordion_icon" />
              )}
              <h5>Is Parasol Health a hospital?</h5>
            </div>

            {showAccordion == 'six' && (
              <span className="accordion_content">
                No, Parasol Health is not a hospital. It is the bridge which connects your treatment requirements to the right and quality medical care. Unlike others, we just do not take care of providing you with treatment options, but we ensure that from discovery to discharge, you experience comfort. We customize the treatment journey as per the need of the patients. We have associated with 100+ hospitals in Bangladesh, India & Thailand, Singapore & Turkey. to help our patients access the best healthcare.
              </span>
            )}
          </div>
        </div>
        <div className="frequently_content-right">
          <h4>Have Any Other Questions?</h4>
          <hr />
          <form onSubmit={handleSubmit}>
            <label htmlFor="username">Patients Name *</label>
            <input
              name="patientName"
              value={values.patientName}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              placeholder="Your name"
              id="username"
              className={errors.patientName && touched.patientName && 'error'}
            />
            <label htmlFor="email">E-mail *</label>
            <input
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              type="email"
              placeholder="E-mail address"
              id="email"
              className={errors.email && touched.email && 'error'}
            />
            <label htmlFor="question">Ask A Question *</label>
            <textarea
              name="question"
              value={values.question}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Write your concern."
              id="question"
              className={errors.question && touched.question && 'error'}
            ></textarea>
            <button type="submit">Submit</button>
            {sendMsg && <p className="qustion_message">{sendMsg}</p>}
          </form>
        </div>
      </div>
    </section>
  );
};

export default Frequently;
