import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { CgClose } from "react-icons/cg";
import { FaPhone, FaEnvelope  } from "react-icons/fa";
import "./Navbar.css";

const nestedItem = (
  <>
    <ul className="nav-nested-animatoin">
      <li>
        <NavLink
          className={({ isActive }) => (isActive ? "navActive" : "")}
          to="/services/training-services"
        >
          Nursing & Caregiver Service
        </NavLink>
      </li>
      <li>
        <NavLink
          className={({ isActive }) => (isActive ? "navActive" : "")}
          to="/services/tele-medicine"
        >
          Tele-Medicine
        </NavLink>
      </li>
      <li>
        <NavLink
          className={({ isActive }) => (isActive ? "navActive" : "")}
          to="/services/pharmacy"
        >
          Pharmacy
        </NavLink>
      </li>
      <li>
        <NavLink
          className={({ isActive }) => (isActive ? "navActive" : "")}
          to="/services/medical-tourism"
        >
          Medical Tourism
        </NavLink>
      </li>
      <li>
        <NavLink
          className={({ isActive }) => (isActive ? "navActive" : "")}
          to="/services/appointment-doctors"
        >
          Appointment With Specialist Doctors{" "}
        </NavLink>
      </li>
      <li>
        <NavLink
          className={({ isActive }) => (isActive ? "navActive" : "")}
          to="/services/diagnostic"
        >
          Diagnostic
        </NavLink>
      </li>
      <li>
        <NavLink
          className={({ isActive }) => (isActive ? "navActive" : "")}
          to="/services/hospitalization"
        >
          Hospitalization
        </NavLink>
      </li>
      <li>
        <NavLink
          className={({ isActive }) => (isActive ? "navActive" : "")}
          to="/services/psycho-counselling"
        >
          Psycho Counselling
        </NavLink>
      </li>
      <li>
        <NavLink
          className={({ isActive }) => (isActive ? "navActive" : "")}
          to="/services/health-card"
        >
          Health Card
        </NavLink>
      </li>
    </ul>
  </>
);

const Navbar = ({ white, menuBlack, bgWhite }) => {
  const [showNested, SetshowNested] = useState(false);
  const [showMenu, SetshowMenu] = useState(false);
  const [fixtMenu, SetFixtMenu] = useState(false);
  const [fixtMobileMenu, SetFixtMobileMenu] = useState(false);

  function setFixed() {
    if (window.scrollY >= 300 && window.innerWidth > 1024) {
      SetFixtMenu(true);
      SetFixtMobileMenu(false);
      return;
    } else if (window.scrollY >= 300 && window.innerWidth < 1024) {
      SetFixtMenu(false);
      SetFixtMobileMenu(true);
      return;
    } else if (window.scrollY < 300) {
      SetFixtMenu(false);
      SetFixtMobileMenu(false);
      return;
    }
  }
  window.addEventListener("scroll", setFixed);

  return (
    <>
      <section
        className={`nav_container ${fixtMobileMenu ? "mobile_stiky" : null}`}
        style={{
          background: `${bgWhite ? "var(--text-white)" : "transparent"}`,
        }}
      >
        <div className="navbar-header">
        <div
          className="top_nav"
          style={{
            color: "var(--text-white)",
          }}
        >
          <div className="flex items-center gap-5">
          <a href="tel:+8801329669947" className="flex items-center gap-2"> <FaPhone></FaPhone> <span>(+88) 01329-669947</span></a>
          <a href="mailto:info@parasolhealth.org" className="flex items-center gap-2"> <FaEnvelope></FaEnvelope> <span>info@parasolhealth.org</span> </a>
          </div>
          <div className="top-logo">
            <p><Link to="/user-login">Login</Link></p>
          </div>
        </div>
        </div>
        <div className="navbar">
          <Link to="/">
            <img src="/assets/Parasol-Health.svg" alt="Logo" />
          </Link>
          <div
            className="navbar_menu"
            style={{
              color: `${menuBlack ? "var(--text-black)" : "var(--text-white)"}`,
            }}
          >
            <nav>
              <Link to="/">Home</Link>
              <NavLink
                className={({ isActive }) => (isActive ? "navActive" : "")}
                to="/aboutus"
              >
                About Us
              </NavLink>
              <Link
                to="#"
                className={`nested_menu ${
                  window.location.href.split("/")[3] === "services"
                    ? "navActive"
                    : ""
                }`}
              >
                Services +{nestedItem}
              </Link>
              {/* <NavLink
                className={({ isActive }) => (isActive ? "navActive" : "")}
                to="/doctors"
              >
                Our Psycologist
              </NavLink> */}

              <NavLink
                className={({ isActive }) => (isActive ? "navActive" : "")}
                to="/registration"
              >
                Registration
              </NavLink>
              <NavLink
                className={({ isActive }) => (isActive ? "navActive" : "")}
                to="/connect-us"
              >
                Contact
              </NavLink>
            </nav>
          </div>
          <button type="button" onClick={() => SetshowMenu(!showMenu)}>
            {showMenu ? <CgClose /> : <GiHamburgerMenu />}
          </button>
        </div>
        <div
          className={
            fixtMenu
              ? "mobile_menu-container mobile_sticky"
              : "mobile_menu-container"
          }
        >
          {showMenu && (
            <nav className="mobile_menu">
              <nav>
                <Link to="/">Home</Link>
                <NavLink
                  className={({ isActive }) => (isActive ? "navActive" : "")}
                  to="/aboutus"
                >
                  About Us
                </NavLink>
                <NavLink
                  to="#"
                  className={`${
                    window.location.href.split("/")[3] === "services"
                      ? "navActive1"
                      : ""
                  }  
                ${
                  showNested
                    ? "nested_mobile nested_animation1"
                    : "nested_mobile"
                }`}
                  onClick={() => SetshowNested(!showNested)}
                >
                  Services +{showNested && nestedItem}
                </NavLink>
                {/* <NavLink
                  className={({ isActive }) => (isActive ? "navActive" : "")}
                  to="/doctors"
                >
                  Our Psycologist
                </NavLink> */}
                <NavLink
                  className={({ isActive }) => (isActive ? "navActive" : "")}
                  to="/registration"
                >
                  Online Registration
                </NavLink>
                <NavLink
                  className={({ isActive }) => (isActive ? "navActive" : "")}
                  to="/connect-us"
                >
                  Contact
                </NavLink>
              </nav>
            </nav>
          )}
        </div>
      </section>
      {fixtMenu && (
        <section
          className={`nav_container ${fixtMobileMenu ? "mobile_stiky" : null}`}
          style={{
            background: `${bgWhite ? "var(--text-white)" : "transparent"}`,
          }}
        >
          <div
            className="top_nav"
            style={{
              color: "var(--text-white)",
            }}
          >
            <a href="tel:+8801708545295">Contact : (+88) 01708-545295</a>
            <a href="mailto:parasol@gmail.com">E-mail : parasol@gmail.com</a>
          </div>
          <div className="navbar sticky">
            <Link to="/">
              <img src="/assets/Parasol-Health.svg" alt="Logo" />
            </Link>
            <div
              className="navbar_menu"
              style={{
                color: `${
                  menuBlack ? "var(--text-black)" : "var(--text-white)"
                }`,
              }}
            >
              <nav>
                <Link to="/">Home</Link>
                <NavLink
                  className={({ isActive }) => (isActive ? "navActive" : "")}
                  to="/aboutus"
                >
                  About Us
                </NavLink>
                <Link
                  to="#"
                  className={`nested_menu ${
                    window.location.href.split("/")[3] === "services"
                      ? "navActive"
                      : ""
                  }`}
                >
                  Services +{nestedItem}
                </Link>
                {/* <NavLink
                  className={({ isActive }) => (isActive ? "navActive" : "")}
                  to="/doctors"
                >
                  Our Psycologist
                </NavLink> */}

                <NavLink
                  className={({ isActive }) => (isActive ? "navActive" : "")}
                  to="/registration"
                >
                  Registration
                </NavLink>
                <NavLink
                  className={({ isActive }) => (isActive ? "navActive" : "")}
                  to="/connect-us"
                >
                  Contact
                </NavLink>
              </nav>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Navbar;
