import { Route, Routes } from "react-router-dom";
import { IoIosArrowUp } from "react-icons/io";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import {
  Home,
  AboutUs,
  TeleMedicine,
  Pharmacy,
  MedicalTourism,
  AppointmentDoctors,
  Diagnostic,
  Hospitalization,
  PsychoCounselling,
  HealthCard,
  TrainingServices,
  Doctors,
  ConnectUs,
  DoctorDetails,
  Registration,
  SubmitDone,
  PageNotFound,
  NurseDashboard,
} from "./pages";
import { AllDoctor } from "./components/ourDoctors/OurDoctors";
import { DoctorBookingContainer } from "./admin/pages/DoctorBooking/DoctorBookingList";
import {
  AllDoctors,
  ContactList,
  Dashboard,
  Login,
  PrivateRoute,
  QustionList,
  RegisterUser,
  Subscribe,
  Users,
} from "./admin";
import { useEffect, useState } from "react";
import { ScrollToTop } from "./helpers/ScrollToTop";
import NurseUser, {
  Appointment,
  NurseBookingForm,
  PatientSelect,
  PendingAppointment,
  UserHistory,
  UserTransactions,
} from "./pages/Nurse_User_Dashboard/NurseUser";
import UserSignup from "./pages/vendor/nurse_auth/nurse_signup";
import { ToastContainer } from "react-toastify";
import NurseUserSendOTP from "./pages/Nurse_User_Dashboard/auth/NurseUserSendOTP";
import NurseUserVerifyOTP from "./pages/Nurse_User_Dashboard/auth/NurseUserVerifyOTP";
import NurseUserSignup from "./pages/Nurse_User_Dashboard/auth/NurseUserSignup";
import NurseUserLogin from "./pages/Nurse_User_Dashboard/auth/NurseUserLogin";
import ProctedRoute from "./pages/Nurse_User_Dashboard/ProctedRoute/ProctedRoute";
import NurseServices from "./pages/Nurse_User_Dashboard/NurseServices/NurseServices";
import { CategoryNurseService } from "./pages/Nurse_User_Dashboard/NurseServices/CategoryNurseService";
import { NurseViewProfile } from "./pages/Nurse_User_Dashboard/Booking/NurseViewProfile";
import NurseSendOtp from "./pages/vendor/nurse_auth/nurse_sendotp";
import NurseVerifyotp from "./pages/vendor/nurse_auth/nurse_verifyotp";
import Whoareyou from "./pages/vendor/nurse_auth/whoareyou";
import SignupFormNurse from "./pages/vendor/nurse_auth/signup_form_nurse";
import CongraculatoinNurse from "./pages/vendor/Congraculatoin/Congraculatoin_nurse";
import AdminDashboard from "./admin/pages/dashboard/AdminDashboard/AdminDashboard";

function App() {
  const [isScroll, setIsScroll] = useState(false);

  const getToTop = () =>
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  const scrollNew = () => {
    const WinScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    WinScroll > 250 ? setIsScroll(true) : setIsScroll(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollNew);
    return () => window.removeEventListener("scroll", scrollNew);
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <ScrollToTop>
        <Routes>
          {/*============== Frontend Route start ============== */}

          <Route index path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route
            path="services/training-services"
            element={<TrainingServices />}
          />
          <Route path="services/tele-medicine" element={<TeleMedicine />} />
          <Route path="services/pharmacy" element={<Pharmacy />} />
          <Route path="services/medical-tourism" element={<MedicalTourism />} />
          <Route
            path="services/appointment-doctors"
            element={<AppointmentDoctors />}
          />
          <Route path="services/diagnostic" element={<Diagnostic />} />
          <Route
            path="services/hospitalization"
            element={<Hospitalization />}
          />
          <Route
            path="services/psycho-counselling"
            element={<PsychoCounselling />}
          />
          <Route path="services/health-card" element={<HealthCard />} />
          {/* ========= Doctors and Psychologist ========= */}
          {/* <Route path="/" element={<Doctors />}>
            <Route path="doctors" element={<AllDoctor />} />
            <Route path="Psychologist" element={<AllDoctor />} />
          </Route> */}
          {/* <Route
            path="/doctors/doctor-details/:id"
            element={<DoctorDetails />}
          /> */}
          {/* <Route
            path="/Psychologist/doctor-details/:id"
            element={<DoctorDetails />}
          /> */}
          {/* ========= Doctors and Psychologist End ========= */}
          <Route path="/registration" element={<Registration />} />
          <Route path="/connect-us" element={<ConnectUs />} />
          {/* Register Done than this page load  */}
          <Route path="/submitdone" element={<SubmitDone />} />
          <Route path="login" element={<Login />} />

          {/* ============== Frontend Route End ============== */}

          {/* ========Nurse vandor Route========== */}
          {/* <Route path="/sendotp-nurse" element={<NurseSendOtp />} />
          <Route path="/verifyotp-nurse" element={<NurseVerifyotp />} />
          <Route path="/whoareyou-nurse" element={<Whoareyou />} />
          <Route path="/signup-form-nurse" element={<SignupFormNurse />} />
          <Route
            path="/congraculatoin-nurse"
            element={<CongraculatoinNurse />}
          /> */}
          <Route path="/login-nurse" element={<p>Login Nurse</p>} />
          {/* <Route path="/nurse-user-signup" element={<UserSignup />} /> */}
          <Route path="/nurse-dashboard" element={<NurseDashboard />} />
          {/* ========Nurse User Route Start ========== */}
          {/* User Auth */}
          <Route path="/send-otp" element={<NurseUserSendOTP />} />
          <Route path="/verify-otp" element={<NurseUserVerifyOTP />} />
          <Route path="/user-signup" element={<NurseUserSignup />} />
          <Route path="/user-login" element={<NurseUserLogin />} />
          {/* User Dashboard */}
          <Route
            path="user"
            element={
              <ProctedRoute>
                <NurseUser />
              </ProctedRoute>
            }
          >
            <Route path="dashboard" element={<NurseServices />} />
            <Route
              path="category-nurseservices"
              element={<CategoryNurseService />}
            />
            {/* ==Running== */}
            <Route
              path="nurse-profile/:nurseId"
              element={<NurseViewProfile />}
            />
            <Route path="appointment" element={<Appointment/>} />
            <Route path="pending-appointment" element={<PendingAppointment/>} />
            <Route path="booking" element={<NurseBookingForm />} />
            <Route path="history" element={<UserHistory />} />
            <Route path="transatoin" element={<UserTransactions />} />
            <Route path="patient-select" element={<PatientSelect />} />
          </Route>
          {/* ========Nurse User Route End ========== */}

          {/* ============= Admin Dashboard ============= */}
          <Route
            path="dashboard"
            element={
              // <PrivateRoute>
              // </PrivateRoute>
              <AdminDashboard />
            }
          >
            <Route
              path="test"
              element={<h1>Test1</h1>}
            />
            <Route
              path="list1"
              element={<DoctorBookingContainer pageTitle="Touch With Us" />}
            />
            <Route
              path="list2"
              element={<ContactList pageTitle="Contact Us List" />}
            />
            <Route
              path="list3"
              element={<QustionList pageTitle="Ask Questions" />}
            />
            <Route
              path="list4"
              element={<Subscribe pageTitle="Subscribe List" />}
            />
            <Route
              path="list5"
              element={<AllDoctors pageTitle="Doctor List" />}
            />
            <Route path="usersList" element={<Users pageTitle="Users" />} />
            <Route
              path="users-register"
              element={<RegisterUser pageTitle="Users Register" />}
            />
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </ScrollToTop>

      {isScroll && (
        <button onClick={getToTop} className="scroll_to_top">
          <IoIosArrowUp className="arrow_icon-scroll" />
        </button>
      )}

        {/* TODO: Display Button Unhide  */}
      {/* <div className="hotline-phone-ring-container">
        <a href="#" target="_blank" rel="noopener noreferrer">
          <div class=" call_wrapper">
            <div class="call_ring">
              <div class="call_coccoc-alo-phone call_coccoc-alo-green call_coccoc-alo-show">
                <div class="call_coccoc-alo-ph-circle"></div>
                <div class="call_coccoc-alo-ph-circle-fill"></div>
                <div class="call_coccoc-alo-ph-img-circle"></div>
              </div>
              <div className="call_numbertn">
                <p>012-3456-6789</p>
              </div>
            </div>
          </div>
        </a>
      </div> */}
    </div>
  );
}

export default App;
