import React from 'react'
import './SingleHospital.css'
const SingleHospital = (props) => {
    const {name, address,hospitalImage} = props;
    // const bgImage = {
    //   backgroundImage: `url(${hospitalImage})`,
    // };
  return (
    <div className="hospital-item">
    <div className="hospital-img overflow-hidden">
      <img src={hospitalImage} alt="" className='hover:scale-115 w-full h-full rounded-lg' />
    </div>
    <div className="hospital-info">
      <h3>{name}</h3>
      <p>{address}</p>
    </div>
  </div>
  )
}

export default SingleHospital