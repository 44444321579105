import './FeaturedVideo.css';
import { BsPlayCircle, BsPauseCircle } from 'react-icons/bs';
import { useState } from 'react';
// import ReactPlayer from 'react-player/lazy'

const FeaturedVideo = () => {
  const [isVideoPlay, setIsVideoPlay] = useState(false);

  const handlePlay = () => {
    let FeaturedVideo = document.getElementById('FeaturedVideo');
    setIsVideoPlay(true);
    FeaturedVideo.play();
  };

  const handlePause = () => {
    let FeaturedVideo = document.getElementById('FeaturedVideo');
    setIsVideoPlay(false);
    FeaturedVideo.pause();
  };

  return (
    <section className="featured_conatiner">
      <div className="featured_conatiner_shadow">
        <div className="featured_content-box">
          <div className="featured_content-left">
            <h3>Featured Video</h3>
            <p>
            In an era where health and well-being take center stage, the digital realm has become a powerful ally in our pursuit of a healthier lifestyle. Parasol Health is  a digital health website can be the gateway to a world of information, guidance, and motivation
            </p>
            <button type="button">Learn More</button>
          </div>
          <div className="featured_content-right">
            <div className="featured_content_right-video">
              {/* <ReactPlayer url='https://www.youtube.com/embed/QUMxGLo3UbY' /> */}

              <iframe className="video-container rounded-lg" id="FeaturedVideo" width="560" height="315" src="https://www.youtube.com/embed/QUMxGLo3UbY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              {/* <video
                id="FeaturedVideo"
                src="https://www.youtube.com/embed/QUMxGLo3UbY"
                poster="/assets/Rectangle 167.png"
                className="video-container"
              ></video> */}

              {/* <video
                id="FeaturedVideo"
                controls
                poster="/assets/Rectangle 167.png"
                class="video-container"
              >
                <source
                  src="https://www.youtube.com/embed/QUMxGLo3UbY"
                  type="video/youtube"
                />
              </video> */}
              {/* {!isVideoPlay ? (
                <span className="video_control">
                  <img
                    src="/assets/Frame173.svg"
                    alt="Play"
                    onClick={handlePlay}
                  />
                </span>
              ) : (
                //<BsPlayCircle onClick={handlePlay} className="video_control" />

                <BsPauseCircle
                  onClick={handlePause}
                  className={
                    isVideoPlay ? 'video_control Pause' : 'video_control'
                  }
                />
              )} */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedVideo;
