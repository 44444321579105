import { Link } from 'react-router-dom';
import './FooterPoster.css';

const FooterPoster = () => {
  return (
    <section className="footerPoster_container">
      <div className="footerPoster_liner">
        <img src="/assets/Vector8.png" alt="" />
      </div>
      <div className="footerPoster_content-container">
        <div className="footerPoster_content-left">
          <h4>Download Our Mobile App.</h4>
          <p>
            Download the app for better visibility and explore more attractive
            features.
          </p>
        </div>
        <div className="footerPoster_content-right">
          <div className="footerPoster_content_right-top">
            <h5>Download Vendor App</h5>
            <Link to="#" className="bubble-animate-btn">
              <img src="/assets/Google-Play-Icon 1.png" alt="app" />
            </Link>
            <Link to="#" className="bubble-animate-btn">
              <img src="/assets/apple-app-store-icon-png-11 1.png" alt="app" />
            </Link>
          </div>
          <div className="footerPoster_content_right-bottom">
            <h5>Download User App</h5>
            <Link to="#" className="bubble-animate-btn">
              <img src="/assets/Google-Play-Icon 1.png" alt="app" />
            </Link>
            <Link to="#" className="bubble-animate-btn">
              <img src="/assets/apple-app-store-icon-png-11 1.png" alt="app" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterPoster;
