import axios from 'axios';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { FaStar } from 'react-icons/fa';
import { MdBusinessCenter } from 'react-icons/md';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {Link} from 'react-router-dom'

export const NurseViewProfile = () => {
  const [isActive, setIsActive] = useState('speciality');
  let { nurseId } = useParams();
  const [nurse, setNurse] = useState('');
  let token = Cookies.get('parasol_token');

  async function getNurseDeatilsInfo() {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/nurse_profile_details?user_id=${nurseId}`,
        config
      );
      setNurse(response?.data?.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  useEffect(() => {
    getNurseDeatilsInfo();
  }, []);

  return (
    <div>
      <h3 className="text-[1.4rem] mb-4 font-bold">Nurse Profile</h3>
      <div className="">
        <div className="border max-h-72 rounded-2xl shadow-sm  w-full flex items-center  gap-4 py-4 mb-4">
          {nurse ? (
            <>
              <div className="ml-4 my-4 ">
                <img
                  src={
                    !nurse?.image
                      ? nurse?.image
                      : 'https://img.freepik.com/free-vector/faceless-portrait-nurse-isolated-vector-illustration_7504-679.jpg?w=826&t=st=1687620897~exp=1687621497~hmac=5c5a33ed002bde58c34946bce30348b3cbf8139744ba5a29cbd562a9ab09ba0f'
                  }
                  className="h-[200px] w-[200px] rounded-xl border border-cyan-200"
                  alt=""
                />
              </div>
              <div className="flex-1">
                <h3 className="text-[1.5rem] font-medium ">{nurse?.name}</h3>
                <p>Home nursing specialist</p>
                <div className="mb-2">
                  <p className="font-medium">
                    BNMC No: {nurse?.bnmc_no ? nurse?.bnmc_no : '__'}
                  </p>
                  <p className="font-medium">
                    Experience :{' '}
                    {nurse?.year_of_experience ? nurse?.year_of_experience : 0}{' '}
                    year
                  </p>
                </div>
                <div className="flex items-center gap-4 font-semibold">
                  <p className="flex items-center gap-2">
                    <MdBusinessCenter
                      color="#2ABFC4"
                      className="bg-[#2abfc426] w-10 h-10 p-2 rounded-full"
                    />{' '}
                    {nurse?.patient_served}
                  </p>
                  <p className="flex items-center gap-2">
                    <FaStar
                      color="#FF9529"
                      className="bg-[#ff952926] w-10 h-10 p-2 rounded-full"
                    />{' '}
                    {nurse?.rating}
                  </p>
                </div>
                <div className="w-full mt-2 text-end pr-4">
                  <Link to='/user/patient-select'>
                  <button className="font-medium text-white block bg-[#2ABFC4] py-2 px-6 rounded-md hover:bg-[#F1634C] transition-all duration-500">
                    Make an Appointment
                  </button>
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </div>

        <div className="">
          <h3 className="text-[1.4rem] mb-4 font-semibold">About nurse</h3>
          <p>
            A physician, medical practitioner, medical doctor, or simply doctor,
            is a health professional who practices medicine, which is concerned
            with promoting, maintaining or restoring health through the study,
            diagnosis, prognosis and treatment of disease, injury, and other
            physical and mental impairments.
          </p>
        </div>
        <div className="">
          <ul className="flex items-center font-semibold my-6">
            <li
              onClick={() => setIsActive('speciality')}
              className={`py-2 cursor-pointer px-6 rounded-md ${
                isActive === 'speciality' && 'text-white bg-cyan-500'
              }`}
            >
              Speciality
            </li>
            <li
              onClick={() => setIsActive('experience')}
              className={`py-2 cursor-pointer px-6 rounded-md ${
                isActive === 'experience' && 'text-white bg-cyan-500'
              }`}
            >
              Experience
            </li>
            <li
              onClick={() => setIsActive('education')}
              className={`py-2 px-6 cursor-pointer rounded-md ${
                isActive === 'education' && 'text-white bg-cyan-500'
              }`}
            >
              Education
            </li>
            <li
              onClick={() => setIsActive('review')}
              className={`py-2 cursor-pointer px-6 rounded-md ${
                isActive === 'review' && 'text-white bg-cyan-500'
              }`}
            >
              Review
            </li>
          </ul>
          <div className="">
            {isActive === 'speciality' && (
              <div className="flex flex-wrap gap-4">
                {nurse?.specialities?.length > 0 ? (
                  nurse?.specialities.map((item) => (
                    <div className="w-28 h-28 border-2 border-cyan-400 rounded-xl flex flex-col items-center gap-2  p-2 shadow-md">
                      <img
                        src={
                          item?.icon
                            ? item?.icon
                            : '/assets/icons/injection.svg'
                        }
                        alt=""
                      />
                      <p className="font-semibold">
                        {item?.name ? item?.name : '__'}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>No specialitie found..!</p>
                )}
              </div>
            )}
            {isActive === 'experience' && (
              <div>
                {nurse?.experiences.length > 0 ? (
                  nurse?.experiences.map((item) => (
                    <div className="max-w-md p-2 shadow-sm">
                      <div className="flex items-center">
                        <p className="flex-1">Institute Name</p>
                        <p className="flex-1">
                          {': '}
                          {item?.organization_name
                            ? item?.organization_name
                            : '__'}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="flex-1">Designation</p>
                        <p className="flex-1">
                          {': '}
                          {item?.designation ? item?.designation : '__'}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No experience info found !</p>
                )}
              </div>
            )}
            {isActive === 'education' && (
              <div>
                {nurse?.academics.length > 0 ? (
                  nurse?.academics.map((item, i) => (
                    <div className="max-w-md p-2 shadow-sm" key={i}>
                      <div className="flex items-center">
                        <p className="flex-1">Institute Name</p>
                        <p className="flex-1">
                          {': '}
                          {item?.institute_name ? item?.institute_name : '__'}
                        </p>
                      </div>

                      <div className="flex items-center">
                        <p className="flex-1">Course Name</p>
                        <p className="flex-1">
                          {': '}
                          {item?.course_name ? item?.course_name : '__'}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No education info found !</p>
                )}
              </div>
            )}
            {isActive === 'review' && (
              <div className="flex flex-wrap justify-center gap-4">
                {new Array(5).fill(0).map(() => (
                  <div className="h-32 max-w-md border py-2 px-3 flex gap-3 rounded-xl shadow-sm">
                    <img
                      src="/assets/Ellipse 23.png"
                      alt=""
                      className="w-20 h-20 rounded-full border-2 border-cyan-500"
                    />
                    <div className="flex-1">
                      <h4 className="font-medium">Lorem, ipsum dolor.</h4>
                      <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Saepe, incidunt?
                      </p>
                      <div className="flex items-center gap-1 mt-2">
                        <AiFillStar color="#FF9529" />
                        <AiFillStar color="#FF9529" />
                        <AiFillStar color="#FF9529" />
                        <AiFillStar color="#FF9529" />
                        <AiFillStar color="grey" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
