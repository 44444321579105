import { Footer, TourismBunner } from '../../components';
import {
  Banner,
  CardThree,
  EaseSteps,
  NewsletterSubscribe,
  TouchContact,
  OurservicesCard,
  AppPosterCard,
} from '../../components/common';

const TrainingServices = () => {
  const servicesUs = [
    {
      "id": 1,
      "title": "Why Choose Our Nursing Services",
      "content": [
        {
          "id": 1,
          "point": "Expertise and Experience",
          "description": "Our nursing team consists of highly trained and experienced professionals who bring a wealth of medical knowledge and practical skills to every patient interaction."
        },
        {
          "id": 2,
          "point": "Compassionate Care",
          "description": "We believe in treating patients with the utmost compassion and empathy, creating a nurturing environment that promotes healing and comfort."
        },
        {
          "id": 3,
          "point": "Customized Care Plans",
          "description": "Each patient is unique, and so are their healthcare needs. We develop personalized care plans that address specific medical requirements, ensuring comprehensive and effective treatment."
        },
        {
          "id": 4,
          "point": "Collaborative Approach",
          "description": "Our nursing services are built on teamwork. We work closely with physicians, therapists, and other healthcare professionals to provide holistic care."
        }
      ]
    }
  ]
  
  return (
    <>
      <Banner title="Nursing Training & Services" />
      <div className="space_1"></div>
      <section>
        <CardThree image="Rectangle9.png" servicesUs={servicesUs[0]} />
        <div className="space_1"></div>
        <AppPosterCard />
        <div className="space_1"></div>
        <OurservicesCard />
        <div className="space_1"></div>
        <EaseSteps />
        <div className="space_1"></div>
        <TouchContact />
        <div className="space_1"></div>
      </section>
      <div className="space_1"></div>
      <Footer />
    </>
  );
};

export default TrainingServices;
