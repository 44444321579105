import './users.css';
import { FaUserPlus, FaTrashAlt } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';

const Users = ({ pageTitle }) => {
  const [allUser, setAllUser] = useState([]);
  const [visible, setVisible] = useState(false);
  const [delVisible, setDelVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const url = process.env.REACT_APP_BACKEND_PRIFILE_URL;

  let token = useSelector((info) => {
    return info.user.info.token;
  });

  // fatch all user
  const getAllUser = async () => {
    try {
      const { data } = await axios.get(`${url}/user/getAllUser`, {
        headers: {
          authorization: 'Bearer ' + token,
        },
      });
      setAllUser(data);
    } catch (error) {
      setErrorMsg(error.response.data.message);
    }
  };

  // Fatch one user and deleted

  const deleteUsre = async (id) => {
    try {
      await axios.delete(`${url}/user/delete/${id}`, {
        headers: {
          authorization: 'Bearer ' + token,
        },
      });

      setTimeout(() => {
        getAllUser();
        setDelVisible(false);
      }, 2000);
    } catch (error) {
      setErrorMsg(error.response.data.message);
    }
  };

  const initialValue = {
    name: '',
    email: '',
    password: '',
    confPassword: '',
    roles: '',
    userControll: false,
    replayControll: false,
    doctorControll: false,
    siteControll: false,
  };

  const adminAddValidatoinSchema = yup.object({
    name: yup
      .string()
      .required('Name are required')
      .min(3, 'Name minimum 3 charactor'),
    email: yup
      .string()
      .required('Email are required')
      .email('Please Enter valid Emial address'),
    password: yup.string().required('Password are required'),
    confPassword: yup.string().required('Confirm Password are required'),
    roles: yup.string().required('Please Select valid Roles are required'),
  });

  const {
    errors,
    values,
    touched,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValue,
    onSubmit: async ({
      name,
      email,
      password,
      confPassword,
      roles,
      userControll,
      replayControll,
      doctorControll,
      siteControll,
      profilePic,
    }) => {
      try {
        setErrorMsg('');

        let image = new FormData();
        image.append('profilePic', profilePic);
        const { data } = await axios.post(`${url}/image-upload`, image);

        const respons = await axios.post(
          `${url}/user/register`,
          {
            name,
            email,
            password,
            confPassword,
            roles,
            userControll,
            replayControll,
            doctorControll,
            siteControll,
            profilePic: data.uploadUrl,
          },
          {
            headers: { authorization: 'Bearer ' + token },
          }
        );
        setSuccessMsg(respons.data.message);
        resetForm();
        setTimeout(() => {
          getAllUser();
          setVisible(false);
        }, 1000);
      } catch (error) {
        setSuccessMsg('');
        setErrorMsg(error.response.data.message);
      }
    },
    validationSchema: adminAddValidatoinSchema,
  });

  useEffect(() => {
    getAllUser();
  }, []);

  return (
    <>
      <h3>{pageTitle}</h3>
      <section className="users_container">
        <div className="user_container_add">
          <button onClick={() => setVisible(true)}>
            <FaUserPlus className="add_user_icon" /> Addn New User
          </button>
        </div>
        <div className="user_header">
          <h4>Name</h4>
          <h4>email</h4>
          <h4>states</h4>
          <h4>action</h4>
        </div>
        <div className="user_content_wrap">
          {allUser.newUser ? (
            allUser.newUser.map((user, i) => {
              return (
                <div className="user_content" key={i}>
                  <p>
                    <img
                      src={
                        user.profilePic ||
                        'https://cdn.pixabay.com/photo/2022/10/02/01/58/technology-7492577_960_720.jpg'
                      }
                      alt="UserPic"
                    />
                    <span>{user?.name || 'Null'}</span>
                  </p>
                  <p>{user.email}</p>
                  <p>
                    <span
                      className={user.isBlock ? 'deactive' : 'actives'}
                    ></span>
                    {user.isBlock ? 'block' : 'active'}
                  </p>
                  <p>
                    {/* <button>
                      <GrView />
                      view
                    </button>
                    <button onClick={() => getOneUser(user._id)}>
                      <GrEdit /> edit
                    </button> */}
                    <button onClick={() => setDelVisible(user._id)}>
                      <FaTrashAlt className="user_icon_delete" />
                      delete
                    </button>
                  </p>
                </div>
              );
            })
          ) : (
            <p>Loading...</p>
          )}
        </div>

        {/* Add User Form  */}
        {visible && (
          <div className="user_add_form_container">
            <div className="user_add_header">
              <h3>Add New User</h3>
              <AiOutlineClose
                className="add_user_header_icon"
                onClick={() => setVisible(false)}
              />
            </div>

            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="name">
                  Name <span>*</span>
                </label>
                <input
                  type="text"
                  onChange={handleChange}
                  value={values.naem}
                  id="name"
                  name="name"
                />
                {errors.name && touched.name && <p>{errors.name}</p>}
              </div>
              <div>
                <label htmlFor="email">
                  Email <span>*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                />
                {errors.email && touched.email && <p>{errors.email}</p>}
              </div>

              <div>
                <label htmlFor="password">
                  Password <span>*</span>
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  onChange={handleChange}
                  value={values.password}
                />
                {errors.password && touched.password && (
                  <p>{errors.password}</p>
                )}
              </div>

              <div>
                <label htmlFor="confpassword">
                  Confirm Password <span>*</span>
                </label>
                <input
                  type="password"
                  name="confPassword"
                  id="confpassword"
                  onChange={handleChange}
                  value={values.confPassword}
                />
                {errors.confPassword && touched.confPassword && (
                  <p>{errors.confPassword}</p>
                )}
              </div>
              <div>
                <label htmlFor="roles">
                  Roles <span>*</span>
                </label>
                <select
                  name="roles"
                  id="roles"
                  onChange={handleChange}
                  value={values.roles}
                  className="user_roll_set"
                >
                  <option>Rolse</option>
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                </select>

                {errors.roles && touched.roles && <p>{errors.roles}</p>}
              </div>
              <p className="parmation_select">
                <h4>What Permission Allowed This User</h4>
                <span>
                  <label htmlFor="userCtrl">
                    <input
                      type="checkbox"
                      onChange={handleChange}
                      value={values.userControll}
                      name="userControll"
                      id="userCtrl"
                    />
                    User Ctrl
                  </label>
                  <label htmlFor="replayCtrl">
                    <input
                      type="checkbox"
                      onChange={handleChange}
                      value={values.replayControll}
                      name="replayControll"
                      id="replayCtrl"
                    />
                    Replay Ctrl
                  </label>
                  <label htmlFor="doctorCtrl">
                    <input
                      type="checkbox"
                      onChange={handleChange}
                      value={values.doctorControll}
                      name="doctorControll"
                      id="doctorCtrl"
                    />
                    doctorCtrl
                  </label>
                </span>
              </p>

              <div className="picture_avater">
                <label htmlFor="profilePic">Select User Profile Pick</label>
                <input
                  onChange={(e) => {
                    setFieldValue('profilePic', e.target.files[0]);
                  }}
                  type="file"
                  accept="image/*"
                  name="profilePic"
                  id="profilePic"
                />
              </div>

              <button type="submit">Submit</button>
              {errorMsg && <p>{errorMsg}</p>}
              {successMsg && <p>{successMsg}</p>}
            </form>
          </div>
        )}

        {delVisible && (
          <div className="user_delete_model_wrap">
            <div className="user_delete_model">
              <button onClick={() => setDelVisible(false)}>Cancel</button>
              <button onClick={() => deleteUsre(delVisible)}>Comfirm</button>
            </div>
          </div>
        )}

        {/* {editVisible && (
          <div className="user_roll_edeit_wrap">
            {oneUser?.user ? (
              <>
                <div>
                  <label onClick={() => setisBlock(!isBlock)} htmlFor="isBlock">
                    <span
                      style={{ background: `${!isBlock ? 'green' : 'red'}` }}
                      id="isBlock"
                    />
                    isBlock
                  </label>
                  <label
                    onClick={() => setreplayControll(!replayControll)}
                    htmlFor="replayControll"
                  >
                    <span
                      style={{
                        background: `${replayControll ? 'green' : 'red'}`,
                      }}
                    />
                    replayControll
                  </label>
                  <label
                    onClick={() => setdoctorControll(!doctorControll)}
                    htmlFor="doctorControll"
                  >
                    <span
                      style={{
                        background: `${doctorControll ? 'green' : 'red'}`,
                      }}
                    />
                    doctorControll
                  </label>
                  <label
                    onClick={() => setuserControll(!userControll)}
                    htmlFor="userControll"
                  >
                    <span
                      style={{
                        background: `${userControll ? 'green' : 'red'}`,
                      }}
                    />
                    userControll
                  </label>
                  <button type="submit" onClick={() => userActionUpdate()}>
                    submit
                  </button>
                </div>
                <button type="button" onClick={() => setEditVisible(false)}>
                  Calcel
                </button>
              </>
            ) : (
              <p>Loading...</p>
            )}
          </div>
        )} */}
      </section>
    </>
  );
};

export default Users;
