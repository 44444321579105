import React from 'react';

export default function NurseServices() {
  const allserviceList = [
    {
      name: 'Nurse Service',
      icon: '01.svg',
      link: '/user/category-nurseservices',
    },
    {
      name: 'Medical Tourism',
      icon: '02.svg',
      link: '#',
    },
    {
      name: 'Pharmacy',
      icon: '03.svg',
      link: '#',
    },
    {
      name: 'Psycho Counselling',
      icon: '04.svg',
      link: '#',
    },
    {
      name: 'Specialist Doctor',
      icon: '05.svg',
      link: '#',
    },
    {
      name: 'Tele-Medicine',
      icon: '06.svg',
      link: '#',
    },
    {
      name: 'Diagnostic',
      icon: '07.svg',
      link: '#',
    },
    {
      name: 'Health Card',
      icon: '08.svg',
      link: '#',
    },
    {
      name: 'Hospitalization',
      icon: '09.svg',
      link: '#',
    },
    {
      name: 'Ambulance',
      icon: '10.svg',
      link: '#',
    },
  ];
  return (
    <div className="max-h-full p-4 flex flex-wrap justify-center gap-x-6 gap-y-8">
      {allserviceList.map((item, i) => (
        <a
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
          className="w-fit h-fit"
        >
          <div className="h-[200px] w-[200px] border gap-4 border-gray-300 p-2 flex flex-col justify-center items-center shadow-md rounded-lg hover:border-cyan-400 hover:shadow-xl transition-all">
            <span className="block h-24 w-24 bg-[#F1634C] rounded-full">
              <img
                src={`/assets/icons/${item.icon}`}
                alt="I"
                className="w-full h-full p-[20%]"
              />
            </span>
            <p className="font-semibold text-[17px]">{item.name}</p>
          </div>
        </a>
      ))}
    </div>
  );
}
