import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Footer, Navbar } from '../../components';
import { NewsletterSubscribe } from '../../components/common';
import { getAllDoctor } from '../../components/ourDoctors/OurDoctors';
import './DoctorDetails.css';

const DoctorDetails = () => {
  const [doctor, setDoctor] = useState('');
  const { id } = useParams();

  useEffect(() => {
    async function test() {
      let { doctor } = await getAllDoctor();
      const data = doctor.filter((item) => item._id.toString() === id);
      setDoctor(data);
    }
    test();
  }, []);

  return (
    <section>
      <Navbar white menuBlack bgWhite />
      {doctor[0] ? (
        <div className="doctorDetails_wrap">
          <div className="doctorDetails_wrap_name">
            <h2>{doctor[0]?.fullName || 'Doctor Name'}</h2>
            <p>{'(Bangladesh)'} </p>
          </div>
          <div className="doctorDetails_line" />
          <div className="doctorDetails_wrap_details">
            <div className="doctorDetails_wrap_details-left">
              <img src={doctor[0]?.profilePic || ''} alt="Doctor_photos" />
            </div>
            <div className="doctorDetails_wrap_details-right">
              <h3>Profile Details</h3>
              <div className="doctorDetails_line" />
              <div className="doctorDetails_wrap_details_right-content">
                <div className="right_content-details">
                  <h5>Full Name :</h5>
                  <p> {doctor[0]?.fullName || 'Doctor Name'}</p>
                </div>
                <div className="right_content-details">
                  <h5>Qualifications :</h5>
                  <p>{doctor[0]?.qualifications || 'qualifications'}</p>
                </div>
                <div className="right_content-details">
                  <h5> Designation :</h5>
                  <p>{doctor[0]?.designation || 'designation'}</p>
                </div>
                <div className="right_content-details">
                  <h5>Experience :</h5>
                  <p>{doctor[0]?.experience || 'designation'}</p>
                </div>
                <div className="right_content-details">
                  <h5> Specialization : </h5>
                  <p>{doctor[0]?.specialization || 'specialization'}</p>
                </div>
                <div className="right_content-details">
                  <h5>Membership : </h5>
                  <p>{doctor[0]?.membership || 'membership'}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="doctorDetails_availability">
            <div className="doctorDetails_availability_title">
              <h3>Availability :</h3>
              <div className="doctorDetails_availability_subtitle">
                <p>{doctor[0]?.availability || 'membership'}</p>
              </div>
            </div>
            <div className="doctorDetails_line" />
            <div className="doctorDetails_availability-consultaion">
              <h3>Consultaion Fees :</h3>
              <div className="doctorDetails_availability_consultaion-content">
                {doctor[0]?.consultaion1 && <h5>{doctor[0]?.consultaion1}</h5>}
                {doctor[0]?.consultaion2 && <h5>{doctor[0]?.consultaion2}</h5>}
                {doctor[0]?.consultaion3 && <h5>{doctor[0]?.consultaion3}</h5>}
                {doctor[0]?.consultaion4 && <h5>{doctor[0]?.consultaion4}</h5>}
                {doctor[0]?.consultaion5 && <h5>{doctor[0]?.consultaion5}</h5>}
              </div>
            </div>
          </div>

          {doctor[0]?.youtubeLink && (
            <div className="doctorDetails_youtube_wrap">
              <iframe
                className="doctorDetails_youtube"
                src={doctor[0]?.youtubeLink}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          )}
        </div>
      ) : (
        <div className="loading-wrap">
          <img src="/assets/Loading.svg" alt="Loading..." />
          <p>Loading...</p>
        </div>
      )}
      <div className="space_1"></div>
      <NewsletterSubscribe />
      <div className="space_1"></div>
      <Footer />
    </section>
  );
};

export const SignleDoctor = ({
  fullName,
  profilePic,
  qualifications,
  designation,
  experience,
  specialization,
  membership,
  availability,
  consultaion1,
  consultaion2,
  consultaion3,
  consultaion4,
  consultaion5,
  youtubeLink,
}) => {
  return (
    <div className="doctorDetails_wrap">
      <div className="doctorDetails_wrap_name">
        <h2>{fullName || 'Doctor Name'}</h2>
        <p>{'(Bangladesh)'} </p>
      </div>
      <div className="doctorDetails_line" />
      <div className="doctorDetails_wrap_details">
        <div className="doctorDetails_wrap_details-left">
          <img src={profilePic || ''} alt="Doctor_photos" />
        </div>
        <div className="doctorDetails_wrap_details-right">
          <h3>Profile Details</h3>
          <div className="doctorDetails_line" />
          <div className="doctorDetails_wrap_details_right-content">
            <div className="right_content-details">
              <h5>Full Name :</h5>
              <p> {fullName || 'Doctor Name'}</p>
            </div>
            <div className="right_content-details">
              <h5>Qualifications :</h5>
              <p>{qualifications || 'qualifications'}</p>
            </div>
            <div className="right_content-details">
              <h5> Designation :</h5>
              <p>{designation || 'designation'}</p>
            </div>
            <div className="right_content-details">
              <h5>Experience :</h5>
              <p>{experience || 'designation'}</p>
            </div>
            <div className="right_content-details">
              <h5> Specialization : </h5>
              <p>{specialization || 'specialization'}</p>
            </div>
            <div className="right_content-details">
              <h5>Membership : </h5>
              <p>{membership || 'membership'}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="doctorDetails_availability">
        <div className="doctorDetails_availability_title">
          <h3>Availability :</h3>
          <div className="doctorDetails_availability_subtitle">
            <p>{availability || 'membership'}</p>
          </div>
        </div>
        <div className="doctorDetails_line" />
        <div className="doctorDetails_availability-consultaion">
          <h3>Consultaion Fees :</h3>
          <div className="doctorDetails_availability_consultaion-content">
            {consultaion1 && <h5>{consultaion1}</h5>}
            {consultaion2 && <h5>{consultaion2}</h5>}
            {consultaion3 && <h5>{consultaion3}</h5>}
            {consultaion4 && <h5>{consultaion4}</h5>}
            {consultaion5 && <h5>{consultaion5}</h5>}
          </div>
        </div>
      </div>
      {youtubeLink && (
        <div className="doctorDetails_youtube_wrap">
          <iframe
            className="doctorDetails_youtube"
            src={youtubeLink}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default DoctorDetails;
