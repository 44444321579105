import { useEffect } from 'react';
import { Footer, TourismHospital, TourismOffer } from '../../components';
import {
  Banner,
  CardThree,
  TouchContact,
} from '../../components/common';
import TourismServicesMain from '../../components/MiniComponents/TourismServices/TourismServicesMain';

const MedicalTourism = () => {
  const servicesUs = [
    {
      "id": 1,
      "title": "Why Choose Our Nursing Services",
      "content": [
        {
          "id": 1,
          "point": "Expertise and Experience",
          "description": "Our nursing team consists of highly trained and experienced professionals who bring a wealth of medical knowledge and practical skills to every patient interaction."
        },
        {
          "id": 2,
          "point": "Compassionate Care",
          "description": "We believe in treating patients with the utmost compassion and empathy, creating a nurturing environment that promotes healing and comfort."
        },
        {
          "id": 3,
          "point": "Customized Care Plans",
          "description": "Each patient is unique, and so are their healthcare needs. We develop personalized care plans that address specific medical requirements, ensuring comprehensive and effective treatment."
        },
        {
          "id": 4,
          "point": "Collaborative Approach",
          "description": "Our nursing services are built on teamwork. We work closely with physicians, therapists, and other healthcare professionals to provide holistic care."
        }
      ]
    }
  ]
  return (
    <section>
      <Banner title="Medical Tourism" />
      <div className="space_1"></div>
      {/* <TourismBunner/> */}
        <CardThree servicesUs={servicesUs[0]}/>
      <TourismServicesMain/>
      {/* <div className="space_1"></div> */}
      <TourismOffer />
      <div className="space_1"></div>
      <TourismHospital />
      {/* <TouchContact /> */}
      <div className="space_1"></div>
      <Footer />
    </section>
  );
};

export default MedicalTourism;
