import './Footer.css';
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin, AiFillTwitterSquare, AiFillYoutube } from "react-icons/ai";
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Link } from 'react-router-dom';
import FooterPoster from '../FooterPoster/FooterPoster';
import axios from 'axios';

const Footer = () => {
  const subscribeValidate = yup.object({
    email: yup.string().required().email(),
  });
  const { errors, values, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async ({ email }) => {
      const url = process.env.REACT_APP_BACKEND_PRIFILE_URL;
      await axios.post(`${url}/parasolhealth-Subscribeing`, {
        email,
      });
      resetForm();
    },
    validationSchema: subscribeValidate,
  });

  return (
    <>
      <FooterPoster />
      <footer>
        <section className="footer_info_container">
          <div className="footer_info_content-1">
            <img src="/assets/Parasol-Health.svg" alt="Parasol Health" />
            {/* <p>Lorem Ipsum is simply dummy text of the printing</p> */}
            <div className="footer_info_social">
              <p>Follow Us On</p>
              <div className="footer_info_social-icons">
                {/* <Link to="#">
                  <FiFacebook />
                </Link> */}
                <Link to="#">
                  <AiFillFacebook />
                </Link>
                <Link to="#">
                  <AiFillTwitterSquare />
                </Link>
                <Link to="#">
                  <AiFillInstagram />
                </Link>
                <Link to="#">
                  <AiFillLinkedin />
                </Link>
                <Link
                  to="https://www.youtube.com/@parasolhealth1805"
                  target="_blank"
                >
                  <AiFillYoutube />
                </Link>
              </div>
            </div>
          </div>

          <div className="footer_info_content-2">
            <h4>About Us</h4>
            <span>
              <Link to="#">Order status</Link>
              <Link to="#">Payment</Link>
              <Link to="#">Contact us</Link>
              <Link to="#">Order status</Link>
              <Link to="#">Payment</Link>
              <Link to="#">Contact us</Link>
            </span>
          </div>

          <div className="footer_info_content-3">
            <h4>Services</h4>
            <span>
              <Link to="#">Careers</Link>
              <Link to="#">Service</Link>
              <Link to="#">Supply chain acts</Link>
              <Link to="#">Privacy Policy</Link>
              <Link to="#">Terms & Conditions</Link>
            </span>
          </div>
          <div className="footer_info_content-4">
            <h4>Contact</h4>
            <span>
              <a href="#">+880 1708545295, +880 1708545290</a>
              <a href="mailto:info@parasolhealth.org">info@parasolhealth.org</a>
              <a href="http://parasolhealth.org">www.parasolhealth.org</a>
              <a href="#">Rupayan Trade Center, Level-7, 114 Kazi Nazrul Islam Ave, Banglamotor, Dhaka, Bangladesh</a>

              <form onSubmit={handleSubmit}>
                <input
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="E-mail Address"
                />
                <button disabled={errors.email && true} type="submit">
                  Subscribe
                </button>
              </form>
            </span>
          </div>
        </section>
        <section className="footer_copyRight">
          <p>@ 2020-2022 All rights reserved by Parasol Health</p>
          <hr />
          <span>
          Design and Development By Reflex Soft Ltd
          </span>
        </section>
      </footer>
    </>
  );
};

export default Footer;
