import './Login.css';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { loginReducer } from '../../reducer/userReducer';
import { useNavigate } from 'react-router-dom';
import DotLoader from 'react-spinners/DotLoader';

const Login = () => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminLoginSchema = yup.object({
    email: yup
      .string()
      .email('Please Enter valid Email address')
      .required('Email address are required'),
    password: yup.string().required('password are required'),
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      onSubmit: async ({ email, password }) => {
        setLoading(true);
        try {
          const url = process.env.REACT_APP_BACKEND_LOGIN_URL;
          const { data } = await axios.post(`${url}/login`, {
            email,
            password,
          });
          setError('');
          setLoading(false);
          setSuccess(data.message);

          const { message, ...rest } = data;

          setTimeout(() => {
            dispatch(loginReducer(rest));
            Cookies.set('user', JSON.stringify(rest));

            navigate('/dashboard/list1');
          }, 1000);
        } catch (error) {
          setLoading(false);
          setSuccess('');
          setError(error.response.data.message);
        }
      },
      validationSchema: adminLoginSchema,
    });

  return (
    <section className="login">
      <div className="login_container">
        <img
          src="https://cdn.pixabay.com/photo/2019/02/10/18/56/umbrella-3987973_960_720.jpg"
          alt="Logo"
        />
        <h3>BOSS WELCOME BACK...</h3>
        <form onSubmit={handleSubmit}>
          <div className="error_message">
            <input
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              name="email"
              placeholder="Enter Your Email address"
              className={errors.email && touched.email && 'error'}
            />
            {errors.email && touched.email && <p>{errors.email}</p>}
          </div>
          <div className="error_message">
            <input
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              type="password"
              name="password"
              placeholder="Enter Password"
            />
            {errors.password && touched.password && <p>{errors.password}</p>}
          </div>
          <div className="button_wrap">
            <button type="submit">Login</button>
            {success && <p style={{ color: '#283084' }}>{success}</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}

            {loading && <DotLoader color="#F1634C" size={30} />}
          </div>
        </form>
      </div>
    </section>
  );
};

export default Login;
