import { useEffect } from 'react';
import { Footer } from '../../components';
import { Banner, CardOne, CardTwo } from '../../components/common';

const AboutUs = () => {
  return (
    <section>
      <Banner title="About Us" />
      <div className="space_1"></div>
      <div className="aboutus_cosntent">
        <CardOne />
        <div className="space_1"></div>
        <CardTwo
          positionX="right"
          positionY="bottom"
          header="Mission"
          objective={mission}
          icon="achievement 1.png"
          image="Rectangle 140.png"
        />

        <div className="space_1"></div>
        <CardTwo
          color
          rowRev
          positionX="left"
          positionY="bottom"
          header="Vision"
          objective={vision}
          icon="visionary 1.png"
          image="Rectangle 141.png"
        />

        <div className="space_1"></div>
      </div>

      <Footer />
    </section>
  );
};

export default AboutUs;


const mission =
  [
    {
      "id": 1,
      "text": "Our mission is to empower individuals with innovative healthcare solutions, leveraging technology to provide accessible and personalized care."
    },
    {
      "id": 2,
      "text": "We are committed to delivering compassionate care that prioritizes the well-being and comfort of each individual we serve."
    },
    {
      "id": 3,
      "text": "Our aim is to create a seamless and comprehensive healthcare ecosystem that integrates various services, addressing diverse health needs under one roof."
    },
    {
      "id": 4,
      "text": "We strive to achieve global excellence in healthcare while maintaining a deep understanding of local contexts and cultural sensitivities."
    }
  ]

  const vision =[
    {
      "id": 1,
      "text": "Our vision is to pioneer innovative wellness solutions that redefine healthcare standards and empower individuals to lead healthier lives."
    },
    {
      "id": 2,
      "text": "We envision a transformed healthcare landscape where advanced technology, compassionate care, and patient-centric services converge for a more positive healthcare experience."
    },
    {
      "id": 3,
      "text": "Our aspiration is to make excellence in healthcare accessible to all, transcending barriers and ensuring that quality care reaches every corner of the world."
    },
    {
      "id": 4,
      "text": "We see a future where lives are empowered through informed healthcare decisions, enabled by our platform's tools, resources, and support."
    }
  ]
  
