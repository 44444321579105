import { Footer } from '../../components';
import '../../components/common/ContactForm/ContactForm.css';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { Banner, NewsletterSubscribe } from '../../components/common';
import { ContactInfoCard } from '../../components/common/ContactForm/ContactForm';
import { useEffect, useState } from 'react';

const ConnectUs = () => {
  const [masg, setMasg] = useState(false);
  const url = process.env.REACT_APP_BACKEND_PRIFILE_URL;

  const validateScheam = yup.object({
    name: yup
      .string()
      .required('Name are required')
      .min(3, 'Name minimum 3 charactor')
      .max(59, 'Name are tooo Long...!'),
    contactNo: yup
      .string()
      .required('ContactNo are required')
      .min(11, 'Please Enter valid Contact Number'),
    email: yup
      .string()
      .required('Email are required')
      .email('Please Enter valid email address'),
    message: yup
      .string()
      .required('Message are required')
      .min(6, 'Message minimum 6 charactor')
      .max(1499, 'Message are toooo Long..!'),
  });

  const {
    errors,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      name: '',
      contactNo: '',
      email: '',
      message: '',
      address: '',
    },
    onSubmit: async (value) => {
      try {
        const { data } = await axios.post(`${url}/contactus`, { ...value });
        setMasg(data.message);
        resetForm();
        setTimeout(() => {
          setMasg(false);
        }, 3000);
      } catch (error) {
        return;
      }
    },
    validationSchema: validateScheam,
  });

  return (
    <>
      <Banner title="Connect With Us" />
      <div className="space_1"></div>
      <section className="Contact">
        <div className="Contact_form">
          <form onSubmit={handleSubmit}>
            <label htmlFor="name">
              Name <span className="star">*</span>
            </label>
            <input
              onChange={handleChange}
              value={values.name}
              type="text"
              name="name"
              id="name"
              className={errors.name && touched.name && 'error_border'}
            />
            {errors.name && touched.name && (
              <p className="star">{errors.name}</p>
            )}
            <label htmlFor="contactNo">
              Contact <span className="star">*</span>
            </label>
            <input
              onChange={handleChange}
              value={values.contactNo}
              type="text"
              name="contactNo"
              id="contactNo"
              className={
                errors.contactNo && touched.contactNo && 'error_border'
              }
            />
            {errors.contactNo && touched.contactNo && (
              <p className="star">{errors.contactNo}</p>
            )}

            <label htmlFor="email">
              E-mail <span className="star">*</span>
            </label>
            <input
              onChange={handleChange}
              value={values.email}
              type="email"
              name="email"
              id="email"
              className={errors.email && touched.email && 'error_border'}
            />
            {errors.email && touched.email && (
              <p className="star">{errors.email}</p>
            )}

            <label htmlFor="message">
              Message <span className="star">*</span>
            </label>
            <textarea
              onChange={handleChange}
              value={values.message}
              type="text"
              name="message"
              id="message"
              className={errors.message && touched.message && 'error_border'}
            />
            {errors.message && touched.message && (
              <p className="star">{errors.message}</p>
            )}

            <label htmlFor="address">Address</label>
            <input
              onChange={handleChange}
              value={values.address}
              type="text"
              name="address"
              id="address"
            />
            {masg && <p className="messages_success">{masg}</p>}
            <button type="submit">Submit</button>
          </form>
        </div>
      </section>
      <div className="space_1"></div>
      <Footer />
    </>
  );
};

export default ConnectUs;
