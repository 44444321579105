import { useState } from 'react';
import './easeSteps.css';

const EaseSteps = () => {
  const [isActive, setIsActive] = useState('one');

  const active = (itis) => {
    return {
      borderBottom: `${isActive == itis ? '2px solid #2ABFC4' : 'none'}`,
    };
  };

  return (
    <div className="ease_steps">
      <h3>03 Easy Steps to Connect With Us.</h3>
      {/* <div className="ease_steps-container">
        <div className="ease_steps_container-left">
          <img src="/assets/Sign in 5.png" alt="App" />
        </div>
        <div className="ease_steps_container-right">
          <div className="ease_steps_container_right_wrap">
            <div className="steps_content_round">
              <h1>1</h1>
            </div>
            <div className="ease_steps_container_right_content">
              <h4>Download the App</h4>
              <p>
                The standard chunk of Lorem Ipsum used since the 1500s is
                reproduced, The standard chunk of Lorem Ipsum used since the
                1500s is reproduced
              </p>
            </div>
          </div>
          <div className="ease_steps_container_right_wrap">
            <div className="steps_content_round">
              <h1>2</h1>
            </div>
            <div className="ease_steps_container_right_content">
              <h4>Create Account</h4>
              <p>
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 year
              </p>
            </div>
          </div>
          <div className="ease_steps_container_right_wrap">
            <div className="steps_content_round">
              <h1>3</h1>
            </div>
            <div className="ease_steps_container_right_content">
              <h4>Get Your Service</h4>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <div className="ease_steps-container">
        <div className="steps_bar">
          <button style={active('one')} onClick={() => setIsActive('one')}>
            Step 1
          </button>
          <button style={active('two')} onClick={() => setIsActive('two')}>
            Step 2
          </button>
          <button style={active('three')} onClick={() => setIsActive('three')}>
            Step 3
          </button>
          <button style={active('four')} onClick={() => setIsActive('four')}>
            Step 4
          </button>
        </div>
        <div className="steps_content">
          <div
            className={`single_content ${
              isActive == 'one' ? 'single_content_anime' : 'null'
            }`}
          >
            <div className="img">
              <img src="/assets/NurseSplashScreen.png" alt="Nurse Apps" />
            </div>
            <div className="content">
              <h4>Step 1</h4>
              <h3>Lorem ipsum dolor sit.</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque,
                minus et fuga quasi, dolor ullam porro pariatur quo temporibus
                commodi nisi voluptate officia magnam similique, dolores illo
                iure doloremque reprehenderit.
              </p>
              <a href="#" target="_blank" rel="noopener noreferrer">
                Registratoin
              </a>
            </div>
          </div>
          <div
            className={`single_content ${
              isActive == 'two' ? 'single_content_anime' : 'null'
            }`}
          >
            <div className="img">
              <img src="/assets/NurseSplashScreen.png" alt="Nurse Apps" />
            </div>
            <div className="content">
              <h4>Step 2</h4>
              <h3>Lorem ipsum dolor sit.</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque,
                minus et fuga quasi, dolor ullam porro pariatur quo temporibus
                commodi nisi voluptate officia magnam similique, dolores illo
                iure doloremque reprehenderit.
              </p>
              <a href="#" target="_blank" rel="noopener noreferrer">
                Registratoin
              </a>
            </div>
          </div>
          <div
            className={`single_content ${
              isActive == 'three' ? 'single_content_anime' : 'null'
            }`}
          >
            <div className="img">
              <img src="/assets/NurseSplashScreen.png" alt="Nurse Apps" />
            </div>
            <div className="content">
              <h4>Step 3</h4>
              <h3>Lorem ipsum dolor sit.</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque,
                minus et fuga quasi, dolor ullam porro pariatur quo temporibus
                commodi nisi voluptate officia magnam similique, dolores illo
                iure doloremque reprehenderit.
              </p>
              <a href="#" target="_blank" rel="noopener noreferrer">
                Registratoin
              </a>
            </div>
          </div>
          <div
            className={`single_content ${
              isActive == 'four' ? 'single_content_anime' : 'null'
            }`}
          >
            <div className="img">
              <img src="/assets/NurseSplashScreen.png" alt="Nurse Apps" />
            </div>
            <div className="content">
              <h4>Step 4</h4>
              <h3>Lorem ipsum dolor sit.</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque,
                minus et fuga quasi, dolor ullam porro pariatur quo temporibus
                commodi nisi voluptate officia magnam similique, dolores illo
                iure doloremque reprehenderit.
              </p>
              <a href="#" target="_blank" rel="noopener noreferrer">
                Registratoin
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EaseSteps;
