import axios from 'axios';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { MdBusinessCenter } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import './NurseServices.css'

export const CategoryNurseService = () => {
  const [allNurseList, setAllNurseList] = useState([]);

  let token = Cookies.get('parasol_token');

  const getAllNurse = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/nurse_list`,
        config
      );
      setAllNurseList(response.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllNurse();
  }, []);

  return (
    <div>
      <div className="">
        <h3 className="text-[1.4rem] font-bold">Category</h3>
        <div className="mt-4 overflow-hidden custom-scrollbar-container">
          <div className=" max-w-fit w-full overflow-x-auto custom-overflow  flex items-end gap-4 pb-4">
            {new Array(5).fill(0).map(() => (
              <div className="border rounded-xl hover:border-cyan-400 hover:shadow-md transition-all cursor-pointer min-w-[220px] h-[220px] grid place-items-center max-h-full active:scale-95">
                <img
                  src="/assets/icons/hardIcon.svg"
                  className="w-[100px] h-[100px]"
                  alt=""
                />
                <p className="text-[1.1rem] font-semibold">Heart Disease</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-6">
        <h3 className="text-[1.4rem] font-bold">Our Nurses</h3>
        <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-4">
          {allNurseList?.length > 0 ? (
            allNurseList.map((item, i) => (
              <div
                className="border h-64 rounded-2xl shadow-sm max-w-xl w-full flex gap-4 py-4"
                key={item?.id}
              >
                <div className="ml-4 mt-4">
                  <img
                    src={
                      item.image
                        ? item?.image
                        : 'https://img.freepik.com/free-vector/faceless-portrait-nurse-isolated-vector-illustration_7504-679.jpg?w=826&t=st=1687620897~exp=1687621497~hmac=5c5a33ed002bde58c34946bce30348b3cbf8139744ba5a29cbd562a9ab09ba0f'
                    }
                    className="h-[120px] w-[120px] rounded-full border border-cyan-200"
                    style={{ boxShadow: '3px 3px 2px 2px #2ABFC4' }}
                    alt=""
                  />
                </div>
                <div className=" ">
                  <h3 className="text-[1.2rem] font-semibold mb-2">
                    {item?.name}
                  </h3>
                  <div className="mb-2">
                    <p className="font-medium">
                      BNMC No: {item?.bnmc_no ? item?.bnmc_no : '__'}
                    </p>
                    <p className="font-medium">
                      Experience :{' '}
                      {item?.year_of_experience
                        ? item?.year_of_experience
                        : '__'}{' '}
                      year
                    </p>
                  </div>
                  <div className="flex items-center gap-4 font-semibold">
                    <p className="flex items-center gap-2">
                      <MdBusinessCenter
                        color="#2ABFC4"
                        className="bg-[#2abfc426] w-10 h-10 p-2 rounded-full"
                      />{' '}
                      {item?.patient_served}
                    </p>
                    <p className="flex items-center gap-2">
                      <FaStar
                        color="#FF9529"
                        className="bg-[#ff952926] w-10 h-10 p-2 rounded-full"
                      />{' '}
                      {item?.rating}
                    </p>
                  </div>
                  <Link
                    to={`/user/nurse-profile/${item?.id}`}
                    className="bg-[#2ABFC4] grid place-items-center font-semibold text-[18px] capitalize mt-6 mx-auto hover:bg-[#00b8be] my-4 btn text-white w-full max-w-sm"
                  >
                    View Nurse Profile
                  </Link>
                </div>
              </div>
            ))
          ) : (
            <div className="text-[3rem] w-[350px] h-[350px] mt-10 text-center">
              <ScaleLoader className="scale-150" color="#2ABFC4" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
