import './PostCard.css';
import { BsChevronRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const PostCard = ({ title, img, content, link }) => {
  return (
    <div className="postCard_container">
      <div className="postCard_img">
        <img src={img} alt="Post image" />
      </div>
      <div className="postCard_content">
        <h4 className='min-h-[70px]'>{title}</h4>
        {/* <p>
          {content?.length > 130 ? (
            <>
              {content.substring(0, 127)} <strong>...</strong>
            </>
          ) : (
            content
          )}
        </p> */}
        <p className='min-h-[250px]'>
          {content}
        </p>
        <Link to={link}>
          Read more <BsChevronRight />
        </Link>
      </div>
    </div>
  );
};

export default PostCard;
