import './banner.css';
import Navbar from '../../Navbar/Navbar';

const Banner = ({ title }) => {
  return (
    <>
      <Navbar white />
      <div className="banner_content">
        <h1>{title}</h1>
        <h4>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry
        </h4>
      </div>
    </>
  );
};

export default Banner;
