import React from 'react'
import './TourismHospital.css'
import SectionHeading from '../TourismOffer/SectionHeading'
import SingleHospital from './SingleHospital'
import Image1 from './../../assets/hospital/Apollo__Bangalore_India.jpg'
import Image2 from './../../assets/hospital/MGM Healthcare__Chennai_India.jpg'
import Image3 from './../../assets/hospital/sims-hospital-jawaharlal-nehru-salai-chennai_117391.webp'
import Image4 from './../../assets/hospital/Fortis Hospital Anandapur, __Kolkata, India.jpg'
import Image5 from './../../assets/hospital/max-multi-speciality-centre-panchsheel-park__Delhi NCR, India.webp'
import Image6 from './../../assets/hospital/nova-fertility-_Gujrat.jpg'
import Image7 from './../../assets/hospital/rainbow-children-karnataka__Bangalore, India.jpg'
import Image8 from './../../assets/hospital/Nanavati__Mumbai, India.jpg'
const TourismHospital = () => {
  return (
    <div className='container-main best-hospital'>
      <div className="best-hospital-head">
        <SectionHeading title="Best Hospitals" span="For Your Needs" button="View More" text="JCI & NABH accredited hospitals with state-of-art facilities available at affordable prices along with the best medical staffs."></SectionHeading>
      </div>
      <div className="hospital-cards">
        <SingleHospital name="Apollo Hospital" address="Bangalore, India" hospitalImage={Image1}></SingleHospital>
        <SingleHospital name="MGM Healthcare Hospital" address="Chennai, India" hospitalImage={Image2}></SingleHospital>
        <SingleHospital name="SIMS Hospital" address="jawaharlal Nehru Salai, Chennai, India" hospitalImage={Image3}></SingleHospital>
        <SingleHospital name="Fortis Hospital" address="Fortis Hospital Anandapur, Kolkata, India" hospitalImage={Image4}></SingleHospital>
        <SingleHospital name="Max-multi Speciality Centre" address="Delhi NCR, India" hospitalImage={Image5}></SingleHospital>
        <SingleHospital name="NOVA Fertility " address="Gujrat, India" hospitalImage={Image6}></SingleHospital>
        <SingleHospital name="Rainbow Children Hospital" address="Marathahalli Karnataka Bangalore, India" hospitalImage={Image7}></SingleHospital>
        <SingleHospital name="Nanavati Hospital" address="Mumbai, India" hospitalImage={Image8}></SingleHospital>
      </div>
    </div>
  )
}

export default TourismHospital