import React from 'react';
import { ImCircleRight } from 'react-icons/im';

export default function Nursecard({ title, iconName, onClick }) {
  return (
    <div
      onClick={() => onClick(true)}
      className="hover:border-b-4 transition-all hover:border-[#ff6767] card w-full min-h-[70px] bg-base-100 shadow-lg flex b  flex-row p-3  items-center gap-2"
    >
      <img
        src={`/assets/${iconName}`}
        alt=""
        className="w-[70px] rounded-lg h-[70px] self-center overflow-hidden shadow-sm p-1 border"
      />

      <h3 className="text-[1.3rem] font-medium">{title}</h3>
      <div className="badge badge-error badge-lg ml-auto mb-auto"></div>
    </div>
  );
}

export function ActiveCard({ iconName, title, count, color }) {
  return (
    <a href="#">
      <div
        className="flex bg-white justify-between items-center p-2 border border-gray-200 rounded-xl shadow-md hover:shadow-inner transition-all mainListCard mb-2"
        style={{ backgroundColor: color ? color : '#ffffff' }}
      >
        <div className="flex justify-start items-center gap-3">
          <img
            src={iconName}
            className="w-[70px] h-[70px] shadow-md rounded-full overflow-hidden p-2"
            alt=""
          />
          <div className="">
            <h4 className="text-[16px] text-white font-medium">{title}</h4>

            <p className="text-[14px] ">
              Active on{' '}
              <span className="text-[16px] font-semibold">({count})</span>{' '}
            </p>
          </div>
        </div>
        <ImCircleRight size={40} className="transition-all innderHover" />
      </div>
    </a>
  );
}
