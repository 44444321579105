import { useState } from "react";
import { Link } from "react-router-dom"

export const Menubar = ({ listIcon,
  listOfMenu, listogActive }) => {

  const [activeMenu, setActiveMenu] = useState(null);

  const toggleActiveMenu = (index) => {
    if (activeMenu === index) {
      setActiveMenu(null);
    } else {
      setActiveMenu(index);
    }
  };

  return (
    <div className="mt-6">
      {listOfMenu.map((item, index) => (
  <p key={index}>
    {childLinks[item] ? (
      <span
        className={`text-white font-semibold flex gap-2 items-center text-xl py-2 hover:bg-black pl-3 rounded-r-full ${activeMenu === index ? 'bg-black' : ''}`}
        onClick={() => toggleActiveMenu(index)}
      >
        {listIcon[index]}
        {item}
      </span>
    ) : (
      <Link
        to={`/${listogActive[index]}`}
        className={`text-white font-semibold flex gap-2 items-center text-xl py-2 hover:bg-black pl-3 rounded-r-full ${activeMenu === index ? 'bg-black' : ''}`}
        onClick={() => toggleActiveMenu(index)}
      >
        {listIcon[index]}
        {item}
      </Link>
    )}
    {activeMenu === index && childLinks[item] && (
      <div className="pl-4 text-white font-semibold">
        {/* Render child links */}
        {childLinks[item].map((childItem, childIndex) => (
          <Link
            key={childIndex}
            to={`/${listogActive[index]}/${childItem.toLowerCase().replace(/\s/g, '-')}`} // Assuming childItem is used for the route segment
            className="text-gray-600 block py-1"
          >
            {childItem}
          </Link>
        ))}
      </div>
    )}
  </p>
))}

    </div>
  )
}

const childLinks = {
  'Nurse Services': ['Active Nurse List', 'Pending Nurse List', 'Rejected Nurse List'],
  'Medical Tourism': ['Active Nurse List', 'Pending Nurse List', 'Rejected Nurse List'],
  // Add more child links for other menu items if needed
};
