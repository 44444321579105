import './cardOne.css';

const CardOne = () => {
  return (
    <section className="cardOne md:w-[80%]">
      <div className="cardOne_img">
        <img src="/assets/Rectangle 139.png" alt="photo" />
      </div>
      <div className="cardOne_content">
        <div className="cardOne_content_title">
          <h4>Know Us</h4>
          <h2>Who We Are</h2>
        </div>
        <p>
        At Parasol Health Ltd, we are a team of healthcare enthusiasts, technology visionaries, and dedicated professionals. Our collective expertise and passion form the backbone of our platform, ensuring that every aspect of the user experience is designed with care and precision.
        </p>

        <div className="cardOne_content_parragraph">
          <h4>What We Do</h4>
          <p>
          We offer a wide range of innovative healthcare solutions all under one digital roof. Our platform facilitates virtual consultations with qualified medical professionals, enables seamless management of health records and appointments, and provides access to educational resources that empower individuals to make informed decisions about their health
          </p>
        </div>
        <div className="cardOne_content_parragraph">
          <h4>Join Us on the Path to Better Health</h4>
          <p>
          Parasol Health Ltd invites you to embark on a transformative journey towards better health and well-being. Whether you're seeking convenient healthcare solutions, looking to manage your health records more effectively, or aiming to stay informed about the latest health trends, our platform is here to support you every step of the way.
          </p>
        </div>
      </div>
    </section>
  );
};

export default CardOne;
