import axios from 'axios';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { FiLock } from 'react-icons/fi';
import { IoCallOutline } from 'react-icons/io5';
import { useNavigate, Navigate } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import { toast } from 'react-toastify';

export default function NurseUserLogin() {
  const [userInfo, setUserInfo] = useState({
    phone: '',
    password: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  let token = Cookies.get('parasol_token');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleLogin = async () => {
    if (userInfo.phone && userInfo.password) {
      setIsLoading(true);
      try {
        let response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/login`,
          {
            ...userInfo,
          }
        );
        //token-----
        Cookies.set('parasol_token', response?.data?.access_token);
        Cookies.set('parasol_refrash_token', response?.data?.refresh_token);
        toast.success(
          `${response?.data?.user?.name} ${response?.data?.message}`
        );
        setUserInfo({
          phone: '',
          password: '',
        });
        setTimeout(() => navigate('/user/dashboard'), 1000);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error(error?.response?.data?.errors?.phone[0]);
      }
    } else {
      toast.error(' All field are required..!');
    }
  };

  return token ? (
    <Navigate to="/user/dashboard" />
  ) : (
    <div className="w-screen h-screen grid place-items-center">
      <div className="p-6 h-fit min-w-[450px] max-w-full shadow-md">
        <div className="h-[163px] w-[217px] mx-auto">
          <img
            src="assets/Parasol-Health.svg"
            alt=""
            className="w-full h-full"
          />
        </div>
        <div className="">
          <IconInput
            handleChange={handleChange}
            name="phone"
            labelName="Phone Number"
            htmlFor="pnoneNo"
            icon={<IoCallOutline size={28} />}
          />
          <br />
          <IconInput
            handleChange={handleChange}
            name="password"
            labelName="Password"
            htmlFor="password"
            type="password"
            icon={<FiLock size={28} />}
          />
          <a className="text-[#2ABFC4] underline text-end py-2 block cursor-pointer">
            Forgot Password?
          </a>

          <div className="flex flex-col items-center">
            <button
              disabled={isLoading}
              onClick={handleLogin}
              className="bg-[#2ABFC4] capitalize mx-auto hover:bg-[#00b8be] my-4 btn text-white w-full max-w-sm"
            >
              SignIn
            </button>
            {isLoading && <BounceLoader color="#F1634C" />}
          </div>
        </div>
      </div>
    </div>
  );
}

const IconInput = ({ icon, labelName, htmlFor, type, name, handleChange }) => {
  return (
    <>
      <label htmlFor={htmlFor} className="my-2">
        {labelName}
      </label>
      <label className="flex border border-gray-300 px-2 rounded-md items-center focus:border-[#2ABFC4] active:border-[#2ABFC4] hover:border-[#2ABFC4]">
        {icon}
        <input
          onChange={handleChange}
          name={name}
          id={htmlFor}
          type={type ? type : 'text'}
          className="h-10 outline-none border-0 w-full pl-2"
        />
      </label>
    </>
  );
};
