import React from 'react';
import { Link } from 'react-router-dom';

export default function SiteDrawer({
  isMenuActive,
  setIsMenuActive,
  listOfMenu = [],
  listogActive = [],
  listOfIcon = [],
}) {
  return (
    <div className="w-full h-full bg-white shadow-lg rounded-2xl">
      <ul className="menu w-56 rounded-box text-[17px] font-medium -ml-2 bg-transparent btn-inner-hover">
        <h2 className="menu-title">Menu</h2>
        {listOfMenu.length > 0 ? (
          listOfMenu.map((item, index) => (
            <li>
              <Link 
              to={`${listogActive[index]}`}
                className={
                  isMenuActive == listogActive[index]
                    ? 'btn-linearGradient py-3'
                    : 'py-3'
                }
                onClick={() => setIsMenuActive('dashboard')}
              >
                {listOfIcon[index]}
                {item}
              </Link>
            </li>
          ))
        ) : (
          <li>Home</li>
        )}
      </ul>
    </div>
  );
}
