import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './Subscribe.css';

const Subscribe = ({ pageTitle }) => {
  const [allSubscriber, setAllSubscriber] = useState([]);
  const url = process.env.REACT_APP_BACKEND_PRIFILE_URL;

  const token = useSelector((info) => {
    return info.user.info.token;
  });

  const getAllSubscriber = async () => {
    try {
      const { data } = await axios.get(`${url}/getAll-Subscribeing`, {
        headers: { authorization: 'Bearer ' + token },
      });
      setAllSubscriber(data.subscriber);
    } catch (error) {
      return;
    }
  };
  const deleteSubscriber = async (id) => {
    try {
      const { data } = await axios.delete(`${url}/delete-Subscribeing/${id}`, {
        headers: { authorization: 'Bearer ' + token },
      });
      getAllSubscriber();
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    getAllSubscriber();
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    return () => {
      unlisten();
    };
  }, []);

  return (
    <>
      <h3>{pageTitle}</h3>
      <div className="user_subscriber_container">
        <div className="user_subscriber_wrap">
          <div className="user_subscriber_header">
            <h3>Email</h3>
            <h3>Actoin</h3>
          </div>
          {allSubscriber ? (
            allSubscriber.map((subscriber) => {
              return (
                <div className="user_subscriber_content">
                  <p>
                    <a href={`mailto:${subscriber.email}`}>
                      {subscriber.email}
                    </a>
                  </p>
                  <p>
                    <button onClick={() => deleteSubscriber(subscriber._id)}>
                      Delete
                    </button>
                  </p>
                </div>
              );
            })
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </>
  );
};

export default Subscribe;
