import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Footer, Navbar } from '../../components';
import './Registration.css';
import DotLoader from 'react-spinners/DotLoader';

import { Formik, Form, ErrorMessage, useField } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const Registration = () => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const [regsiterData, setRegisterData] = useState({
    fullName: '',
    fatherName: '',
    motherName: '',
    spouseName: '',
    bYear: new Date().getFullYear(),
    bMonth: new Date().getMonth() + 1,
    bDay: new Date().getDate(),
    gender: '',
    bloodGroup: '',
    birthCertificateNo: '',
    nidNo: '',
    passportNo: '',
    maillingAddress: '',
    occupation: '',
    permanentAddress: '',
    mobileNo: '',
    email: '',
    diseases: '',
    fPersonsNameOne: '',
    fPersonsMobileNoOne: '',
    fPersonsNameTwo: '',
    familyPersonsMobileNo: '',
  });
  const [profilePic, setProfilePic] = useState('');

  const url = process.env.REACT_APP_BACKEND_PRIFILE_URL;

  const {
    fullName,
    fatherName,
    motherName,
    spouseName,
    bYear,
    bMonth,
    bDay,
    gender,
    bloodGroup,
    birthCertificateNo,
    nidNo,
    passportNo,
    maillingAddress,
    occupation,
    permanentAddress,
    mobileNo,
    email,
    diseases,
    fPersonsNameOne,
    fPersonsMobileNoOne,
    fPersonsNameTwo,
    familyPersonsMobileNo,
    picture,
  } = regsiterData;

  const registerhandleChange = (e) => {
    const { name, value } = e.target;
    setRegisterData({ ...regsiterData, [name]: value });
  };

  const navigate = useNavigate();
  // Birth Date
  const tempDate = new Date().getFullYear();
  const years = Array.from(new Array(100), (val, i) => tempDate - i - 1);
  const months = Array.from(new Array(12), (val, i) => 1 + i);
  const getDays = () => {
    return new Date(bYear, bMonth, 0).getDate();
  };
  const days = Array.from(new Array(getDays()), (val, i) => 1 + i);

  const currentDate = new Date();
  let selectDate = new Date(bYear, bMonth, bDay);

  let showDMY = {
    D: 0,
    M: 0,
    Y: 0,
  };

  const getDate = () => {
    let date = new Date();
    let cD = date.getDate();
    let cM = date.getMonth();
    let cY = date.getFullYear();

    if (cM < bMonth && cD >= bDay) {
      let myYear = cY - 1 - bYear;
      let myMonth = cM + 13 - bMonth;
      let myDays = cD - bDay;

      showDMY.D = myDays;
      showDMY.M = myMonth;
      showDMY.Y = myYear;
      return;
    } else if (cM < bMonth && cD < bDay) {
      let myYear = cY - 1 - bYear;
      let myMonth = cM + 12 - bMonth;
      let myDays = cD + 31 - bDay;

      showDMY.D = myDays;
      showDMY.M = myMonth;
      showDMY.Y = myYear;
      return;
    } else if (cM >= bMonth && cD >= bDay) {
      let myYear = cY - bYear;
      let myMonth = cM + 1 - bMonth;
      let myDays = cD - bDay;

      showDMY.D = myDays;
      showDMY.M = myMonth;
      showDMY.Y = myYear;
      return;
    }
  };

  getDate();

  // form Validation
  const validationReg = Yup.object({
    fullName: Yup.string()
      .required('Full Name is required')
      .min(3, 'Full Nmae Length Must be between 3 to 30 character')
      .max(30, 'Full Nmae Length Must be between 3 to 30 character')
      .matches(/^[aA-zZ\s]+$/, 'Number and spacial Character are not allowed'),
    fPersonsNameOne: Yup.string()
      .required('Person Name is required')
      .min(3, 'Person Name Must be between 3 to 30 character')
      .max(30, 'Person Name Must be between 3 to 30 character')
      .matches(/^[aA-zZ\s]+$/, 'Number and spacial Character are not allowed'),
    fPersonsMobileNoOne: Yup.string()
      .required('Persons One Mobile Number are required')
      .min(11, 'Invalid Mobile Number')
      .max(14, 'Invalid Mobile Number'),
    mobileNo: Yup.string()
      .required('Mobile Number are required')
      .min(11, 'Invalid Mobile Number')
      .max(14, 'Invalid Mobile Number'),
    email: Yup.string()
      .required('Email are required')
      .matches(
        /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,12})(\.[a-z]{2,12})?$/,
        'Please enter a valid email'
      ),
  });

  return (
    <section className="registration">
      <Navbar white menuBlack bgWhite />
      <div className="registration_container">
        <div className="registration-header">
          <h2>Registration Form</h2>
          <h5>If you are a interested, please fill up this Form.</h5>
        </div>
        <div className="registration_line" />
        <div className="registration_form_wrap">
          <Formik
            enableReinitialize
            initialValues={regsiterData}
            onSubmit={async () => {
              setLoading(true);

              try {
                if (profilePic) {
                  // imageCode
                  let image = new FormData();
                  image.append('profilePic', profilePic);
                  const imageRes = await axios.post(
                    `${url}/image-upload`,
                    image
                  );

                  const { data } = await axios.post(
                    `${url}/fontend-user-register`,
                    {
                      fullName,
                      fatherName,
                      motherName,
                      spouseName,
                      bYear,
                      bMonth,
                      bDay,
                      gender,
                      bloodGroup,
                      birthCertificateNo,
                      nidNo,
                      passportNo,
                      maillingAddress,
                      occupation,
                      permanentAddress,
                      mobileNo,
                      email,
                      diseases,
                      fPersonsNameOne,
                      fPersonsMobileNoOne,
                      fPersonsNameTwo,
                      familyPersonsMobileNo,
                      picture: imageRes.data.uploadUrl,
                    }
                  );
                  setError('');
                  setLoading(false);
                  setSuccess(data.message);
                } else {
                  const { data } = await axios.post(
                    `${url}/fontend-user-register`,
                    {
                      fullName,
                      fatherName,
                      motherName,
                      spouseName,
                      bYear,
                      bMonth,
                      bDay,
                      gender,
                      bloodGroup,
                      birthCertificateNo,
                      nidNo,
                      passportNo,
                      maillingAddress,
                      occupation,
                      permanentAddress,
                      mobileNo,
                      email,
                      diseases,
                      fPersonsNameOne,
                      fPersonsMobileNoOne,
                      fPersonsNameTwo,
                      familyPersonsMobileNo,
                      picture,
                    }
                  );
                  setError('');
                  setLoading(false);
                  setSuccess(data.message);
                }

                setTimeout(() => {
                  navigate('/submitdone');
                }, 2000);
              } catch (error) {
                setLoading(false);
                setSuccess('');
                setError(error.response.data.message);
              }
            }}
            validationSchema={validationReg}
          >
            {() => {
              return (
                <Form>
                  <div className="form_grid-one">
                    <RegistrationInput
                      onChange={registerhandleChange}
                      name="fullName"
                      label="Full Name :"
                      require={true}
                    />
                    <RegistrationInput
                      onChange={registerhandleChange}
                      name="fatherName"
                      label="Father’s Name :"
                    />
                    <RegistrationInput
                      onChange={registerhandleChange}
                      name="motherName"
                      label="Mother’s Name : "
                    />
                    <RegistrationInput
                      onChange={registerhandleChange}
                      name="spouseName"
                      label="Spouse Name : "
                    />
                  </div>
                  <div className="form_grid-two">
                    <div className="registration_form-dateAge">
                      <label htmlFor="">
                        Date of Birth : <span className="star">*</span>{' '}
                      </label>
                      <div className="registration_form_dateAge-wrap">
                        <div className="registration_form_dateAge_wrap-left">
                          <select
                            name="bDay"
                            value={bDay}
                            onChange={registerhandleChange}
                          >
                            <option
                              value={currentDate.getDate()}
                              selected
                              disabled
                            >
                              Days*
                            </option>
                            {days.map((day, i) => (
                              <option value={day} key={i}>
                                {day}
                              </option>
                            ))}
                          </select>

                          <select
                            name="bMonth"
                            value={bMonth}
                            onChange={registerhandleChange}
                          >
                            <option
                              value={currentDate.getMonth() + 1}
                              selected
                              disabled
                            >
                              Month*
                            </option>
                            {months.map((month, i) => (
                              <option value={month} key={i}>
                                {month}
                              </option>
                            ))}
                          </select>
                          <select
                            name="bYear"
                            value={bYear}
                            onChange={registerhandleChange}
                          >
                            <option
                              value={currentDate.getFullYear()}
                              disabled
                              selected
                            >
                              Year*
                            </option>
                            {years.map((year, i) => (
                              <option value={year} key={i}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>
                        <p>
                          {showDMY.M == 12 ? showDMY.Y + 1 : showDMY.Y} years{' '}
                          {showDMY.M == 12 ? 0 : showDMY.M} months {showDMY.D}{' '}
                          days
                        </p>
                      </div>
                    </div>

                    <div className="registration_form-gender">
                      <label htmlFor="">
                        Gender : <span className="star">*</span>
                      </label>
                      <div className="registration_form_gender-wrap">
                        <span>
                          <input
                            type="radio"
                            name="gender"
                            value="male"
                            id="male"
                            onChange={registerhandleChange}
                          />
                          <label htmlFor="male">
                            <span />
                            Male
                          </label>
                        </span>
                        <span>
                          <input
                            type="radio"
                            name="gender"
                            value="female"
                            id="female"
                            onChange={registerhandleChange}
                          />
                          <label htmlFor="female">
                            <span />
                            Female
                          </label>
                        </span>
                        <span>
                          <input
                            type="radio"
                            name="gender"
                            value="others"
                            id="others"
                            onChange={registerhandleChange}
                          />
                          <label htmlFor="others">
                            <span />
                            Others
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="registration_form-bloodGroup">
                    <label htmlFor="">
                      Blood Group : <span className="star">*</span>
                    </label>
                    <div className="registration_form_bloodGroup-wrap">
                      <span>
                        <input
                          type="radio"
                          name="bloodGroup"
                          value="a+"
                          id="a+"
                          onChange={registerhandleChange}
                        />
                        <label htmlFor="a+">
                          <span />
                          {'A(+)'}
                        </label>
                      </span>
                      <span>
                        <input
                          type="radio"
                          name="bloodGroup"
                          value="a-"
                          id="a-"
                          onChange={registerhandleChange}
                        />
                        <label htmlFor="a-">
                          <span />
                          {'A(-)'}
                        </label>
                      </span>
                      <span>
                        <input
                          type="radio"
                          name="bloodGroup"
                          value="b+"
                          id="b+"
                          onChange={registerhandleChange}
                        />
                        <label htmlFor="b+">
                          <span />
                          {'B(+)'}
                        </label>
                      </span>
                      <span>
                        <input
                          type="radio"
                          name="bloodGroup"
                          value="b-"
                          id="b-"
                          onChange={registerhandleChange}
                        />
                        <label htmlFor="b-">
                          <span />
                          {'B(-)'}
                        </label>
                      </span>
                      <span>
                        <input
                          type="radio"
                          name="bloodGroup"
                          value="o+"
                          id="o+"
                          onChange={registerhandleChange}
                        />
                        <label htmlFor="o+">
                          <span />
                          {'O(+)'}
                        </label>
                      </span>
                      <span>
                        <input
                          type="radio"
                          name="bloodGroup"
                          value="o-"
                          id="o-"
                          onChange={registerhandleChange}
                        />
                        <label htmlFor="o-">
                          <span />
                          {'O(-)'}
                        </label>
                      </span>
                      <span>
                        <input
                          type="radio"
                          name="bloodGroup"
                          value="ab+"
                          id="ab+"
                          onChange={registerhandleChange}
                        />
                        <label htmlFor="ab+">
                          <span />
                          {'AB(+)'}
                        </label>
                      </span>
                      <span>
                        <input
                          type="radio"
                          name="bloodGroup"
                          value="ab-"
                          id="ab-"
                          onChange={registerhandleChange}
                        />
                        <label htmlFor="ab-">
                          <span />
                          {'AB(-)'}
                        </label>
                      </span>
                    </div>
                  </div>
                  <div className="reg_divider" />
                  <div className="registration_form-col">
                    <RegistrationInput
                      onChange={registerhandleChange}
                      name="nidNo"
                      label="NID No. :"
                    />
                    <RegistrationInput
                      onChange={registerhandleChange}
                      name="passportNo"
                      label="Passport No. : "
                    />
                  </div>
                  <div className="registration_form-col">
                    <RegistrationInput
                      onChange={registerhandleChange}
                      name="birthCertificateNo"
                      label="Birth Certificate No. :"
                    />

                    <RegistrationInput
                      onChange={registerhandleChange}
                      name="maillingAddress"
                      label="Mailling Address :"
                    />
                  </div>
                  <div className="registration_form-col">
                    <RegistrationInput
                      onChange={registerhandleChange}
                      name="permanentAddress"
                      label="Permanent Address :"
                    />
                    <RegistrationInput
                      onChange={registerhandleChange}
                      name="occupation"
                      label="Occupation (In Details) :"
                    />
                  </div>
                  <div className="registration_form-col">
                    <RegistrationInput
                      onChange={registerhandleChange}
                      name="mobileNo"
                      label="Mobile No. :"
                      require
                    />
                    <RegistrationInput
                      onChange={registerhandleChange}
                      name="email"
                      label="E-mail :"
                      require
                    />
                  </div>
                  <div className="registration_form-grid">
                    <RegistrationInput
                      onChange={registerhandleChange}
                      name="diseases"
                      label="Types of Diseases :"
                    />
                    <div className="registration_form-image">
                      <h5>Upload Image</h5>

                      <span>
                        <span>
                          <label htmlFor="image">
                            {profilePic && profilePic.name}
                          </label>
                        </span>

                        <label htmlFor="image">Browse</label>
                        <input
                          type="file"
                          id="image"
                          name="profilePic"
                          onChange={(e) => setProfilePic(e.target.files[0])}
                        />
                      </span>
                    </div>
                  </div>
                  <p className="relative-title">
                    {
                      '( The Name & Mobile No. of Two Familiar Persons of Patients )'
                    }
                  </p>
                  <div className="reg_divider" />
                  <div className="registration_form-col">
                    <RegistrationInput
                      onChange={registerhandleChange}
                      name="fPersonsNameOne"
                      label="Full Name :"
                      require
                    />
                    <RegistrationInput
                      onChange={registerhandleChange}
                      name="fPersonsMobileNoOne"
                      label="Mobile No. :"
                      require
                    />
                  </div>
                  <div className="registration_form-col">
                    <RegistrationInput
                      onChange={registerhandleChange}
                      name="fPersonsNameTwo"
                      label="2. Full Name :"
                    />
                    <RegistrationInput
                      onChange={registerhandleChange}
                      name="familyPersonsMobileNo"
                      label="2. Mobile No. :"
                    />
                  </div>
                  <br />
                  <div className="registration_form-terms">
                    <input type="checkbox" name="" id="" required />
                    <p>
                      I accept all the{' '}
                      <a href="" target="_blank">
                        terms & conditions
                      </a>{' '}
                      of Parasol
                    </p>
                  </div>
                  <div className="registration_form-button">
                    <button type="button">Cancel</button>
                    <button type="submit" disabled={loading}>
                      Submit
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>

          <div className="register_message">
            {error && <p>{error}</p>}
            {success && <p>{success}</p>}
            {loading && (
              <p>
                <DotLoader color="#F1634C" size={30} />
              </p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

const RegistrationInput = ({ label, require, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="RegistrationInput_wrap">
      <label htmlFor={field.name}>
        {label} {require && <span className="star">*</span>}
      </label>
      <input
        type="text"
        name={field.name}
        id={field.name}
        {...field}
        {...props}
      />
      {meta.error && meta.touched && (
        <p style={{ color: 'red' }}>
          <ErrorMessage name={field.name} />
        </p>
      )}
    </div>
  );
};

export default Registration;
