import React, { useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import './nurseDashboard.css';
import NurseHeader from '../../../components/nurse-dashboard/nurse_header';
import Nursecard, {
  ActiveCard,
} from '../../../components/nurse-dashboard/nurse_card';
import UserInfo from '../../../components/nurse-dashboard/User_Info';
import SiteDrawer from '../../../components/nurse-dashboard/SiteDrawer';
import { TiArrowLeftThick } from 'react-icons/ti';
import { BsFillPencilFill } from 'react-icons/bs';
import { MdOutlineTaskAlt, MdOutlineWatchLater } from 'react-icons/md';
import { TbUsers } from 'react-icons/tb';
import { BiChevronRight } from 'react-icons/bi';
import { AiTwotoneSetting, AiFillHome } from 'react-icons/ai';
import { BiQrScan } from 'react-icons/bi';
import { FaUser } from 'react-icons/fa';
import { RiHistoryLine } from 'react-icons/ri';
import { FcCalendar, FcCurrencyExchange } from 'react-icons/fc';
import { GrScheduleNew } from 'react-icons/gr';
import { GiWallet } from 'react-icons/gi';
import { SiGoogletranslate } from 'react-icons/si';

let listOfMenu = [
  'Dashboard',
  'Profile',
  'Schedule',
  'Appointment',
  'Wallet',
  'Transactions',
  'History',
  'Setting',
  'QR scan',
  'Language',
];

let listogActive = [
  'dashboard',
  'profile',
  'schedule',
  'appointment',
  'wallet',
  'transactions',
  'history',
  'setting',
  'qrscan',
  'language',
];

let listOfIcon = [
  <AiFillHome />,
  <FaUser />,
  <GrScheduleNew />,
  <FcCalendar />,
  <GiWallet />,
  <FcCurrencyExchange />,
  <RiHistoryLine />,
  <AiTwotoneSetting />,
  <BiQrScan />,
  <SiGoogletranslate />,
];

export default function NurseDashboard() {
  const [isMenuActive, setIsMenuActive] = useState('dashboard');
  return (
    <section className="nurseDashboard">
      <div className="nurseDashboard_grid_top">
        <NurseHeader />
      </div>
      <div className="nurseDashboard_wrap">
        <div className="nurseDashboard_grid_left">
          <SiteDrawer
            isMenuActive={isMenuActive}
            setIsMenuActive={setIsMenuActive}
            listOfMenu={listOfMenu}
            listogActive={listogActive}
            listOfIcon={listOfIcon}
          />
        </div>
        <div className="nurseDashboard_grid_right">
          <UserInfo />
        </div>
        <div className="nurseDashboard_grid_body">
          {isMenuActive == 'dashboard' ? (
            <DashboardHome />
          ) : isMenuActive == 'profile' ? (
            <Profile />
          ) : isMenuActive == 'schedule' ? (
            <Schedule />
          ) : isMenuActive == 'appointment' ? (
            <Appointment />
          ) : isMenuActive == 'wallet' ? (
            <Wallet />
          ) : isMenuActive == 'transactions' ? (
            <Transactions />
          ) : isMenuActive == 'history' ? (
            <History />
          ) : isMenuActive == 'setting' ? (
            <Setting />
          ) : isMenuActive == 'qrscan' ? (
            <QRScan />
          ) : isMenuActive == 'language' ? (
            <Language />
          ) : (
            <DashboardHome />
          )}
        </div>
      </div>
    </section>
  );
}

const Profile = () => {
  const [isActive, setIsActive] = useState('All');

  return (
    <div className="w-full min-h-[85vh] rounded-xl p-4">
      {isActive == 'All' ? (
        <div className="flex flex-col gap-3">
          <button
            className="btn h-14 shadow-md bg-white w-full text-left px-4 capitalize flex items-center justify-between rounded-xl hover:bg-[#2ABFC4] hover:text-white"
            onClick={() => setIsActive('personalinfo')}
          >
            <span className="text-[18px] font-medium">Personal Info</span>
            <FaChevronRight size={20} />
          </button>
          <button
            className="btn h-14 shadow-md bg-white w-full text-left px-4 capitalize flex items-center justify-between rounded-xl hover:bg-[#2ABFC4] hover:text-white"
            onClick={() => setIsActive('academicinfo')}
          >
            <span className="text-[18px] font-medium">Academic Info</span>
            <FaChevronRight size={20} />
          </button>
          <button
            className="btn h-14 shadow-md bg-white w-full text-left px-4 capitalize flex items-center justify-between rounded-xl hover:bg-[#2ABFC4] hover:text-white"
            onClick={() => setIsActive('experienceinfo')}
          >
            <span className="text-[18px] font-medium">Experience Info</span>
            <FaChevronRight size={20} />
          </button>
          <button
            className="btn h-14 shadow-md bg-white w-full text-left px-4 capitalize flex items-center justify-between rounded-xl hover:bg-[#2ABFC4] hover:text-white"
            onClick={() => setIsActive('scheduleinfo')}
          >
            <span className="text-[18px] font-medium">Schedule Info</span>
            <FaChevronRight size={20} />
          </button>
          <button
            className="btn h-14 shadow-md bg-white w-full text-left px-4 capitalize flex items-center justify-between rounded-xl hover:bg-[#2ABFC4] hover:text-white"
            onClick={() => setIsActive('serviceinfo')}
          >
            <span className="text-[18px] font-medium">Service Info</span>
            <FaChevronRight size={20} />
          </button>
        </div>
      ) : isActive == 'personalinfo' ? (
        <PersonalInfo setIsActive={setIsActive} />
      ) : isActive == 'academicinfo' ? (
        <AcademicInfo setIsActive={setIsActive} />
      ) : isActive == 'experienceinfo' ? (
        <ExperienceInfo setIsActive={setIsActive} />
      ) : isActive == 'scheduleinfo' ? (
        <ScheduleInfo setIsActive={setIsActive} />
      ) : isActive == 'serviceinfo' ? (
        <ServiceInfo setIsActive={setIsActive} />
      ) : (
        <div className="flex flex-col gap-3">
          <button
            className="btn h-14 shadow-md bg-white w-full text-left px-4 capitalize flex items-center justify-between rounded-xl hover:bg-[#2ABFC4] hover:text-white"
            onClick={() => setIsActive('personalinfo')}
          >
            <span className="text-[18px] font-medium">Personal Info</span>
            <FaChevronRight size={20} />
          </button>
          <button
            className="btn h-14 shadow-md bg-white w-full text-left px-4 capitalize flex items-center justify-between rounded-xl hover:bg-[#2ABFC4] hover:text-white"
            onClick={() => setIsActive('academicinfo')}
          >
            <span className="text-[18px] font-medium">Academic Info</span>
            <FaChevronRight size={20} />
          </button>
          <button
            className="btn h-14 shadow-md bg-white w-full text-left px-4 capitalize flex items-center justify-between rounded-xl hover:bg-[#2ABFC4] hover:text-white"
            onClick={() => setIsActive('experienceinfo')}
          >
            <span className="text-[18px] font-medium">Experience Info</span>
            <FaChevronRight size={20} />
          </button>
          <button
            className="btn h-14 shadow-md bg-white w-full text-left px-4 capitalize flex items-center justify-between rounded-xl hover:bg-[#2ABFC4] hover:text-white"
            onClick={() => setIsActive('scheduleinfo')}
          >
            <span className="text-[18px] font-medium">Schedule Info</span>
            <FaChevronRight size={20} />
          </button>
          <button
            className="btn h-14 shadow-md bg-white w-full text-left px-4 capitalize flex items-center justify-between rounded-xl hover:bg-[#2ABFC4] hover:text-white"
            onClick={() => setIsActive('serviceinfo')}
          >
            <span className="text-[18px] font-medium">Service Info</span>
            <FaChevronRight size={20} />
          </button>
        </div>
      )}
    </div>
  );
};

const TitleWithBtn = ({ title, setIsActive, isAdd = false, btnFunc }) => (
  <div className="flex items-center gap-4">
    <button
      className="btn btn-circle shadow-xl bg-white hover:bg-slate-50 hover:text-[#F1634C] transition-all"
      onClick={() => setIsActive('All')}
    >
      <TiArrowLeftThick size={25} />
    </button>
    <h3 className="text-[22px] font-semibold border-b border-gray-400">
      {title}
    </h3>
    {isAdd && (
      <button
        className="bg-[#f1624c9c] hover:bg-[#f1624c] transition-all outline-0 active:scale-110 px-6 text-[20px] py-1 font-medium rounded-lg ml-auto shadow-lg hover:text-white"
        onClick={() => btnFunc(true)}
      >
        Add More +
      </button>
    )}
  </div>
);

const PersonalInfo = ({ setIsActive }) => (
  <div>
    <TitleWithBtn title="Personal Info" setIsActive={setIsActive} />
  </div>
);
//================================================================
const AcademicInfo = ({ setIsActive }) => {
  const [isEdit, setIsEdit] = useState(false);
  return (
    <div>
      <TitleWithBtn
        title="Academic Info"
        setIsActive={setIsActive}
        isAdd={true}
        btnFunc={setIsEdit}
      />

      {isEdit ? (
        <div className="bg-white p-4 flex gap-3 flex-wrap rounded-xl shadow-md mt-4 relative">
          <span
            className="h-[32px] w-[32px] text-white font-bold bg-black grid place-items-center rounded-full absolute right-2 top-2 cursor-pointer hover:bg-red-500 transition-all"
            onClick={() => setIsEdit(false)}
          >
            X
          </span>
          <div className="">
            <label
              htmlFor=""
              className="text-[16px] font-medium text-[#2f2f30] block"
            >
              Institute Name
            </label>
            <input
              type="text"
              placeholder=""
              className="input input-bordered input-info w-full max-w-xs"
            />
          </div>
          <div className="">
            <label
              htmlFor=""
              className="text-[16px] font-medium text-[#2f2f30] block"
            >
              Department
            </label>
            <input
              type="text"
              placeholder="Type here"
              className="input input-bordered input-info w-full max-w-xs"
            />
          </div>
          <div className="">
            <label
              htmlFor=""
              className="text-[16px] font-medium text-[#2f2f30] block"
            >
              Passing Year
            </label>
            <input
              type="text"
              placeholder="Type here"
              className="input input-bordered input-info w-full max-w-xs"
            />
          </div>
          <div className="">
            <label
              htmlFor=""
              className="text-[16px] font-medium text-[#2f2f30] block"
            >
              G.P.A
            </label>
            <input
              type="text"
              placeholder="Type here"
              className="input input-bordered input-info w-full max-w-xs"
            />
          </div>
          <button className="btn btn-info bg-[#2abfc4] text-white w-full max-w-xs">
            Save
          </button>
        </div>
      ) : (
        <div className="mt-8 flex flex-wrap gap-4 max-h-[85vh] overflow-auto">
          {new Array(15).fill(0).map((item) => (
            <div className="min-h-[181px] min-w-[388px] flex-1 max-h-fit bg-white shadow-xl p-4 rounded-xl">
              <div className="flex justify-between items-center">
                <p className="text-[14px] font-medium text-[#6C757D]">
                  Insittute Name
                </p>
                <span
                  className="h-[32px] w-[32px] bg-black grid place-items-center rounded-full  cursor-pointer hover:bg-[#2ABFC4] transition-all"
                  onClick={() => setIsEdit(!isEdit)}
                >
                  <BsFillPencilFill color="#ffffff" />
                </span>
              </div>
              <h3 className="text-[18px] font-medium text-black mb-3">
                Bolihari Higher Secondary School
              </h3>
              <h5 className="text-[14px] font-medium text-[#6C757D]">
                Department
              </h5>
              <h3 className="text-[18px] font-medium text-black mb-3">
                Science
              </h3>
              <div className="flex items-start gap-[20%]">
                <div className="">
                  <p className="text-[14px] font-medium text-[#6C757D]">
                    Passing Year
                  </p>

                  <h3 className="text-[18px] font-medium text-black">2010</h3>
                </div>
                <div className="">
                  <p className="text-[14px] font-medium text-[#6C757D]">
                    G.P.A
                  </p>
                  <h3 className="text-[18px] font-medium text-black">3.31</h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

//============================================================================
const ExperienceInfo = ({ setIsActive }) => {
  const [isEdit, setIsEdit] = useState(false);
  return (
    <div>
      <TitleWithBtn
        title="Experience Info"
        setIsActive={setIsActive}
        isAdd={true}
        btnFunc={setIsEdit}
      />

      {isEdit ? (
        <div className="bg-white p-4 flex gap-3 flex-wrap rounded-xl shadow-md mt-4 relative">
          <span
            className="h-[32px] w-[32px] text-white font-bold bg-black grid place-items-center rounded-full absolute right-2 top-2 cursor-pointer hover:bg-red-500 transition-all"
            onClick={() => setIsEdit(false)}
          >
            X
          </span>
          <div className="">
            <label
              htmlFor=""
              className="text-[16px] font-medium text-[#2f2f30] block"
            >
              Organization Name
            </label>
            <input
              type="text"
              placeholder=""
              className="input input-bordered input-info w-full max-w-xs"
            />
          </div>
          <div className="">
            <label
              htmlFor=""
              className="text-[16px] font-medium text-[#2f2f30] block"
            >
              Designation
            </label>
            <input
              type="text"
              placeholder="Type here"
              className="input input-bordered input-info w-full max-w-xs"
            />
          </div>
          <div className="">
            <label
              htmlFor=""
              className="text-[16px] font-medium text-[#2f2f30] block"
            >
              From
            </label>
            <input
              type="date"
              placeholder="Type here"
              className="input input-bordered input-info w-full max-w-xs"
            />
          </div>
          <div className="">
            <label
              htmlFor=""
              className="text-[16px] font-medium text-[#2f2f30] block"
            >
              To
            </label>
            <input
              type="date"
              placeholder="Type here"
              className="input input-bordered input-info w-full max-w-xs"
            />
          </div>
          <button className="btn btn-info bg-[#2abfc4] text-white w-full max-w-xs">
            Save
          </button>
        </div>
      ) : (
        <div className="mt-8 flex flex-wrap gap-4 max-h-[85vh] overflow-auto">
          {new Array(15).fill(0).map((item) => (
            <div className="min-h-[181px] min-w-[400px] flex-1 max-h-fit bg-white shadow-xl p-4 rounded-xl">
              <div className="flex justify-between items-center">
                <p className="text-[14px] font-medium text-[#6C757D]">
                  Organization Name
                </p>
                <span
                  className="h-[32px] w-[32px] bg-black grid place-items-center rounded-full  cursor-pointer hover:bg-[#2ABFC4] transition-all"
                  onClick={() => setIsEdit(!isEdit)}
                >
                  <BsFillPencilFill color="#ffffff" />
                </span>
              </div>
              <h3 className="text-[18px] font-medium text-black mb-3">
                Monipuri Clinic
              </h3>
              <h5 className="text-[14px] font-medium text-[#6C757D]">
                Designation
              </h5>
              <h3 className="text-[18px] font-medium text-black mb-3">
                Caregiver
              </h3>
              <div className="flex items-start gap-2 justify-between">
                <div className="">
                  <p className="text-[14px] font-medium text-[#6C757D]">From</p>

                  <h3 className="text-[18px] font-medium text-black">
                    20th January, 2022
                  </h3>
                </div>
                <div className="">
                  <p className="text-[14px] font-medium text-[#6C757D]">To</p>
                  <h3 className="text-[18px] font-medium text-black">
                    20th July, 2022
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

//============================================================================

const ScheduleInfo = ({ setIsActive }) => {
  const [isEdit, setIsEdit] = useState(false);
  return (
    <div>
      <TitleWithBtn title="Schedule Info" setIsActive={setIsActive} />

      {isEdit ? (
        <div>Edit</div>
      ) : (
        <div className="mt-8 flex flex-wrap gap-4">
          <ScheduleInfoComponent />
          <ScheduleInfoComponent />
        </div>
      )}
    </div>
  );
};

const ScheduleInfoComponent = () => {
  return (
    <div className="min-w-[400px] min-h-[227px] max-h-full bg-white shadow-lg rounded-xl px-[33px] py-[23px] flex-1 max-w-[50%] relative">
      <div className="flex justify-between items-center relative">
        <h4 className="text-[18px] font-medium mb-3">Active Dary</h4>
        <span className="h-[32px] w-[32px] bg-black grid place-items-center rounded-full  cursor-pointer hover:bg-[#2ABFC4] transition-all">
          <BsFillPencilFill color="#ffffff" />
        </span>
      </div>
      <div className="mb-2">
        <p className="text-[14px] font-medium text-[#686868]">Service type</p>
        <h3 className="text-[18px] font-medium text-black">Emergency</h3>
      </div>
      <div className="mb-2">
        <p className="text-[14px] font-medium text-[#686868]">
          Service duration
        </p>
        <h3 className="text-[18px] font-medium text-black">Weekly</h3>
      </div>
      <p className="text-[14px] font-medium text-[#686868] mb-2">Specialty</p>
      <div className=" flex gap-2 flex-wrap">
        <span className="py-[10px] px-4 bg-black text-white block rounded-[42px] font-medium">
          Saturday
        </span>
        <span className="py-[10px] px-4 bg-black text-white block rounded-[42px] font-medium ">
          Sunday
        </span>
        <span className="py-[10px] px-4 bg-black text-white block rounded-[42px] font-medium ">
          Monday
        </span>
        <span className="py-[10px] px-4 bg-black text-white block rounded-[42px] font-medium ">
          Tuesday
        </span>
        <span className="py-[10px] px-4 bg-black text-white block rounded-[42px] font-medium ">
          Wednesday
        </span>
        <span className="py-[10px] px-4 bg-black text-white block rounded-[42px] font-medium ">
          Friday
        </span>
      </div>
      <div className="mb-2">
        <p className="text-[14px] font-medium text-[#686868] mt-3">
          Service area{' '}
        </p>
        <h3 className="text-[18px] font-medium text-black">Mirpur Khmarbari</h3>
      </div>
      <div className="mb-2">
        <p className="text-[14px] font-medium text-[#686868]">
          Patient gender{' '}
        </p>
        <h3 className="text-[18px] font-medium text-black">Both</h3>
      </div>
      <div className="mt-2">
        <h4 className="font-medium">Active Time</h4>
        <p className="font-medium">10:00 AM - 10:00 PM</p>
      </div>
    </div>
  );
};

//============================================================================

const ServiceInfo = ({ setIsActive }) => (
  <div>
    <TitleWithBtn title="Service Info" setIsActive={setIsActive} />
  </div>
);

//==============
const Schedule = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-[388px] h-[227px] max-w-full max-h-full bg-white shadow-lg rounded-xl px-[33px] py-[23px]">
        <div className="flex justify-between items-center">
          <h4 className="text-[18px] font-medium">Active Dary</h4>
          <span>i</span>
        </div>
        <div className="">
          <span className="py-[10px] px-4 bg-black text-white block rounded-[42px] font-medium">
            Saturday
          </span>
          <span className="py-[10px] px-4 bg-black text-white block rounded-[42px] font-medium ">
            Sunday
          </span>
          <span className="py-[10px] px-4 bg-black text-white block rounded-[42px] font-medium ">
            Monday
          </span>
          <span className="py-[10px] px-4 bg-black text-white block rounded-[42px] font-medium ">
            Tuesday
          </span>
          <span className="py-[10px] px-4 bg-black text-white block rounded-[42px] font-medium ">
            Wednesday
          </span>
          <span className="py-[10px] px-4 bg-black text-white block rounded-[42px] font-medium ">
            Friday
          </span>
        </div>
        <div className="">
          <h4>Active Time</h4>
          <p>10:00 AM - 10:00 PM</p>
        </div>
      </div>
    </div>
  );
};
const Appointment = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  return (
    <div>
      <div className=" flex items-center">
        <button
          onClick={() => setIsEdit(false)}
          className={`text-[1.3rem] font-medium w-full p-3 border-b-2  ${
            isEdit ? '' : 'border-[#F1634C]'
          }`}
        >
          Emergency
        </button>
        <button
          onClick={() => setIsEdit(true)}
          className={`text-[1.3rem] font-medium w-full p-3 border-b-2 ${
            isEdit ? 'border-[#F1634C]' : ''
          }`}
        >
          Regular
        </button>
      </div>
      <div className="">
        <div className="">
          {isView && (
            <div className="">
              <div className="bg-white rounded-lg p-4 flex flex-wrap items-start gap-6 relative">
                <span
                  className="h-[32px] w-[32px] text-white font-bold bg-black grid place-items-center rounded-full absolute right-2 top-2 cursor-pointer hover:bg-red-500 transition-all"
                  onClick={() => setIsView(false)}
                >
                  X
                </span>

                <div className="">
                  <p className="text-[14px] font-medium text-[#5f5f5f]">
                    Patient name
                  </p>
                  <h3 className="text-[18px] font-medium">MD. Fahad</h3>
                </div>
                <div className="">
                  <p className="text-[14px] font-medium text-[#5f5f5f]">
                    Patient sex
                  </p>
                  <h3 className="text-[18px] font-medium">Male</h3>
                </div>
                <div className="">
                  <p className="text-[14px] font-medium text-[#5f5f5f]">
                    Blood group
                  </p>
                  <h3 className="text-[18px] font-medium">A(+ve)</h3>
                </div>
                <div className="">
                  <p className="text-[14px] font-medium text-[#5f5f5f]">Date</p>
                  <h3 className="text-[18px] font-medium">20/09/2022</h3>
                </div>
                <div className="">
                  <p className="text-[14px] font-medium text-[#5f5f5f]">
                    Reason
                  </p>
                  <h3 className="text-[18px] font-medium">Amnesia</h3>
                </div>
                <div className="">
                  <p className="text-[14px] font-medium text-[#5f5f5f]">
                    Address
                  </p>
                  <h3 className="text-[18px] font-medium">Farmgate, Dhaka.</h3>
                </div>
                <div className="">
                  <p className="text-[14px] font-medium text-[#5f5f5f]">Age</p>
                  <h3 className="text-[18px] font-medium">23 year (s)</h3>
                </div>
                <div className="">
                  <p className="text-[14px] font-medium text-[#5f5f5f]">
                    Weight (KG)
                  </p>
                  <h3 className="text-[18px] font-medium">60</h3>
                </div>
                <div className="">
                  <p className="text-[14px] font-medium text-[#5f5f5f]">
                    Contact
                  </p>
                  <h3 className="text-[18px] font-medium">01304-350205</h3>
                </div>
                <div className="">
                  <p className="text-[14px] font-medium text-[#5f5f5f]">Time</p>
                  <h3 className="text-[18px] font-medium">04:00 PM</h3>
                </div>
                <div className="">
                  <p className="text-[14px] font-medium text-[#5f5f5f]">Fee</p>
                  <h3 className="text-[18px] font-medium">BDT 1000.00</h3>
                </div>
              </div>
              <div className=""></div>
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 mt-4 gap-2">
          {!isEdit
            ? new Array(10).fill('0').map((item) => (
                <div className="shadow-md flex items-center justify-between p-2 gap-2 bg-white rounded-lg ">
                  <div className="flex items-center gap-4">
                    <img
                      src="https://plus.unsplash.com/premium_photo-1685312182226-20af33367686?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=663&q=80"
                      className="h-[65px] w-[65px] rounded-full shadow-md"
                      alt=""
                    />
                    <div className="">
                      <p className="text-[14px] font-medium text-[#818181]">
                        Appointment ID: 45A584A
                      </p>
                      <h3 className="text-[16px] font-medium text-black">
                        Kalachan Miah
                      </h3>
                      <div className=" text-[13px] font-medium">
                        <span className="mr-8">01 Mar,2022</span>
                        <span>Monday, 10:00 am.</span>
                      </div>
                    </div>
                  </div>

                  <button
                    className="btn capitalize bg-[#2ABFC4] text-[14px] text-white hover:text-black"
                    onClick={() => setIsView(true)}
                  >
                    View Details
                  </button>
                </div>
              ))
            : new Array(15).fill('0').map((item) => (
                <div className="shadow-md flex items-center justify-between p-2 gap-2 bg-white rounded-lg ">
                  <div className="flex items-center gap-4">
                    <img
                      src="https://plus.unsplash.com/premium_photo-1685312182226-20af33367686?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=663&q=80"
                      className="h-[65px] w-[65px] rounded-full shadow-md"
                      alt=""
                    />
                    <div className="">
                      <p className="text-[14px] font-medium text-[#818181]">
                        Appointment ID: 45A584A
                      </p>
                      <h3 className="text-[16px] font-medium text-black">
                        Kalachan Miah
                      </h3>
                      <div className=" text-[13px] font-medium">
                        <span className="mr-8">01 Mar,2022</span>
                        <span>Monday, 10:00 am.</span>
                      </div>
                    </div>
                  </div>

                  <button className="btn capitalize bg-[#2ABFC4] text-[14px] text-white hover:text-black">
                    View Details
                  </button>
                </div>
              ))}
        </div>
        <div className=""></div>
      </div>
    </div>
  );
};
const Wallet = () => {
  return <div>Wallet</div>;
};
const Transactions = () => {
  return <div>Transactions</div>;
};
const History = () => {
  return <div>History</div>;
};
const Setting = () => {
  return <div>Setting</div>;
};
const QRScan = () => {
  return (
    <div className="w-full h-full grid place-items-center rounded-xl bg-white">
      <div className="flex flex-col items-center">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/5/5e/QR_Code_example.png"
          className="h-[346px] w-[346px] p-3"
          alt=""
        />
        <br />
        <p className="text-[14px] text-[#646464]">
          If you share it with someone, they scan it with their Parasol Health
          app and find you.
        </p>
      </div>
    </div>
  );
};
const Language = () => {
  return <div>Language</div>;
};

const DashboardHome = () => {
  const [courseView, setCourseView] = useState(false);
  const [courseDetails, setCourseDetails] = useState(false);
  return (
    <div className="nurseDashboard_grid_body_wrap relative">
      {courseView && (
        <div className="absolute w-full h-full top-0 bottom-0 left-0 right-0 bg-white z-10 rounded-lg shadow-lg px-4 py-3">
          {courseDetails ? (
            <OurSingleCourseDeatils />
          ) : (
            <OurSingleCourse
              onBack={setCourseView}
              onClick={setCourseDetails}
            />
          )}
        </div>
      )}

      <div className="mb-5">
        <div className="">
          <div className="glass bg-white flex flex-col justify-evenly items-start min-h-[250px]  rounded-lg p-4 shadow-md relative">
            <h4 className="text-[1.4rem] font-medium">Lorem, ipsum dolor.</h4>
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Numquam
              asperiores dolorem laborum voluptatum totam facere adipisci ex eum
              in. At harum commodi quasi illo quia dicta maiores voluptates qui
              quam!
            </p>
            <button className="btn btn-accent text-white">Eran Monry</button>
          </div>
        </div>
      </div>
      <div className="min-h-[250px] flex gap-4">
        <div className="glass border rounded-lg shadow min-w-[300px] max-w-full flex-1 p-4 flex flex-col gap-3">
          <p className="text-[16px] font-medium">container title</p>
          <Nursecard title="Appointment" iconName="documents.png" />
          <Nursecard title="Wallet" iconName="wallet.png" />
          <Nursecard title="Schedule" iconName="calendar.png" />
          <Nursecard
            title="Courses"
            onClick={setCourseView}
            iconName="graduate1.png"
          />
        </div>
        <div className="rounded-lg shadow glass min-w-[300px] flex-1 max-w-full p-4 ">
          <p className="text-[16px] font-medium mb-3">quick access</p>

          <ActiveCard
            iconName="https://img.freepik.com/free-psd/medical-chart-pen-icons_23-2149257777.jpg?w=826&t=st=1686374141~exp=1686374741~hmac=2bee262a042e27ebe0ce6969784ada433f611b7614669b6038ead2d01685ea3b"
            title="Active Appointment"
            count={10}
            color={'#028DB9'}
          />

          <ActiveCard
            iconName="https://img.freepik.com/free-psd/3d-illustration-stomatology-dentistry_23-2150033491.jpg?size=626&ext=jpg&ga=GA1.1.474731673.1669717882&semt=ais"
            title="Pending Appointment "
            count={7}
            color={'#009F40'}
          />

          <ActiveCard
            iconName="https://img.freepik.com/free-psd/medical-chart-pen-icons_23-2149257777.jpg?w=826&t=st=1686374141~exp=1686374741~hmac=2bee262a042e27ebe0ce6969784ada433f611b7614669b6038ead2d01685ea3b"
            title="Cancel Appointment"
            count={3}
            color={'#F1634C'}
          />
        </div>
      </div>
      <div className="mt-8">
        <h3 className="text-[1.2rem] font-bold">Latest News</h3>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda aut
          ex ut! Deserunt animi eligendi, odit quis illo accusantium officia
          voluptatibus unde sunt. Delectus aliquid maxime laboriosam earum
          beatae quasi.
        </p>
      </div>
    </div>
  );
};

//================================================================

const OurSingleCourse = ({ onClick, onBack }) => {
  return (
    <div>
      <div className="flex items-center gap-4">
        <button
          onClick={() => onBack(false)}
          className="btn btn-circle shadow-xl bg-[#2ABFC4] text-white hover:bg-slate-50 hover:text-[#F1634C] transition-all"
        >
          <TiArrowLeftThick size={25} />
        </button>
        <h3 className="text-[22px] font-semibold border-b border-gray-400">
          Our Course
        </h3>
      </div>
      <div className="h-[83vh] overflow-hidden">
        <div className="relative w-full h-full overflow-auto flex flex-wrap gap-4 mt-6">
          {new Array(12).fill('0').map((item) => (
            <div
              className="border border-gray-200 rounded-2xl overflow-hidden shadow-lg min-h-[320px] min-w-[388px] flex-1 max-w-[50%] flex flex-col"
              onClick={() => onClick(true)}
            >
              <div className=" max-h-[280px] h-full w-full">
                <img
                  src="https://th.bing.com/th/id/OIG.n9uMDUv50OpeIkdd_8u0"
                  alt=""
                  className="h-[280px] w-full"
                />
              </div>
              <div className="bg-[#2ABFC4] px-1 py-2 ">
                <div className="flex items-center justify-around pt-2">
                  <p className="bg-[rgba(255,255,255,0.2)] items-center flex text-white text-[14px] font-medium px-4 py-2 rounded-3xl">
                    <MdOutlineWatchLater className="mr-4" size={22} />
                    <span>15 Days left</span>
                  </p>
                  <p className="bg-[rgba(255,255,255,0.2)] items-center flex text-white text-[14px] font-medium px-4 py-2 rounded-3xl">
                    <TbUsers className="mr-4" size={22} />
                    <span>10 Seat available</span>
                  </p>
                </div>
                <h3 className="text-[20px] font-semibold text-white py-2">
                  Nurse Training Workshop & Theory.
                </h3>
                <h4 className="text-[18px] font-medium">
                  Instructor : Tabassum Ferdous
                </h4>
                <div className="flex items-center gap-[10%] mt-2">
                  <p className="text-[22px] font-semibold text-white">
                    10,000 BDT
                  </p>
                  <p className="text-[20px] font-medium text-[#F1634C] line-through">
                    15,000 BDT
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const OurSingleCourseDeatils = () => {
  let taskList = [
    'Total Class 10',
    'Job Opportunity',
    'Practical Class 05',
    'Certificate',
    'Theory Class 05',
  ];

  return (
    <div>
      <div className="flex items-center gap-4">
        <button
          onClick={() => true}
          className="btn btn-circle shadow-xl bg-[#2ABFC4] text-white hover:bg-slate-50 hover:text-[#F1634C] transition-all"
        >
          <TiArrowLeftThick size={25} />
        </button>
        <h3 className="text-[22px] font-semibold border-b border-gray-400">
          Course Overview
        </h3>
      </div>
      <div className="mt-6">
        {/* <div className="">

        <h3 className="text-[20px] font-medium">
          Nurse Training Workshop & Theory.
        </h3>
        <p className="pl-2 mt-1">
          There are many variations of passages of Lorem Ipsum available, but
          the majority have suffered alteration in some form, by injected
          humour.
        </p>
        <h3 className="mt-4 text-[20px] font-medium">Course Schedule</h3>
        <div className="bg-gray-200 py-6 px-8 w-full max-w-md rounded-xl shadow-md ml-3 mt-2">
          <p className="flex items-center gap-3">
            <AiOutlineUser size={22} /> <span>Tabassum Ferdous</span>
          </p>
          <div className="flex items-center gap-8 mt-2">
            <p className="flex items-center gap-2">
              <BiCalendarCheck size={22} /> <span>Nov 10th, 2022</span>
            </p>
            <p className="flex items-center gap-2">
              <BiTimeFive size={22} /> <span>09:00 AM to 11:00 AM</span>
            </p>
          </div>
          <p className="flex items-center gap-2 mt-3">
            <FaRegCalendarAlt size={22} />{' '}
            <span>Saturday, Monday, Wednesday</span>
          </p>
        </div>
        <div className="border w-full max-w-md p-6  bg-[#EDFEFF] rounded-lg grid grid-cols-2 shadow-sm gap-y-4 gap-x-2 mt-8 ml-3">
          {taskList.map((item) => (
            <p className="flex items-center gap-2">
              <MdOutlineTaskAlt color="#2ABFC4" size={22} />{' '}
              <span className="text-[14px]">{item}</span>
            </p>
          ))}
        </div>
        <h3 className="text-[20px] font-medium mt-6 mb-2">About Course</h3>
        <p className="pl-2">
          There are many variations of passages of Lorem Ipsum available, but
          the majority have suffered alteration in some form, by injected
          humour, but the majority have suffered alteration in some form, by
          injected humour.There are many variations of passages of Lorem Ipsum
          available, but the majority have suffered alteration in some form, by
          injected humour, but the majority have suffered alteration in some
          form, by injected humour.
        </p>
        <div className="rounded-lg shadow-md mt-8 min-h-16 bg-[#2ABFC4] p-3 flex justify-evenly items-center">
          <div className="flex items-center gap-4">
            <span className="font-bold text text-[22px] text-white">
              10,000 BDT
            </span>
            <span className="font-semibold text-[18px] text-[#ff2200] line-through ">
              15,000 BDT
            </span>
          </div>
          <button className="btn bg-[#F1634C] hover:bg-[#ce1b00] text-white capitalize font-semibold text-[18px] border-0  flex items-center gap-1">
            Join to Course <BiChevronRight />
          </button>
        </div>
        </div> */}
        <div className="">
          <h3 className="text-[20px] font-medium">
            Nurse Training Workshop & Theory.
          </h3>
          <p className="pl-2 mt-1">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour.
          </p>
          <p>
            <span className="font-semibold text-[18px] text-[#ff2200] line-through mx-3 mt-3">
              15,000 BDT
            </span>
            <span className="font-bold text text-[22px] text-black">
              10,000 BDT
            </span>
          </p>
          <h3 className="text-[20px] font-medium mt-6">Course Start On</h3>
          <div className="border bg-[#F1634C] text-white p-6 rounded-lg shadow-md w-fit text-center ml-4 mt-2">
            <p className="text-[26px] font-bold">10 Nov</p>
            <p className="text-[18px] font-semibold">Sat, 09:00 AM</p>
          </div>
          <div className="border w-full max-w-md p-6  bg-[#EDFEFF] rounded-lg grid grid-cols-2 shadow-sm gap-y-4 gap-x-2 mt-6 ml-3">
            {taskList.map((item) => (
              <p className="flex items-center gap-2">
                <MdOutlineTaskAlt color="#2ABFC4" size={22} />{' '}
                <span className="text-[14px]">{item}</span>
              </p>
            ))}
          </div>
          <button className="btn bg-[#F1634C] hover:bg-[#ce1b00] text-white capitalize font-semibold text-[18px] border-0  flex items-center gap-1 w-full max-w-sm mt-12 ml-4">
            Purchase Now <BiChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};
