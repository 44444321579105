import axios from 'axios';
import React, { useState } from 'react';
import { IoCallOutline } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function NurseUserSendOTP() {
  const [phone, setPhone] = useState('');
  const navigate = useNavigate();

  const nurseUserPhoneSignUp = async () => {
    try {
      let respons = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/send_otp`,
        {
          phone: phone,
        }
      );
      toast.success(respons?.data?.message);

      if (phone.length > 10)
        localStorage.setItem('parasol_health_phone', phone);
      navigate('/verify-otp');
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <div className="w-screen h-screen grid place-items-center">
      <div className="p-6 h-fit min-w-[450px] max-w-full shadow-md">
        <div className="h-[163px] w-[217px] mx-auto">
          <img
            src="assets/Parasol-Health.svg"
            alt=""
            className="w-full h-full"
          />
        </div>
        <div className="">
          <label htmlFor="pnoneNo" className="my-2">
            Phone Number
          </label>
          <label className="flex border border-gray-300 px-2 rounded-md items-center focus:border-[#2ABFC4] active:border-[#2ABFC4] hover:border-[#2ABFC4]">
            <IoCallOutline size={28} />
            <input
              onChange={(e) => setPhone(e.target.value)}
              id="pnoneNo"
              placeholder="01xxxxxxxxxxx"
              type="text"
              className="h-10 outline-none border-0 w-full pl-2"
            />
          </label>

          <br />

          <div className="flex flex-col justify-center">
            <button
              className="bg-[#2ABFC4] capitalize mx-auto hover:bg-[#00b8be] my-4 btn text-white w-full max-w-sm"
              onClick={nurseUserPhoneSignUp}
            >
              Send OTP
            </button>
            <p className="text-[15px] font-medium text-center mb-4">
              Already have an Account?{' '}
              <Link to="/nurse-user-login" className="text-[#2ABFC4] underline">
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
