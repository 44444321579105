export const postData = [
  {
    img: '/assets/Rectangle1.png',
    title: 'Tele-Medicine',
    content:
      'In the fast-paced digital age, consulting a healthcare professional with Parasol Health telemedicine has emerged as a groundbreaking solution, bridging the gap between patients and healthcare providers all can be done 24/7!',
    link: '/services/tele-medicine',
  },
  {
    img: '/assets/Rectangle2.png',
    title: 'Pharmacy',
    content:
      'Parasol Model Pharma your premier destination for all your healthcare Products your need. As a trusted and reliable pharmacy, we are committed to providing top-notch products and services to enhance your well-being both online and offline.',
    link: '/services/pharmacy',
  },
  {
    img: '/assets/Rectangle 144 (1).png',
    title: 'Medical Tourism',
    content:
      'Explore the world of medical tourism and discover dynamic and cost-effective solution that allows you to access top-notch medical treatments while exploring the beauty of international destinations in India, Thailand, Singapore, Turki.',
    link: '/services/medical-tourism',
  },
  {
    img: '/assets/Rectangle4.png',
    title: 'Appointment with Specialist Doctors',
    content:
      'Are you seeking top-tier medical expertise for your unique health concerns? Look no further. Our specialized doctor appointments offer a direct pathway to tailored healthcare solutions, right at your fingertips.',
    link: '/services/appointment-doctors',
  },
  {
    img: '/assets/Rectangle5.png',
    title: 'Diagnostic',
    content:
      'Parasol Health is your trusted partner in advanced diagnostic solutions. Our state-of-the-art diagnostic services are designed to provide you with accurate and timely health insights, empowering you to make informed decisions about your well-being.',
  },
  {
    img: '/assets/Rectangle6.png',
    title: 'Hospitalization',
    content:
      'Our hospitalization services provide the highest level of medical expertise and personalized treatment. We understand that hospitalization can be a daunting experience, which is why our dedicated team is committed to delivering compassionate care in a state-of-the-art facility.',
  },
  {
    img: '/assets/Rectangle7.png',
    title: 'Psycho Counselling',
    content:
    "Discover inner healing and emotional well-being through professional psycho counselling. Our experienced consultant provides personalized guidance for a brighter future. Start your journey today with Parasol health for navigate life's complexities, fostering emotional resilience and personal growth.",
    link: '/services/psycho-counselling',
  },
  {
    img: '/assets/Rectangle8.png',
    title: 'Health Card',
    content:
    "Parasol health Card is your gateway to comprehensive health coverage and a world of wellness. Your health card is not just a piece of plastic – it's your key to accessing a multitude of health insurance and healthcare benefits that empower you to take charge of your well-being.",
    link: '/services/health-card',
  },
  {
    img: '/assets/Rectangle9.png',
    title: 'Nursing & Caregiver Service',
    content:
      'Discover our specialized nursing and caregiver services designed to provide compassionate and skilled care to patients in various settings. At Parasol health, our dedicated nursing team delivers exceptional care, ensuring the well-being and comfort of every patient we serve.',
    link: '/services/training-services',
  },
];
