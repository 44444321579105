import './PageNotFound.css';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className="not-found-wrap">
      <div className="wrap2">
        <p>WhOoops..! Pages Not Found </p>
        <Link to="/">Go Back Home</Link>
      </div>
      <div className="wrap1">
        <img src="/assets/pageNotFound-4.png" alt="404" />
      </div>
    </div>
  );
};

export default PageNotFound;
