import { Link } from 'react-router-dom';
import { Footer, Navbar } from '../../components';
import './SubmitDone.css';

const SubmitDone = () => {
  return (
    <>
      <Navbar white menuBlack bgWhite />
      <div className="SubmitDone_card_container">
        <div className="SubmitDone_card">
          <div className="SubmitDone_card-content">
            <h3>We have received your response.</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry
            </p>
            <Link to="#">Go to home</Link>
          </div>
        </div>

        <div className="SubmitDone_card-image">
          <img src="/assets/submitdone_poster.png" alt="WELCOME" />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SubmitDone;
