import React from 'react'
import './OfferItem.css'
const OfferItem = ({specialties}) => {
  const {name, img} =specialties;
  return (
    <div className="offer-item">
            <div className="offer-icon">
                <img src={img} alt="specialties" />
            </div>
            <div className="offer-text">
                <p>{name}</p>
            </div>
        </div>
  )
}

export default OfferItem