import { useEffect, useState } from 'react';
import { RiQuillPenLine } from 'react-icons/ri';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import axios from 'axios';

export default function UserInfo() {
  const [profileEdit, setProfileEdit] = useState(false);

  let token = Cookies.get('parasol_token');
  const [userInfo, setUserInfo] = useState([])
  console.log(userInfo)
  const {name,phone,email,image, nid,dob} = userInfo;


  const getUserInfo = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/user_profile_detail`,
        config
      );
      setUserInfo(response.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);


  let inputStyle = 'input input-bordered input-info w-full max-w-xs';
  return (
    <div className="flex flex-col items-center p-3">
      <div className="card w-full bg-base-100 shadow-xl relative z-0">
        <figure className="px-10 pt-10">
          <div className="avatar ">
            <div className="w-32 rounded border border-gray-300">
              <img src={image} />
            </div>
          </div>
        </figure>
        <div className="card-body items-center text-center -mt-4">
          {userInfo ? (
            <input
              type="text"
              placeholder="Enter your Name"
              className={inputStyle}
              value={name}
              disabled
            />
          ) : (
            <h2 className="card-title">User N/A</h2>
          )}

          {!profileEdit && <p>⭐⭐⭐⭐⭐</p>}
          {profileEdit ? (
            <textarea
              defaultValue=" Lorem ipsum dolor sit amet elit. Eos tempore nihil nam commodi
            voluptate!"
              className="textarea textarea-info w-full"
              placeholder="Write youra Bio here.... "
            ></textarea>
          ) : (
            <p>
              Lorem ipsum dolor sit amet elit. Eos tempore nihil nam commodi
              voluptate!
            </p>
          )}

          <div className="divider divide-green-400">info</div>
          <div className="text-start">
            <label htmlFor="" className="my-1 block">
              Phone :
            </label>
            <input
              type="text"
              placeholder="Enter your Name"
              className={inputStyle}
              defaultValue={phone}
              disabled={!profileEdit}
            />
            <label htmlFor="" className="my-1 block">
              Email
            </label>
            <input
              type="text"
              placeholder="Enter your Name"
              className={inputStyle}
              defaultValue={email}
              disabled={!profileEdit}
            />
            <label htmlFor="" className="my-1 block">
              Date of Birth
            </label>

            <input
              type="date"
              placeholder="Enter your Name"
              className={inputStyle}
              defaultValue={dob}
              disabled={!profileEdit}
            />
            <label htmlFor="" className="my-1 block">
              NID Number
            </label>
            <input
              type="text"
              placeholder="Enter your Name"
              className={inputStyle}
              defaultValue={nid}
              disabled={!profileEdit}
            />
            <label htmlFor="" className="my-1 block">
              Gender
            </label>
            {profileEdit ? (
              <div className="flex flex-wrap gap-4">
                <label htmlFor="male" className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="radio-4"
                    id="male"
                    style={{ display: 'block' }}
                    className="radio radio-accent  "
                    checked
                  />
                  <span>Male</span>
                </label>
                <label htmlFor="female" className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="radio-4"
                    id="female"
                    style={{ display: 'block' }}
                    className="radio radio-accent  "
                  />
                  <span>Female</span>
                </label>
                <label htmlFor="Others" className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="radio-4"
                    id="Others"
                    style={{ display: 'block' }}
                    className="radio radio-accent  "
                  />
                  <span>Others</span>
                </label>
              </div>
            ) : (
              <p className="text-[18px] font-semibold">Male</p>
            )}
            <br />
            {!profileEdit ? (
              <button
                onClick={() => setProfileEdit(true)}
                className="btn btn-link text-red-400    "
              >
                <RiQuillPenLine />
                Edit
              </button>
            ) : (
              <button
                onClick={() => setProfileEdit(false)}
                className="btn btn-outline text-[#2ABFC4]    "
              >
                <RiQuillPenLine />
                Update
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
