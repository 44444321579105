import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const blueBtn =
  'bg-[#2ABFC4] capitalize mx-auto text-[20px] hover:bg-[#00b8be] my-4 btn text-white w-full max-w-sm';

export default function NurseUserVerifyOTP() {
  const [start, setDown] = useState(60);
  const [OTP, setOTP] = useState('');
  const navigate = useNavigate();
  function expairOTP() {
    let countTime = setTimeout(() => setDown(start - 1), 1000);
    if (start == 0) {
      clearTimeout(countTime);
      navigate('/send-otp');
      return;
    }
  }
  expairOTP();

  const checkOTp = async () => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/verify_otp`,
        { otp: OTP }
      );
      toast.success('OTP Verificatoin Successfully Done.?');
      navigate('/user-signup');
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message);
    }
  };

  return (
    <div className="w-screen h-screen grid place-items-center">
      <div className="min-w-[450px] h-fit border p-6">
        <h3 className="text-[22px] text-[#2ABFC4] font-semibold text-center my-4">
          Phone Number Verification
        </h3>
        <div className="h-[100px] w-[100px] rounded-full mx-auto">
          <img src="assets/verificalogo.svg" className="w-full h-full" alt="" />
        </div>
        <h3 className="text-[24px] font-bold text-center mt-3">
          Verification code
        </h3>
        <p className="text-[#2abfc4] text-center font-medium">
          We sent the verification code to your Number.
        </p>
        <input
          onChange={(e) => setOTP(e.target.value)}
          type="text"
          placeholder="Enter Code "
          className="input input-bordered input-accent w-full max-w-md my-4"
        />
        <p className="text-[15px] font-medium text-center mb-4">
          You don’t receive OTP? <span className="text-[#2ABFC4]"> 00:</span>
          <span className="countdown text-[#2ABFC4]">
            <span style={{ '--value': start }}></span>
          </span>
        </p>

        <button className={blueBtn} onClick={checkOTp}>
          Proceed
        </button>
      </div>
    </div>
  );
}
