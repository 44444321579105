import CardImageStyle from '../cardImageStyle/CardImageStyle';
import './cardTwo.css';

const CardTwo = ({
  color,
  positionX,
  positionY,
  rowRev,
  header,
  icon,
  image,
  objective
}) => {
  return (
    <section className={`cardTwo ${rowRev ? 'rowRev' : null}`}>
      <div className="cardTwo_content">
        <div className="cardTwo_content-header">
          <span className="a">
            <img src={`/assets/${icon}`} alt="icon" />
          </span>
          <h3>{header}</h3>
        </div>
        {
          objective?.map(object => <div className="cardTwo_content-parragraph">
          <span>
            <img src="/assets/Polygon 7.svg" alt=">" />
          </span>
          <h4>{object.text}</h4>
        </div>)
        }
      </div>
      <CardImageStyle
        image={image}
        color={color}
        positionX={positionX}
        positionY={positionY}
      />
    </section>
  );
};

export default CardTwo;
