import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import './NewsletterSubscribe.css';

const NewsletterSubscribe = () => {
  const subscribeValidate = yup.object({
    email: yup.string().required().email(),
  });
  const { errors, values, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async ({ email }) => {
      const url = process.env.REACT_APP_BACKEND_PRIFILE_URL;
      await axios.post(`${url}/parasolhealth-Subscribeing`, {
        email,
      });
      resetForm();
    },
    validationSchema: subscribeValidate,
  });

  return (
    <section className="newsletterSubscribe md:w-[80%]">
      <h3 className="font-semibold text-3xl">Newsletter subscribe</h3>
      <div className="newsletterSubscribe_content">
        <h2>Subcribe Us For Get More Update About Us.</h2>
        <div className="newsletterSubscribe_content_form">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="E-mail Address"
              name="email"
              value={values.email}
              onChange={handleChange}
            />
            <button type="submit" disabled={errors.email && true}>
              Subscribe
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default NewsletterSubscribe;
