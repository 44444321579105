import React from 'react'
import './ServicesBunner.css'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';


const ServicesBunner = () => {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                // navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper services-bunner"
            >
                <SwiperSlide>
                    <img src="https://i.ibb.co/0m2S155/Rectangle9.png" alt="Services" className='Services-slide' />
                </SwiperSlide>
                <SwiperSlide>
                    <img src="https://i.ibb.co/0m2S155/Rectangle9.png" alt="Services" className='Services-slide' />
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default ServicesBunner