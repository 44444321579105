import { Link } from 'react-router-dom';
import './ContactForm.css';
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiLinkedin,
  FiYoutube,
} from 'react-icons/fi';

import {
  MdOutlineCall,
  MdMailOutline,
  MdOutlineLocationOn,
} from 'react-icons/md';
import { TbWorld } from 'react-icons/tb';

export const ContactInfoCard = ({ title }) => {
  return (
    <div className="Contact_info">
      <h2>{title}</h2>
      <div className="Contact_info-1">
        <span>
          <MdOutlineCall className="Cantact_info_1_icon" />
          <a href="tel:+880 1708545290">+880 1708545290</a>
        </span>
        <span>
          <MdMailOutline className="Cantact_info_1_icon" />
          <a href="mailto:example@gmail.com">info@parasolhealth.org</a>
        </span>
        <span>
          <TbWorld className="Cantact_info_1_icon" />
          <a href="/">www.parasolhealth.org</a>
        </span>
        <span>
          <MdOutlineLocationOn className="Cantact_info_1_icon" />
          <a href="/">Dhaka, Bangladesh</a>
        </span>
      </div>
      <div className="Contact_info-2">
        <Link to="#">
          <FiFacebook />
        </Link>
        <Link to="#">
          <FiTwitter />
        </Link>
        <Link to="#">
          <FiInstagram />
        </Link>
        <Link to="#">
          <FiLinkedin />
        </Link>
        <Link to="#">
          <FiYoutube />
        </Link>
      </div>
    </div>
  );
};
