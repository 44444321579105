import '../ContactList/ContactList.css';
import { GrView } from 'react-icons/gr';
import { IoIosSend } from 'react-icons/io';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { MdClose } from 'react-icons/md';

const QustionList = ({ pageTitle }) => {
  const [allContact, setAllContact] = useState([]);
  const [oneContact, setOneContact] = useState(false);
  const [isReMessage, setisReMessage] = useState('');

  const token = useSelector((info) => {
    return info.user.info.token;
  });

  const url = process.env.REACT_APP_BACKEND_PRIFILE_URL;

  const getAllQustion = async () => {
    try {
      const { data } = await axios.get(`${url}/user/allQuestion`, {
        headers: { authorization: 'Bearer ' + token },
      });
      setAllContact(data.allQuestion);
    } catch (error) {
      return;
    }
  };
  const getOneQustion = async (id) => {
    try {
      const { data } = await axios.get(`${url}/user/oneQuestion/${id}`, {
        headers: { authorization: 'Bearer ' + token },
      });
      setOneContact(data.allContact);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    getAllQustion();
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    return () => {
      unlisten();
    };
  }, []);

  const { errors, touched, values, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        reMessage: '',
      },
      onSubmit: async ({ reMessage }) => {
        await axios.post(
          `${url}/user/question-replay/${isReMessage}`,
          { reMessage },
          {
            headers: { authorization: 'Bearer ' + token },
          }
        );
        resetForm();
        setTimeout(() => {
          setisReMessage(false);
        }, 1000);
      },
    });
  return (
    <>
      <h3>{pageTitle}</h3>
      <div className="dashboard_right_title">
        <div className="dashboard_right_title_header">
          <h3>Message</h3>
          <h3>Name</h3>
          <h3>Action</h3>
        </div>
        {allContact ? (
          allContact.map((allContact) => {
            return (
              !allContact.reply && (
                <div
                  className="dashboard_right_title-content"
                  key={allContact._id}
                >
                  <p>{allContact?.question}</p>
                  <p>{allContact?.patientName}</p>
                  <p className="table_col3">
                    <button onClick={() => getOneQustion(allContact._id)}>
                      <GrView /> View
                    </button>
                    <button onClick={() => setisReMessage(allContact._id)}>
                      <IoIosSend className="action_icon2" /> Replay
                    </button>
                  </p>
                </div>
              )
            );
          })
        ) : (
          <p>Loading.....</p>
        )}

        {isReMessage && (
          <div className="message_replay_wrap">
            <h3>Please Enter Your Answers</h3>

            <form onSubmit={handleSubmit}>
              <span className="message_replay_subHeader">
                <label htmlFor="reMessage">Message</label>
                <MdClose
                  onClick={() => setisReMessage(false)}
                  className="replay_closeIcon"
                />
              </span>
              <textarea
                name="reMessage"
                id="reMessage"
                placeholder="Write Here....."
                onChange={handleChange}
                value={values.reMessage}
              ></textarea>
              <button type="submit">
                <IoIosSend className="action_icon3" /> Send
              </button>
            </form>
          </div>
        )}

        {/* View Message  */}
        {oneContact && (
          <div className="view_contact_container">
            <div className="view_contact_wrap">
              <MdClose
                className="replay_closeIcon posttion"
                onClick={() => setOneContact(false)}
              />
              <h3>Name :{oneContact?.patientName}</h3>
              <p>Email :{oneContact?.email}</p>
              <p>Question :{oneContact?.question}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default QustionList;
