import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function NurseUserSignup() {
  const [user, setUser] = useState({
    name: '',
    phone: '',
    email: '',
    password: '',
    password_confirmation: '',
    nid: '',
    dob: '',
    gender: '',
    blood_group: '',
    is_identity_no: 0,
    user_type: 4,
  });

  let navigate = useNavigate();

  const onchange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const nurseUserSignup = async () => {
    let getPhoneNo = localStorage.getItem('parasol_health_phone');
    if (getPhoneNo.length > 10) {
      try {
        let respons = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/register`,
          { ...user, phone: getPhoneNo }
        );
        toast.success(respons?.data?.message);
        localStorage.removeItem('parasol_health_phone');
        navigate('/user-login');
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    } else {
      toast.error('Some think worng..!');
    }
  };

  return (
    <div className="w-screen h-screen grid place-items-center">
      <div className="border px-6 py-8">
        <h2 className="text-[22px] font-semibold text-center text-[#2ABFC4]">
          Registratoin
        </h2>
        <form action="">
          <div className="flex items-center gap-x-8 gap-y-0 flex-wrap justify-center">
            <InputWithLabel
              labelName="Full name"
              placeholder="Full name"
              htmlFor="fullname"
              name="name"
              onchange={onchange}
            />
            <InputWithSelect
              labelName="Gender"
              name="gender"
              htmlFor="gender"
              hintLabel="Select gender"
              onchange={onchange}
              childdren={
                <>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                  <option value="3">Other</option>
                </>
              }
            />
          </div>
          <div className="flex items-center gap-x-8 gap-y-0 flex-wrap justify-center">
            <InputWithLabel
              labelName="Date of Birth"
              sublabel="(Applicant must be over 19)"
              htmlFor="dateofbirth"
              intype="date"
              name="dob"
              onchange={onchange}
            />

            <InputWithSelect
              labelName="Blood Group"
              name="blood_group"
              htmlFor="bloodgroup"
              hintLabel="Select gender"
              onchange={onchange}
              childdren={
                <>
                  <option value="1">A+</option>
                  <option value="2">A-</option>
                  <option value="3">AB+</option>
                  <option value="4">AB-</option>
                  <option value="5">B+</option>
                  <option value="6">B-</option>
                  <option value="7">O+</option>
                  <option value="8">O-</option>
                </>
              }
            />
          </div>
          <div className="flex items-center gap-x-8 gap-y-0 flex-wrap justify-center">
            <InputWithSelect
              labelName="Nationality"
              name="nationality"
              htmlFor="nationality"
              hintLabel="Select nationality"
              onchange={onchange}
              childdren={
                <>
                  <option value="bangladeshi">Bangladeshi</option>
                  <option value="other">Other</option>
                </>
              }
            />
            <InputWithLabel
              labelName="NID"
              placeholder="NID number"
              htmlFor="nid"
              name="nid"
              onchange={onchange}
            />
          </div>
          <div className="flex items-center gap-x-8 gap-y-0 flex-wrap justify-center">
            <InputWithLabel
              labelName="E-mail"
              placeholder="E-mail address"
              htmlFor="email"
              name="email"
              onchange={onchange}
            />
            <InputWithLabel
              labelName="Password
              "
              intype="password"
              placeholder="Password"
              htmlFor="password"
              name="password"
              onchange={onchange}
            />
          </div>
          <div className="flex items-center gap-x-8 gap-y-0 flex-wrap justify-center">
            <InputWithLabel
              labelName="Confirm password
            "
              intype="password"
              placeholder="Password"
              htmlFor="confirmpassword"
              name="password_confirmation"
              onchange={onchange}
            />
            <div className="flex-1"></div>
          </div>

          <div className="w-full flex justify-evenly flex-wrap gap-4 mt-8 mb-4">
            <button
              type="button"
              className="btn btn-outline btn-info capitalize min-w-[190px]"
            >
              Cancel
            </button>
            <button
              onClick={nurseUserSignup}
              type="button"
              className="btn bg-[#2abfc4] btn-info capitalize text-white min-w-[190px]"
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const InputWithLabel = ({
  labelName,
  sublabel,
  intype,
  placeholder,
  htmlFor,
  name,
  onchange,
}) => {
  return (
    <div className="min-w-[325px]">
      <label
        htmlFor={htmlFor}
        className="block mb-1 text-[18px] mt-4 font-medium"
      >
        {labelName}
        {sublabel && <span className="text-[14px]">{sublabel}</span>}
      </label>
      <input
        onChange={onchange}
        name={name}
        type={intype ? intype : 'text'}
        id={htmlFor}
        placeholder={placeholder}
        className="input input-bordered input-info w-full max-w-md"
      />
    </div>
  );
};

const InputWithSelect = ({
  labelName,
  name,
  htmlFor,
  hintLabel,
  childdren,
  onchange,
}) => {
  return (
    <div className="min-w-[325px]">
      <label
        htmlFor={htmlFor}
        className="block mb-1 text-[18px] mt-4 font-medium"
      >
        {labelName}
      </label>
      <select
        onChange={onchange}
        className="select select-info w-full max-w-md"
        name={name}
        id={htmlFor}
      >
        <option disabled selected>
          {hintLabel}
        </option>
        {childdren}
      </select>
    </div>
  );
};
